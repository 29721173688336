import cn from 'classnames'
import React from 'react'

import type {PageContentStylesProps} from './PageContent.styles'
import {usePageContentStyles} from './PageContent.styles'

type PageContentProps = {
  children?: React.ReactNode
  placeholder?: React.ReactNode
  disablePadding?: boolean
  className?: string
  classes?: PageContentStylesProps['classes']
}

const PageContent: React.FC<PageContentProps> = ({className, classes, placeholder, disablePadding, children}) => {
  const {classes: c} = usePageContentStyles(undefined, {
    props: {classes: {...classes, root: cn(className, classes?.root)}}
  })

  return (
    <main
      className={cn(c.root, placeholder && c.rootWithPlaceholder, !disablePadding && c.rootPadding)}
      data-testid={'ui-page-content' /*UI | Контент страницы*/}
    >
      {placeholder ? placeholder : children}
    </main>
  )
}

export default PageContent
