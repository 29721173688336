import cn from 'classnames'
import React from 'react'

import type {IChat} from '../../types'
import {getChatStatusText} from '../../utils/getChatStatusText'

import {useChatStatusChipStyles} from './ChatStatusChip.style'

type ChatStatusChipProps = {
  className?: string
  chat: Pick<IChat, 'status' | 'ask_csat'>
}

function ChatStatusChip({chat: {status, ask_csat}, className}: ChatStatusChipProps) {
  const {classes: c} = useChatStatusChipStyles()

  return (
    <div
      data-testid='chats-table-row-status'
      className={cn(c.status, className, {
        [c.statusOpen]: status === 'open',
        [c.statusPending]: status === 'pending',
        [c.statusSolved]: status === 'solved'
      })}
    >
      {getChatStatusText(status, ask_csat)}
    </div>
  )
}

export default ChatStatusChip
