/* eslint-disable react-hooks/exhaustive-deps */
import type {FC} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useQueryState} from 'react-router-use-location-state'

import {chatsSlice} from '../../slice/chatsSlice'

const SINGLE_CHAT_URL_KEY = 'single_chat'
const LEGACY_CHAT_URL_KEY = 'chat_id'

/** Занимается синхронизацией стейта URL (react-router) со стейтом чатов (redux). */
export const ChatsRouterBinding: FC = () => {
  const dispatch = useDispatch()

  const [singleChatUrlState, setSingleChatUrlState] = useQueryState(SINGLE_CHAT_URL_KEY, false, {stripDefaults: true})
  const singleChatStoreState = useSelector(chatsSlice.selectors.selectSingleChatOpen)
  useEffect(() => void setSingleChatUrlState(singleChatStoreState), [singleChatStoreState])
  useEffect(() => {
    if (singleChatUrlState === singleChatStoreState) {
      return
    }

    if (singleChatUrlState) {
      dispatch(chatsSlice.actions.openSingleChat({}))
    }
  }, [singleChatUrlState])

  const [legacyChatUrlState, setLegacyChatUrlState] = useQueryState(LEGACY_CHAT_URL_KEY, '', {stripDefaults: true})
  const legacyChatStoreState = useSelector(chatsSlice.selectors.selectLegacyOpenedChatId)
  useEffect(() => void setLegacyChatUrlState(legacyChatStoreState ?? ''), [legacyChatStoreState])
  useEffect(() => {
    if (legacyChatUrlState === legacyChatStoreState) {
      return
    }

    if (legacyChatUrlState) {
      dispatch(chatsSlice.actions.openLegacyChat(legacyChatUrlState))
    }
  }, [legacyChatUrlState])

  return null
}
