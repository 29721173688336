import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'
import React, {Fragment} from 'react'
import {makeStyles} from 'tss-react/mui'

import type {IChat} from '../../types'
import {isToday} from '../../utils/isToday'

import ChatListItem from './ChatListItem'

type ChatsListProps = {
  chats: IChat[]
  onClickChat(chat: IChat): void
}

const useStyles = makeStyles()({
  item: {
    marginBottom: 12
  },
  dateGroup: {
    color: '#302F2D',
    opacity: 0.4,
    marginBottom: 6
  }
})

const DATE_FORMAT = 'D MMMM YYYY'

function ChatsList({chats, onClickChat}: ChatsListProps) {
  const {t} = useI18n()
  const {classes: c} = useStyles()

  function renderDateGroup(currentChat: IChat, i: number) {
    if (i === 0) {
      if (!currentChat.last_message_created_at) {
        return
      }

      const lastMessageCreatedAt = moment(currentChat.last_message_created_at)
      return (
        <div className={c.dateGroup}>
          {isToday(lastMessageCreatedAt)
            ? t('main-chats.chats-list.segodnya', 'Сегодня')
            : lastMessageCreatedAt.format(DATE_FORMAT)}
        </div>
      )
    }

    const prevChat = chats[i - 1]
    if (!currentChat.last_message_created_at || !prevChat.last_message_created_at) {
      return
    }

    const lastMessageCreatedAt = moment(currentChat.last_message_created_at)
    const isSameDate = lastMessageCreatedAt.isBetween(
      moment(prevChat.last_message_created_at).startOf('day'),
      moment(prevChat.last_message_created_at).endOf('day')
    )

    if (!isSameDate) {
      return <div className={c.dateGroup}>{lastMessageCreatedAt.format(DATE_FORMAT)}</div>
    }
  }

  return (
    <div>
      {chats.map((chat, i) => (
        <Fragment key={chat.chat_id}>
          {renderDateGroup(chat, i)}
          <ChatListItem key={chat.chat_id} className={c.item} chat={chat} onClick={() => onClickChat(chat)} />
        </Fragment>
      ))}
    </div>
  )
}

export default ChatsList
