import React, {useCallback, useEffect} from 'react'
import {Snackbar, type SnackbarDescription} from '@eda-restapp/snackbars'
import {useExp3} from '@eda-restapp/configs'
import {useDispatch, useSelector} from 'react-redux'
import {addSnackbar, removeSnackbar, removeAllSnackbars, removeOldestSnackbar} from './slice/snackbars.slice'
import {Snackbars} from './Snackbars'
import type {ReduxState} from '@restapp/bootstrap/reducer'
import {eventLogger} from '@eda-restapp/logger'

export const SnackbarsContainer: React.FC = () => {
  const snackbarsConfig = useExp3('restapp_snackbars')
  const dispatch = useDispatch()
  const snackbars = useSelector((state: ReduxState) => state.snackbars)
  const removeIntervalEnabled = snackbars.length > 0

  useEffect(
    () =>
      Snackbar.transport.subscribe((message) => {
        if (message.type === 'show') {
          dispatch(addSnackbar(message.payload))

          if (message.payload.type == 'error') {
            eventLogger({
              name: 'snakbars:error',
              value: message.payload.text,
              additional: {errorCode: message.payload.errorCode}
            })
          }
        }
      }),
    [dispatch]
  )

  useEffect(() => {
    if (!removeIntervalEnabled) {
      return
    }

    const oldestSnackbarRemovingInterval = setInterval(() => {
      dispatch(removeOldestSnackbar())
    }, snackbarsConfig.snackbarDefaulShowTime)

    return () => clearInterval(oldestSnackbarRemovingInterval)
  }, [removeIntervalEnabled, dispatch, snackbarsConfig.snackbarDefaulShowTime])

  const handleClose = useCallback(
    ({id}: Pick<SnackbarDescription, 'id'>) => {
      Snackbar.transport.broadcast({type: 'closed', payload: {id}})
      dispatch(removeSnackbar({id}))
    },
    [dispatch]
  )

  const handleCloseAll = useCallback(() => dispatch(removeAllSnackbars()), [])

  const handleClick = useCallback(
    ({id}: Pick<SnackbarDescription, 'id'>) => {
      Snackbar.transport.broadcast({type: 'clicked', payload: {id}})
      handleClose({id})
    },
    [handleClose]
  )

  return (
    <Snackbars
      snackbars={snackbars}
      onClose={handleClose}
      onCloseAll={handleCloseAll}
      onClick={handleClick}
      transitionMs={300}
    />
  )
}
