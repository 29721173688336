import {useExp3} from '@eda-restapp/configs'
import {getServiceBrandLabels, useI18n} from '@eda-restapp/i18n'
import {usePermission} from '@eda-restapp/permissions'
import {Typography, Box, Text, LegacyButton as Button} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import type {FC} from 'react'
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import type {Service} from '@restapp/shared-api'
import type {PlusStatusByService} from '@restapp/shared-api/types/yaPlus'
import {YaPlusStatusEnum} from '@restapp/shared-api/types/yaPlus'
import {LinkButton} from '@restapp/shared-ui'

import {OperatorInfoTooltip} from '../../../OperatorInfoTooltip/OperatorInfoTooltip'
import PlusConditions from '../../../PlusConditions'

import {DisablePlusTooltip} from './DisablePlusTooltip/DisablePlusTooltip'
import styles from './SinglePlaceCondition.module.css'

type SinglePlaceCondition = {
  placePlus: PlusStatusByService[]
  onDisable: (id: number, service: Service[]) => void
  onEnable: (values: number[], services: Service[]) => void
  setSelectedServices: (service: Service[]) => void
  placeId: number
}

const SinglePlaceCondition: FC<SinglePlaceCondition> = ({
  placePlus,
  placeId,
  onDisable,
  onEnable,
  setSelectedServices
}) => {
  const {t} = useI18n()
  const {dcServiceBrandLabel, eatsServiceBrandLabel} = getServiceBrandLabels()

  const [enablePlusTooltipAnchorEl, setEnablePlusTooltipAnchorEl] = useState<HTMLElement | null>(null)

  const canManagePlus = usePermission([
    'permission.tools.common',
    'permission.tools.subscription.common',
    'permission.plus.edit'
  ])
  const hasAccessToSeePlusStats = usePermission('permission.stats.plus')

  const getServiceName = (service: Service) => {
    if (service === 'eda') {
      return eatsServiceBrandLabel
    }
    if (service === 'dc') {
      return dcServiceBrandLabel
    }
  }

  const {plusCommission} = useExp3('restapp_plus')

  const isActiveService = placePlus?.some(
    (plus) =>
      plus.statuses[0].status === YaPlusStatusEnum.ACTIVE ||
      plus.statuses[0].status === YaPlusStatusEnum.ACTIVATING ||
      plus.statuses[0].status === YaPlusStatusEnum.WAIT_TO_START ||
      plus.statuses[0].status === YaPlusStatusEnum.DEACTIVATING
  )

  const notActiveService = placePlus?.find(
    (plus) => plus.statuses[0].status === YaPlusStatusEnum.CAN_BE_ACTIVATED
  )?.service

  useEffect(() => {
    if (notActiveService) {
      setSelectedServices([notActiveService])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notActiveService])

  return (
    <>
      <Box flexDirection='column' gap='m'>
        {placePlus.map((plus) => {
          const service = plus.service
          const status = plus?.statuses[0]

          if (!status) {
            return null
          }

          const isDeactivating = status.status === YaPlusStatusEnum.DEACTIVATING

          const isActive =
            status.status === YaPlusStatusEnum.ACTIVE ||
            status.status === YaPlusStatusEnum.ACTIVATING ||
            status.status === YaPlusStatusEnum.WAIT_TO_START ||
            isDeactivating

          return (
            <Box key={service} flexDirection='column'>
              <PlusConditions
                mb='s'
                service={service}
                commission={status?.value || String(plusCommission)}
                startDate={moment(status?.starts)}
                hideNotActivateService={!isActive}
                hasAccessToEditPlus={canManagePlus}
              />

              {isActive && (
                <DisablePlusTooltip disabled={canManagePlus}>
                  <LinkButton
                    onClick={() => onDisable(placeId, [service])}
                    className={cn(styles.link, {[styles.linkDisable]: isDeactivating || !canManagePlus})}
                    disabled={isDeactivating || !canManagePlus}
                    data-testid={`plus-single-place-disable-link-${service}` /* Условия Плюса | Ссылка Отключить */}
                  >
                    <Typography variant='body2' group='normal' thickness='regular'>
                      {t('shared-places.active-plus-conditions.disable-for-service', 'Отключить')}
                    </Typography>
                  </LinkButton>
                </DisablePlusTooltip>
              )}
            </Box>
          )
        })}
      </Box>

      <Box
        mt={notActiveService || (isActiveService && canManagePlus) ? 'm' : undefined}
        justifyContent='space-evently'
        gap='s'
        flexWrap='wrap'
      >
        {notActiveService && (
          <Button
            className={styles.controlSectionButton}
            variant='primary'
            size='m'
            disabled={!canManagePlus}
            onClick={() => onEnable([placeId], [notActiveService])}
            ref={setEnablePlusTooltipAnchorEl}
          >
            <Text align='center' color='inherit'>
              <Typography variant='body2' thickness='medium' style={{fontSize: 14}}>
                {t('shared-places.active-plus-conditions.enable-for', 'Подключить в')}{' '}
                {getServiceName(notActiveService)}
              </Typography>
            </Text>
          </Button>
        )}
        {isActiveService && hasAccessToSeePlusStats && (
          <Link to='/metrics/plus' className={styles.controlSectionButton}>
            <Button variant='secondary' size='m' fullWidth>
              <Text align='center'>
                <Typography variant='body2' thickness='medium' style={{fontSize: 14}}>
                  {t('main-places.plus-section.posmotret-statistiku', 'Посмотреть статистику')}
                </Typography>
              </Text>
            </Button>
          </Link>
        )}
      </Box>

      {notActiveService && (
        <Text align='center'>
          <Typography variant='caption1' group='normal' thickness='regular' color='minor' mt='s' px='xl'>
            {t(
              'shared-places.single-place-condition.ebable-for-one-place',
              'Нажимая «Подключить в {service}», вы соглашаетесь с условиями подключения',
              {service: getServiceName(notActiveService)}
            )}
          </Typography>
        </Text>
      )}

      {enablePlusTooltipAnchorEl && !canManagePlus && <OperatorInfoTooltip anchorEl={enablePlusTooltipAnchorEl} />}
    </>
  )
}

export default SinglePlaceCondition
