import {makeStyles} from 'tss-react/mui'

export const useSurveyPageStyles = makeStyles({name: 'SurveyPage'})({
  root: {
    '& + &': {
      marginTop: 16
    }
  },
  question: {
    fontSize: 18,
    lineHeight: 1,
    marginBottom: 8
  },
  description: {
    marginBottom: 8
  }
})
