import {useI18n} from '@eda-restapp/i18n'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {Snackbar} from '@eda-restapp/snackbars'
import {useEffect} from 'react'

import {useSupportChat} from '../../hooks/useSupportChat'

export default function WatchChatEvents() {
  const {t} = useI18n()
  const {openChat} = useSupportChat()

  useEffect(() => {
    const unsubscribe = notificationsChannel.subscribe((notification) => {
      if (!notificationsChannel.leaderElector.isLeader || notification.meta.source !== 'socket') {
        return
      }

      if (notification.event === 'new_message') {
        Snackbar.success({
          text: t('main-chats.watch-chat-events.novoe-soobshchenie-ot-podderzhki', 'Новое сообщение от поддержки'),
          onClick: () => openChat(notification.data.chat_id)
        })
      }
    })

    return unsubscribe
  }, [openChat, t])

  return null
}
