import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit'
import type {DefaultRootState} from 'react-redux'
import rootReducer from './reducer'
import {PERSIST, PURGE, REHYDRATE, persistStore} from 'redux-persist'
import type {TypedAxiosStrictInstance, VendorApi} from '@restapp/shared-api'
import {getConfigsStore} from '@eda-restapp/configs'
import {selectAuthHeaders} from '@restapp/core-auth'
import type {ApiRequest} from '@restapp/core-legacy/api'
import Api from '@restapp/core-legacy/api'
import type {Dispatch} from 'redux'
import {selectDeviceHeaders} from '@restapp/shared/selectors'

// TODO: В будущем нужно разнести в отдельные файлам по смыслу
export const configsStore = getConfigsStore()

export const api = new Api<VendorApi>(
  () => {
    return {...selectAuthHeaders(store.getState()), ...selectDeviceHeaders(store.getState())}
  },
  () => configsStore.getConfigs().restapp_api
)

export const apiRequest = api.request.request as TypedAxiosStrictInstance['request']

type ListenerMiddlewareExtra = {
  apiRequest: ApiRequest
}
export const listenerMiddleware = createListenerMiddleware<DefaultRootState, Dispatch, ListenerMiddlewareExtra>({
  extra: {apiRequest}
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, PURGE, REHYDRATE],
        ignoredActionPaths: ['listenerMiddleware/']
      }
    }).concat(listenerMiddleware.middleware)
})

export const persistor = persistStore(store)
