import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Fire, Modal, Skeleton, StarOfBethlehemViolet, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC} from 'react'

import type {TariffInfo, TariffType} from '@restapp/shared-api'

import {SubscriptionTariffCard} from '../../SubscriptionTariffCard'

import styles from './SubscriptionEditModal.module.css'

type SubscriptionEditModalProps = {
  open: boolean
  hasDiscount: boolean
  isManyPlacesEditing: boolean
  isLoading: boolean
  isHideBusinessPlus: boolean
  disabledEditingSubscription: boolean
  daysBeforeFinishDiscount: number
  hasPaidNextTariff: boolean
  defaultTariffCount: number
  tariffs: TariffInfo[]
  initialTariffType?: TariffType
  trialTariffType?: TariffType
  isFeatureSubscription?: boolean
  onClose: () => void
  onDisableSubscription: () => void
  onChangeSubscriptionTariff: (type: TariffType) => void
  onNavigateSubscriptionPage: () => void
}

export const SubscriptionEditModal: FC<SubscriptionEditModalProps> = ({
  open,
  tariffs,
  hasDiscount,
  isManyPlacesEditing,
  isLoading,
  initialTariffType,
  trialTariffType,
  isHideBusinessPlus,
  disabledEditingSubscription,
  defaultTariffCount,
  hasPaidNextTariff,
  daysBeforeFinishDiscount,
  isFeatureSubscription,
  onClose,
  onDisableSubscription,
  onChangeSubscriptionTariff,
  onNavigateSubscriptionPage
}) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()

  const getHeaderTitle = () => {
    if (hasDiscount) {
      return t('shared-places.subscription-edit-modal.title-discount', '3 месяца подписки со скидкой')
    }

    if (isManyPlacesEditing) {
      return t('shared-places.subscription-edit-modal.title-all-places', 'Управлять подпиской ресторанов')
    }

    return t('shared-places.subscription-edit-modal.title-default', 'Управлять подпиской ресторана')
  }

  const headerTitle = getHeaderTitle()

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header title={headerTitle} />

      <Modal.Content>
        {isLoading ? (
          <Skeleton size='xl' style={{height: 275}} />
        ) : (
          <div className={styles.content}>
            {hasDiscount && (
              <div className={styles.endDays}>
                {daysBeforeFinishDiscount === 1
                  ? t('shared-places.subscription-edit-modal.last-day-for-end-promo', 'Последний день акции')
                  : t(
                      'shared-places.subscription-edit-modal.for-end-promo-count-days',
                      'Осталось {count} д/ень/ня/ней',
                      {
                        one: 'Остался {count} день',
                        some: 'Осталось {count} дня',
                        many: 'Осталось {count} дней',
                        count: daysBeforeFinishDiscount
                      }
                    )}
                <Fire fontSize={'16'} htmlColor={'#FFFFFF'} className={styles.iconFire} />
              </div>
            )}
            {hasDiscount && (
              <div className={styles.textContent}>
                {t(
                  'shared-places.subscription-edit-modal.description-discount',
                  '7000 ресторанов уже пользуются инструментами для роста заказов в подписке. И вы тоже попробуйте — со скидкой на 3 месяца.'
                )}
              </div>
            )}
            <div
              className={cn(styles.scroll, {
                [styles.fewCards]: isHideBusinessPlus || tariffs.length < defaultTariffCount
              })}
            >
              {tariffs?.map((tariff) => {
                const isActive = tariff.type === initialTariffType

                return (
                  <SubscriptionTariffCard
                    className={styles.tariffCard}
                    key={tariff.type}
                    isActive={isActive}
                    hasDiscount={hasDiscount}
                    trialTariffInfoType={trialTariffType}
                    tariffInfo={tariff}
                    disabled={disabledEditingSubscription}
                    isSmallFormat
                    onClick={() =>
                      tariff.type === 'free' ? onDisableSubscription() : onChangeSubscriptionTariff(tariff.type)
                    }
                    hasPaidNextTariff={hasPaidNextTariff}
                  />
                )
              })}
            </div>

            {isFeatureSubscription && (
              <Box mt='m'>
                {isMobile ? (
                  <Box flexDirection='column' p='m' className={styles.mobileBadge} gap='m'>
                    <Box gap='m' alignItems='center'>
                      <StarOfBethlehemViolet />

                      <Typography variant='body2' group='tight' thickness='medium'>
                        {t(
                          'subscription.subscription-edit-modal.feature-subscription-title',
                          'Узнайте больше о всех преимуществах подписки '
                        )}
                      </Typography>
                    </Box>
                    <Button.Text variant='subscription' size='s' fullWidth onClick={onNavigateSubscriptionPage}>
                      {t('subscription.subscription.subscription-edit-modal.feature-subscription-more', 'Подробнее')}
                    </Button.Text>
                  </Box>
                ) : (
                  <Button.MultiSpace
                    onClick={onNavigateSubscriptionPage}
                    fullWidth
                    variant='secondary'
                    size='l'
                    startIcon={<StarOfBethlehemViolet />}
                    endIcon={
                      <Typography
                        variant='caption1'
                        group='tight'
                        thickness='bold'
                        py='s'
                        px='l'
                        color='invert'
                        className={styles.badge}
                      >
                        {t('subscription.subscription.subscription-edit-modal.feature-subscription-more', 'Подробнее')}
                      </Typography>
                    }
                  >
                    {t(
                      'subscription.subscription-edit-modal.feature-subscription-title',
                      'Узнайте больше о всех преимуществах подписки '
                    )}
                  </Button.MultiSpace>
                )}
              </Box>
            )}
          </div>
        )}
      </Modal.Content>
    </Modal>
  )
}
