import type {Dispatch, Store} from 'redux'

import type {LegacyFetcher} from '@restapp/core-api'
import type {ISocketEvents} from '@restapp/core-notifications'
import type {VendorApi} from '@restapp/shared-api'
import type {DialogService} from '@restapp/shared-modals'

import type {IApi} from '../api'
import type API from '../api'
import type {AppStateType} from '../AppState'
import type {ISnackbarNotifier} from '../lib/snackbar-notifier'

export interface IEnv {
  api: IApi<VendorApi>
  snackbarNotifier: ISnackbarNotifier
  socketEvent$: ISocketEvents
  dialogs: DialogService
  dispatch?: Dispatch
  store?: Store
  fetchApiQuery: LegacyFetcher
}

export interface IEnvInject {
  env: IEnv
}

export type AppStateInject = {
  appState: AppStateType
  api: API<VendorApi>
  env: IEnv
}

export enum AppEvent {
  Unauthorized = 'unauthorized',
  NetworkError = 'connection_lost',
  NoPartnerIdError = 'no_partner_id_error',
  NetworkSuccess = 'connection_success'
}

export enum PassportError {
  PASSPORT_TOKEN_ERROR = 'PASSPORT_TOKEN_ERROR',
  WRONG_PASSPORT_ACCOUNT = 'WRONG_PASSPORT_ACCOUNT'
}

export interface UnauthorizedEventData {
  /**
   * Запрос на котором произошло событие Unauthorized
   */
  url: string
  method: string
  status: number
  error_slug?: PassportError
}
