import React from 'react'
import {observer} from 'mobx-react'
import {Navigate, Route, Routes} from 'react-router-dom'

import NoPermissions from '../Placeholders/NoPermissions'
import {RouteContext} from '../../routes/utils'
import type {RoutesType, RouteType, InnerRoute} from '../../routes'
import {useExp3} from '@eda-restapp/configs'
import {useAuth} from '@restapp/core-auth'
import {usePermission} from '@eda-restapp/permissions'

type OuterProps = {
  routes: RoutesType | InnerRoute[]
}

const RoutesComponent: React.FC<OuterProps> = ({routes}) => {
  const {restapp_home} = useExp3()
  const {isLoggedIn} = useAuth()
  const hasManagerDisabledRouteFallback = usePermission('permission.custom.manager_disabled_route_fallback')

  const getDisabledRouteFallback = () => {
    if (!isLoggedIn) {
      return '/auth'
    }

    if (hasManagerDisabledRouteFallback) {
      return restapp_home.enabled === false ? '/places' : '/home'
    } else {
      return '/active'
    }
  }

  const renderRoute = (route: RouteType | InnerRoute, index: number) => {
    if (route.params?.auth !== undefined && route.params?.auth !== isLoggedIn) {
      return null
    }

    return (
      <Route
        key={index}
        path={route.path}
        element={<RouteElement route={route} disabledRouteFallback={getDisabledRouteFallback()} />}
      />
    )
  }

  return <Routes>{routes.map(renderRoute)}</Routes>
}

const RouteElement = ({
  route,
  disabledRouteFallback
}: {
  route: RouteType | InnerRoute
  disabledRouteFallback: string
}) => {
  const permissions = route.params?.permissions ? route.params.permissions : []
  const screenTitle = route.params ? route.params.displayName || '' : ''

  const hasAccess = usePermission(permissions)

  if (!route.enabled) {
    return <Navigate to={disabledRouteFallback} />
  }

  if (!hasAccess) {
    return <NoPermissions screenTitle={screenTitle} />
  }

  if (route.redirectTo) {
    return <Navigate to={route.redirectTo} />
  }

  if (route.component) {
    if ('context' in route) {
      return (
        <RouteContext.Provider value={route.context}>
          <route.component routes={route.routes} />
        </RouteContext.Provider>
      )
    } else {
      return <route.component routes={route.routes} />
    }
  }
}

export default observer(RoutesComponent)
