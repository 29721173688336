import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {Moment} from 'moment'
import moment from 'moment'

const initialState: ChatsSliceState = {
  singleChat: {
    open: false,
    openParams: {},
    unreadCount: 0
  },
  legacyChat: {},
  filters: {
    author: 'all',
    status: 'all',
    topic: 'all',
    period: 'year',
    dateFrom: moment().subtract(1, 'year').valueOf(),
    sortOrder: 'desc'
  }
}

export const chatsSlice = createSlice({
  name: 'chats',
  initialState: () => initialState,
  reducers: {
    setSingleChatUnreadCount: (state, action: PayloadAction<number>) => {
      state.singleChat.unreadCount = action.payload
    },
    openSingleChat: (state, action: PayloadAction<{orderId?: string; placeId?: number}>) => {
      state.singleChat.open = true
      state.singleChat.openParams.orderId = action?.payload.orderId
      state.singleChat.openParams.placeId = action?.payload.placeId
    },
    closeSingleChat: (state) => {
      state.singleChat.open = false
    },
    openLegacyChat: (state, action: PayloadAction<string>) => {
      state.legacyChat.openedChatId = action.payload
    },
    closeLegacyChat: (state) => {
      delete state.legacyChat.openedChatId
      delete state.legacyChat.newChatParams
    },
    setLegacyNewChatParams: (state, action: PayloadAction<ChatsSliceState['legacyChat']['newChatParams']>) => {
      state.legacyChat.newChatParams = action.payload
    },
    setAuthorFilter: (state, action: PayloadAction<ChatsFilters['author']>) => {
      state.filters.author = action.payload
    },
    setStatusFilter: (state, action: PayloadAction<ChatsFilters['status']>) => {
      state.filters.status = action.payload
    },
    setTopicFilter: (state, action: PayloadAction<ChatsFilters['topic']>) => {
      state.filters.topic = action.payload
    },
    setPeriodFilter: (state, action: PayloadAction<ChatsFilters['period']>) => {
      state.filters.period = action.payload
    },
    setDateFromFilter: (state, action: PayloadAction<Moment>) => {
      state.filters.dateFrom = action.payload.valueOf()
    },
    setDateToFilter: (state, action: PayloadAction<Moment>) => {
      state.filters.dateTo = action.payload.valueOf()
    },
    setSortOrderFilter: (state, action: PayloadAction<ChatsFilters['sortOrder']>) => {
      state.filters.sortOrder = action.payload
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
    }
  },
  selectors: {
    selectSingleChatUnreadCount: (state) => state.singleChat.unreadCount,
    selectSingleChatOpen: (state) => state.singleChat.open,
    selectSingleChatOpenParams: (state) => state.singleChat.openParams,
    selectLegacyOpenedChatId: (state) => state.legacyChat.openedChatId,
    selectLegacyNewChatParams: (state) => state.legacyChat.newChatParams,
    selectFilters: (state) => {
      return {
        ...state.filters,
        dateFrom: moment(state.filters.dateFrom),
        dateTo: state.filters.dateTo ? moment(state.filters.dateTo) : undefined
      }
    },
    selectFiltersDirty: (state) => {
      return (
        state.filters.author !== initialState.filters.author ||
        state.filters.status !== initialState.filters.status ||
        state.filters.topic !== initialState.filters.topic ||
        state.filters.period !== initialState.filters.period ||
        state.filters.dateFrom !== initialState.filters.dateFrom ||
        state.filters.dateTo !== initialState.filters.dateTo ||
        state.filters.sortOrder !== initialState.filters.sortOrder
      )
    },
    selectFiltersDirtyCount: (state) => {
      // не учитываем dateFrom и dateTo т.к они считаются за 1 вместе с period
      return (
        Number(state.filters.author !== initialState.filters.author) +
        Number(state.filters.status !== initialState.filters.status) +
        Number(state.filters.topic !== initialState.filters.topic) +
        Number(state.filters.period !== initialState.filters.period) +
        Number(state.filters.sortOrder !== initialState.filters.sortOrder)
      )
    }
  }
})

export type LegacyNewChatParams = {
  place_id?: number
  doccenter_id?: string
  path?: string
  place_ids?: string
  order_id?: string
}

export type ChatsFilters = {
  author: string
  status: 'open' | 'solved' | 'pending' | 'all'
  topic: string
  period: 'week' | 'month' | 'year' | 'custom'
  dateFrom: number
  dateTo?: number
  sortOrder: 'asc' | 'desc'
}

type ChatsSliceState = {
  singleChat: {
    open: boolean
    openParams: {
      orderId?: string
      placeId?: number
    }
    unreadCount: number
  }
  legacyChat: {
    openedChatId?: string
    newChatParams?: LegacyNewChatParams
  }
  filters: ChatsFilters
}
