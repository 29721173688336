import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Typography} from '@eda-restapp/ui'
import moment from 'moment'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {LineClamp} from '@restapp/shared-ui'
import {Button} from '@restapp/shared-ui/Button'

import type {IChat} from '../../types'
import ChatStatusChip from '../ChatStatusChip'

export type ChatListItemProps = {
  className?: string
  chat: IChat
  onClick(): void
}

const useStyles = makeStyles()({
  root: {
    border: '2px solid #DCDEE0',
    borderRadius: 12,
    padding: 10
  },
  title: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  topRow: {
    display: 'flex',
    alignItems: 'center'
  },
  bottomRow: {
    display: 'flex'
  },
  date: {
    marginLeft: 'auto'
  },
  newMessagesBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    background: '#FA6A3C',
    borderRadius: 20,
    color: '#fff',
    fontSize: 13,
    lineHeight: '15px',
    height: 20,
    padding: '0 7px'
  },
  status: {
    margin: '0 8px'
  },
  csatButton: {
    marginTop: 15
  }
})

function ChatListItem({className, chat, onClick}: ChatListItemProps) {
  const {t} = useI18n()
  const {classes: c, cx} = useStyles()

  return (
    <div className={cx(className, c.root)} onClick={onClick}>
      <div className={c.topRow}>
        <Typography variant='body2' thickness='bold' className={c.title}>
          {chat.topic}
        </Typography>
        <ChatStatusChip className={c.status} chat={chat} />
        {!!chat.last_message_created_at && (
          <Typography variant='caption1' color='minor' className={c.date}>
            {moment(chat.created_at).format('DD.MM')}
          </Typography>
        )}
      </div>
      <Typography variant='caption1' my='xs'>
        <div>{chat.author}</div>
      </Typography>
      <div className={c.bottomRow}>
        <Typography variant='caption1' color='minor'>
          <LineClamp lineClamp={2}>{chat.last_message_text}</LineClamp>
        </Typography>
        {chat.new_message_count > 0 && <div className={c.newMessagesBadge}>{chat.new_message_count}</div>}
      </div>
      {chat.ask_csat && (
        <Button
          className={c.csatButton}
          variant='contained'
          size='small'
          fullWidth
          onClick={() => metrika({target: 'chat_rate_click', params: {id: chat.chat_id}})}
        >
          {t('main-chats.chat-list-item.otsenit-podderzhku', 'Оценить поддержку')}
        </Button>
      )}
    </div>
  )
}

export default ChatListItem
