// TODO(SuperPaintman): Remove those rules later
/* tslint:disable:no-inferred-empty-object-type */

// Modules
import type {IModelType} from 'mobx-state-tree'
import {types as t, getRoot, getEnv} from 'mobx-state-tree'
import type {Store} from 'redux'
// Aliases
import type {Places} from '../Places/Places/Places'
import type {IEnv} from '../../types'
import type {AppStateType} from '../../AppState'
import type {PlacesDropdownStateSlice} from '@restapp/core-places'
import {selectPlaceIds} from '@restapp/core-places'

type InjectableModels = {
  readonly places: Places
  readonly reduxState: unknown
}

type InjectableModelsName = keyof InjectableModels

type InjectableEnv = Readonly<IEnv>

type InjectableEnvName = Expand<keyof IEnv>

type InjectedFromRoot<T> = T extends InjectableModelsName ? {$root: {[K in T]: InjectableModels[T]}} : {}

type InjectedFromEnv<T> = T extends InjectableEnvName ? {$env: {[K in T]: InjectableEnv[T]}} : {}

const NONE = Symbol()

type None = typeof NONE

/**
 * @deprecated Если уж приходится использовать, то можно использовать только один экземпляр данной функции иначе последний перезаписывает все предыдущие
 */
export function injectFromRoot<
  T1 extends InjectableModelsName | None = None,
  T2 extends InjectableModelsName | None = None,
  T3 extends InjectableModelsName | None = None,
  T4 extends InjectableModelsName | None = None,
  T5 extends InjectableModelsName | None = None,
  T6 extends InjectableModelsName | None = None,
  T7 extends InjectableModelsName | None = None,
  T8 extends InjectableModelsName | None = None,
  T9 extends InjectableModelsName | None = None
  // Who needs more?
>(
  n1: T1,
  n2?: T2,
  n3?: T3,
  n4?: T4,
  n5?: T5,
  n6?: T6,
  n7?: T7,
  n8?: T8,
  n9?: T9
): IModelType<
  {},
  InjectedFromRoot<T1> &
    InjectedFromRoot<T2> &
    InjectedFromRoot<T3> &
    InjectedFromRoot<T4> &
    InjectedFromRoot<T5> &
    InjectedFromRoot<T6> &
    InjectedFromRoot<T7> &
    InjectedFromRoot<T8> &
    InjectedFromRoot<T9>
>
export function injectFromRoot(...names: InjectableModelsName[]) {
  return t.model().views((self) => {
    /**
     * @note(SuperPaintman):
     *    Because MobX doesn't cache our inner getters (in `$root`), we don't
     *    want to call `getRoot()` for each access to the property. That's why
     *    we cache the root manually.
     */
    let rootStore: any = null
    const $root: any = {}
    const views = {
      get $root() {
        rootStore = getRoot(self)

        return $root
      }
    }

    for (const name of names) {
      Object.defineProperty($root, name, {
        get() {
          return rootStore[name]
        },
        enumerable: true
      })
    }

    return views
  })
}

export function injectFromEnv<
  T1 extends InjectableEnvName | None = None,
  T2 extends InjectableEnvName | None = None,
  T3 extends InjectableEnvName | None = None,
  T4 extends InjectableEnvName | None = None,
  T5 extends InjectableEnvName | None = None,
  T6 extends InjectableEnvName | None = None,
  T7 extends InjectableEnvName | None = None,
  T8 extends InjectableEnvName | None = None,
  T9 extends InjectableEnvName | None = None
  // Who needs more?
>(
  n1: T1,
  n2?: T2,
  n3?: T3,
  n4?: T4,
  n5?: T5,
  n6?: T6,
  n7?: T7,
  n8?: T8,
  n9?: T9
): IModelType<
  {},
  InjectedFromEnv<T1> &
    InjectedFromEnv<T2> &
    InjectedFromEnv<T3> &
    InjectedFromEnv<T4> &
    InjectedFromEnv<T5> &
    InjectedFromEnv<T6> &
    InjectedFromEnv<T7> &
    InjectedFromEnv<T8> &
    InjectedFromEnv<T9>
>
export function injectFromEnv(...names: InjectableEnvName[]) {
  return t.model().views((self) => {
    /**
     * @note(SuperPaintman): Same reason.
     */
    let env: any = null
    const $env: any = {}
    const views = {
      get $env() {
        env = getEnv<IEnv>(self)

        return $env
      }
    }

    for (const name of names) {
      Object.defineProperty($env, name, {
        get() {
          return env[name]
        },
        enumerable: true
      })
    }

    return views
  })
}

interface InjectedPlaceIds {
  single: number | null
  multiple: number[]
}

export function injectPlaceIds(): IModelType<{}, {$placeIds: InjectedPlaceIds}> {
  return t.model().views((self) => {
    let root: AppStateType & {reduxState: PlacesDropdownStateSlice}
    let env: {store: Store}
    let $placeIds: InjectedPlaceIds = {single: null, multiple: []}

    const views = {
      get $placeIds() {
        root = getRoot<AppStateType & {reduxState: PlacesDropdownStateSlice}>(self)
        env = getEnv(self)

        return $placeIds
      }
    }

    Object.defineProperty($placeIds, 'single', {
      get() {
        return selectPlaceIds(root.reduxState || env?.store?.getState())[0] || null
      },
      enumerable: true
    })

    Object.defineProperty($placeIds, 'multiple', {
      get() {
        return selectPlaceIds(root.reduxState || env?.store?.getState())
      },
      enumerable: true
    })

    return views
  })
}
