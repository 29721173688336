import {useI18n} from '@eda-restapp/i18n'
import {Divider} from '@mui/material'
import cn from 'classnames'
import moment from 'moment'
import React from 'react'

import {usePlaces} from '@restapp/core-places'
import {useServiceBrand} from '@restapp/shared'
import type {VendorApi} from '@restapp/shared-api'

import {formatSla} from '../../utils/formating'
import {getChatSlaHours} from '../../utils/getChatSlaHours'
import ChatStatusChip from '../ChatStatusChip'
import {CopyText} from '../CopyText/CopyText'

import {useChatDesktopStyles} from './Chat.style'

type ChatDesktopProps = {
  chat: VendorApi['/4.0/restapp-front/support_chat/v1/chat/list']['GET']['response']['chats'][0]
}

function ChatHeaderDesktop({chat}: ChatDesktopProps) {
  const {t} = useI18n()
  const {classes: c} = useChatDesktopStyles()
  const {getPlaceById} = usePlaces()
  const serviceBrand = useServiceBrand()

  const renderSubTitle = () => {
    const createAtText = t('main-chats.formating.created-at', 'Создан в {createdAt}', {
      createdAt: moment(chat.created_at).format('HH:mm DD.MM.YYYY')
    })
    const chatPlace = chat.place_id && getPlaceById(Number(chat.place_id))
    if (chatPlace) {
      return `${chatPlace.name} (${chatPlace.address})・${createAtText}`
    }
    return createAtText
  }

  const chatSla = getChatSlaHours(chat)

  return (
    <div className={c.header}>
      <div className={c.topRow}>
        <ChatStatusChip chat={chat} />
        {!!chatSla && (
          <div className={c.slaChip}>
            {chatSla > 0 && t('main-chats.chat-header-desktop.vremya-do-resheniya', 'Время до решения:')}
            {formatSla(chatSla)}
          </div>
        )}
      </div>

      <div className={c.chatIdRow}>
        <div className={cn(c.title, {[c.yangoTitle]: serviceBrand === 'YangoEats'})}>{chat.topic}</div>
        <div className={c.textMinor}>{renderSubTitle()}</div>
      </div>

      <div className={c.metaDataGrid}>
        {!!chat.order_id && (
          <div>
            <div className={c.textMinor}>{t('main-chats.chat-header-desktop.zakaz-№', 'Заказ №')}</div>
            <CopyText label={<span className={c.clampId}>{chat.order_id}</span>} copyValue={chat.order_id} />
          </div>
        )}
        <div>
          <div className={c.textMinor}>{t('main-chats.chat-header-desktop.avtor', 'Автор')}</div>
          <div>{chat.author}</div>
        </div>
        <div>
          <div className={c.textMinor}>{t('main-chats.chat-header-desktop.id-obrashcheniya', 'ID обращения')}</div>
          <CopyText label={<span className={c.clampId}>{chat.chat_id}</span>} copyValue={chat.chat_id} />
        </div>
      </div>

      <Divider />
    </div>
  )
}

export default ChatHeaderDesktop
