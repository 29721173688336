import {action, makeObservable, observable, runInAction} from 'mobx'

import type {Disposable} from '@restapp/shared'
import {Deferred} from '@restapp/shared'

import type {DialogProps} from '../types'

/**
 * Модель, предназначеная для упрощения реализации диалога с пользователем -
 * мы предлагаем пользователю совершить действие, а он может согласиться либо отказаться.
 */
export default class DialogModel<TParams = void> implements Disposable {
  private _params: TParams | null = null
  private _isVisible = false
  private _isEffectRunning = false
  private _askDeferred: Deferred<boolean> | null = null

  constructor() {
    makeObservable<DialogModel<TParams>, '_params' | '_isVisible' | '_isEffectRunning'>(this, {
      _params: observable,
      _isVisible: observable,
      _isEffectRunning: observable,
      open: action,
      close: action,
      confirm: action
    })
  }

  /**
   * Флаг, указывающий нужно ли показывать диалог
   */
  get isVisible() {
    return this._isVisible
  }

  /**
   * Флаг, указывающий на наличие запущенного эффекта
   */
  get isEffectRunning() {
    return this._isEffectRunning
  }

  /**
   * Необходимые параметры для отображения диалога
   */
  get params() {
    return this._params
  }

  /**
   * Сборная солянки из стандартных props для диалогов.
   * Помогает избежать ошибок с this и лишнего кода.
   */
  get dialogProps(): DialogProps {
    return {
      visible: this.isVisible,
      progress: this.isEffectRunning,
      onConfirm: () => this.confirm(),
      onClose: () => this.close()
    }
  }

  /**
   * Открывает диалог с пользователем.
   *
   * Возвращает promise, который разрешается:
   *  - true если вызвать .confirm() - пользователь подтвердил действие
   *  - false если вызвали .close() или открыли другой диалог не дожидаясь результата
   *
   * @param params Дополнительные параметры, которые нужны для отрисовки диалога
   * @param effect Асинхронный эффект, который будет выполнен при подтверждении действия
   */
  async open(params: TParams, effect?: () => Promise<unknown>): Promise<boolean> {
    if (this._askDeferred !== null) {
      this.close()
      await this._askDeferred
    }

    runInAction(() => {
      this._isVisible = true
      this._params = params
    })

    this._askDeferred = new Deferred<boolean>()

    let result = await this._askDeferred

    if (result && effect) {
      try {
        runInAction(() => {
          this._isEffectRunning = true
        })
        await effect()
      } catch {
        result = false
      } finally {
        runInAction(() => {
          this._isEffectRunning = false
        })
      }
    }

    runInAction(() => {
      this._isVisible = false
    })

    return result
  }

  /**
   * Подтверждает действие открытого диалога
   */
  confirm() {
    this._askDeferred?.resolve(true)
  }

  /**
   * Закрывает открытый диалог, отклоняя предложенное действие
   */
  close() {
    this._askDeferred?.resolve(false)
  }

  /**
   * Закрывает открытый диалог, при unmount компонента
   */
  dispose(): void {
    this.close()
  }
}
