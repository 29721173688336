import type {ConfigsWithDefaults} from '@eda-restapp/configs'
import {cloneDeepWith, get} from 'lodash-es'

import type {AxiosResponse, AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig} from 'axios'
import {EventEmitter} from 'events'
import type {RestypedBase} from 'restyped/spec'

import {incrementalDelay} from '@restapp/shared/utils/IncrementalDelay/incrementalDelay'
import {
  axios,
  ApiError,
  type ApiErrorResponse,
  type TypedAxiosResponse,
  type TypedAxiosStrictInstance,
  type VendorApi
} from '@restapp/shared-api'
import {delay, getSerializedParams} from '@restapp/shared/utils'

import {AppEvent, type UnauthorizedEventData} from '../types'
import config from '../config'
import {apiLogger} from '@eda-restapp/logger'

type RepeatAxiosRequestConfig = AxiosRequestConfig & {repeatCount?: number}

export const getBaseUrl = (): string => {
  let baseURL: string

  if (config.api.environment === 'dev') {
    baseURL = window.location.origin
  } else {
    baseURL = config.api[location.hostname]?.url || config.api.default.url
  }

  return baseURL
}

let baseURL = getBaseUrl()

const regexpForCheckRussian = /[а-яёА-ЯЁ]/g
const isRussianText = (str: string): boolean => regexpForCheckRussian.test(str)
const isDevCookieLang = () => document.cookie.includes('language=dev')

const {clientUrl} = config

const IDEMPOTENCY_HEADER_KEY = 'X-Idempotency-Token'

export interface IApi<Typings extends RestypedBase> {
  request: TypedAxiosStrictInstance<Typings>
  events: EventEmitter
  setLanguage(token: string): void
}

export type ApiRequest = IApi<VendorApi>['request']['request']

type ApiConfig = Required<ConfigsWithDefaults>['restapp_api']
type ConfigResolver = () => ConfigsWithDefaults['restapp_api']
type HeadersResolver = () => Record<string, string>

class Api<Typings extends RestypedBase> implements IApi<Typings> {
  static readonly DefaultConfig: ApiConfig = {
    logs_enabled: true,
    unauthorized_codes: [401],
    retries: {
      enabled: true,
      count: 3,
      intervals: [1000, 3000, 5000],
      blacklist_urls: []
    },
    socketPingTolerance: 5000,
    socketFirstPingTolerance: 5000,
    socketMaxRetryCount: 5,
    socketRetryInterval: 3000
  }

  request: TypedAxiosStrictInstance<Typings>
  events: EventEmitter

  readonly requestIdHeader = 'x-yarequestid'

  constructor(
    resolveHeaders: HeadersResolver,
    private resolveConfig: ConfigResolver = () => Api.DefaultConfig
  ) {
    this.request = this.createApi<Typings>(resolveHeaders)
    this.events = new EventEmitter()
  }

  get requestId() {
    return this.request.defaults.headers?.[this.requestIdHeader]
  }

  private get config(): Readonly<ApiConfig> {
    return this.resolveConfig() || Api.DefaultConfig
  }

  setLanguage(lang: string): void {
    this.request.defaults.headers['X-Language'] = lang
  }

  protected responseInterceptor = (res: AxiosResponse): AxiosResponse => {
    try {
      apiLogger({
        type: 'success',
        request: {
          url: res.config.url ?? 'unknown_url',
          method: res.config.method ?? 'unknown_method',
          params: res.config.params,
          data: res.config.data,
          headers: res.config.headers
        },
        response: {
          status: res.status,
          headers: res.headers,
          data: res.data,
          traceId: res.headers['x-yatraceid']
        }
      })
    } catch {
      // noop
    }

    this.events.emit(AppEvent.NetworkSuccess)

    const responseRequestId = res.headers[this.requestIdHeader]
    if (!this.requestId && responseRequestId) {
      this.setRequestId(responseRequestId)
    }
    const isDevLang = isDevCookieLang()
    if (isDevLang) {
      const data = cloneDeepWith(res.data, (value) => {
        if (typeof value === 'string' && isDevLang && isRussianText(value)) {
          if (res.config.url?.includes('4.0/restapp-front/eats/v1/ab/configs')) {
            return `(config) ${value}`
          } else {
            return `(api) ${value}`
          }
        }
      })

      return data
    } else {
      return res.data
    }
  }

  // TODO: сделать так чтобы ответы  с 401 ошибкой тоже логгировались
  protected errorInterceptor = (
    e: AxiosError<ApiErrorResponse> & {config: RepeatAxiosRequestConfig}
  ): Promise<TypedAxiosResponse<any, any, any>['data']> => {
    try {
      apiLogger({
        type: 'failure',
        request: {
          url: get(e, 'config.url', 'unknown_url'),
          method: get(e, 'config.method', 'unknown_method'),
          params: get(e, 'config.params'),
          headers: get(e, 'config.headers'),
          data: get(e, 'config.data')
        },
        response: {
          status: get(e, 'response.status'),
          headers: get(e, 'response.headers'),
          data: get(e, 'response.data'),
          traceId: get(e, 'response.headers.x-yatraceid')
        }
      })
    } catch {
      // noop
    }

    const status = e.response && e.response.status

    if (!e.response || !e.request) {
      this.events.emit(AppEvent.NetworkError)
    } else if (e?.response?.status === 412) {
      this.events.emit(AppEvent.NoPartnerIdError)
    }

    if (e.response && this.config.unauthorized_codes.includes(e.response.status)) {
      const passportError = e.response.data.details?.error_slug

      const data: UnauthorizedEventData = {
        url: e.config?.url || '',
        method: e.config?.method || '',
        status: e.response.status,
        ...(passportError && {error_slug: passportError})
      }
      this.events.emit(AppEvent.Unauthorized, data)
    } else if (
      this.config.retries.enabled &&
      !this.config.retries.blacklist_urls?.includes(e.config?.url || '') &&
      (!status || status >= 500)
    ) {
      const {retries} = this.config
      const errConfig = e.config as RepeatAxiosRequestConfig

      return this.repeatRequest(e, retries.count, incrementalDelay(errConfig.repeatCount))
    }

    throw new ApiError(e)
  }

  private setRequestId(id: string): void {
    this.request.defaults.headers[this.requestIdHeader] = id
  }

  private replaceVersion = (version: string) => version.replace(/((unstable|hotfix|testing)\d+)+/, '')

  private createApi<T extends RestypedBase>(resolveHeaders: HeadersResolver): TypedAxiosStrictInstance<T> {
    const headers: {[key: string]: string} = {
      'X-App-Version': this.replaceVersion(VERSION)
    }

    const api = axios.create<T>({
      baseURL,
      headers,
      paramsSerializer: {serialize: getSerializedParams}
    })

    api.interceptors.response.use(this.responseInterceptor, this.errorInterceptor)
    api.interceptors.request.use((config) => {
      const headers = resolveHeaders()
      config.headers.set({...headers})

      return config
    })
    api.interceptors.request.use(this.addIdempotencyTokenToRequest)

    return api
  }

  private generateIdempotencyToken = () =>
    crypto?.randomUUID?.() || `${Date.now()}-${Math.random().toString().slice(2)}`

  private addIdempotencyTokenToRequest = (config: InternalAxiosRequestConfig) => {
    config.headers = config.headers || {}
    if (!config.headers[IDEMPOTENCY_HEADER_KEY]) {
      config.headers[IDEMPOTENCY_HEADER_KEY] = this.generateIdempotencyToken()
    }

    return config
  }

  private async repeatRequest(
    err: AxiosError<ApiErrorResponse> & {config: RepeatAxiosRequestConfig},
    repeatLimit = 3,
    repeatDelay = 0
  ) {
    const repeatConfig = {
      ...err.config,
      url: err.config.url as any
    }

    repeatConfig.repeatCount = err.config.repeatCount ? err.config.repeatCount + 1 : 1

    if (repeatConfig.repeatCount > repeatLimit) {
      throw new ApiError(err)
    }

    await delay(repeatDelay)

    return this.request.request(repeatConfig as any)
  }
}

export {baseURL, clientUrl}

export default Api
