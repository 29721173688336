import {useDispatch, useSelector} from 'react-redux'

import {useApiQuery} from '@restapp/core-api'
import Spinner from '@restapp/shared-ui/Spinner'

import ChatterboxContainer from '../../containers/ChatterboxContainer'
import useChatMetadata from '../../hooks/useChatMetadata'
import type {LegacyNewChatParams} from '../../slice/chatsSlice'
import {chatsSlice} from '../../slice/chatsSlice'
import {ChatHeaderMobile, ChatHeaderDesktop} from '../Chat'

import NewChatHeaderDesktop from './NewChatHeaderDesktop'
import NewChatHeaderMobile from './NewChatHeaderMobile'

type NewChatProps = {
  chatCreationParams: LegacyNewChatParams
  mobile?: boolean
  onChatCreated(chatId: string): void
}

function NewChat({chatCreationParams, mobile, onChatCreated}: NewChatProps) {
  const NewChatHeader = mobile ? NewChatHeaderMobile : NewChatHeaderDesktop
  const ChatHeader = mobile ? ChatHeaderMobile : ChatHeaderDesktop
  const chatMetadata = useChatMetadata(chatCreationParams.place_id)
  const dispatch = useDispatch()

  const createdChatId = useSelector(chatsSlice.selectors.selectLegacyOpenedChatId)

  const handleChatCreated = (chatId: string) => {
    dispatch(chatsSlice.actions.openLegacyChat(chatId))
    onChatCreated(chatId)
  }

  const chatQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/chat/list',
    method: 'GET',
    params: {chat_id: createdChatId},
    enabled: Boolean(createdChatId),
    select: (data) => data.chats[0]
  })

  return (
    <>
      {!chatQuery.data ? <NewChatHeader /> : <ChatHeader chat={chatQuery.data} />}
      {chatMetadata.isLoading ? (
        <Spinner absolute />
      ) : (
        <ChatterboxContainer
          params={{...chatCreationParams, ...chatMetadata.metadata}}
          onChatCreated={handleChatCreated}
        />
      )}
    </>
  )
}

export default NewChat
