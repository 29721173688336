import {makeStyles} from 'tss-react/mui'

export const useChatInfoModalStyles = makeStyles()({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '16px',
    rowGap: '16px'
  },
  label: {
    color: '#999C9E'
  }
})
