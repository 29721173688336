import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'
import {Spinner, useIsMobileStrict} from '@eda-restapp/ui'
import {useSelector, useDispatch} from 'react-redux'

import {useApiQueryInvalidate, useApiQuery} from '@restapp/core-api'
import {useUserInfo} from '@restapp/core-auth'
import {useOldHeavyPlaces} from '@restapp/core-places'
import {Drawer} from '@restapp/shared-ui'

import Chat from '../../components/Chat'
import NewChat from '../../components/NewChat'
import {WatchChatEvents} from '../../components/WatchChatEvents'
import {chatsSlice} from '../../slice/chatsSlice'

import styles from './ChatContainer.module.css'

function ChatContainer() {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()
  const dispatch = useDispatch()
  const legacyChatId = useSelector(chatsSlice.selectors.selectLegacyOpenedChatId)

  const chatListInvalidateQuery = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v1/chat/list', 'GET'])
  const {invalidate: invalidateUnreadCount} = useApiQueryInvalidate([
    '/4.0/restapp-front/support_chat/v1/partner/notifications/count',
    'GET'
  ])

  const newChatParams = useSelector(chatsSlice.selectors.selectLegacyNewChatParams)

  const chatQuery = useApiQuery({
    url: '/4.0/restapp-front/support_chat/v1/chat/list',
    method: 'GET',
    params: {chat_id: legacyChatId},
    enabled: !!legacyChatId,
    select: (data) => data.chats[0],
    onError: () => {
      Snackbar.error({
        text: t('main-chats.support-chats.chat-unavailable', 'Извините, сейчас не можем открыть обращение')
      })

      closeChat()
    }
  })

  const isDrawerOpen = Boolean(!!legacyChatId || newChatParams)

  const {getPlaceById} = useOldHeavyPlaces({enabled: !newChatParams?.doccenter_id && Boolean(newChatParams?.place_id)})
  const userInfo = useUserInfo()

  const closeChat = () => {
    chatListInvalidateQuery.invalidate({
      // перезапрашиваем только запросы которые содержат текущий чат
      refetchPage: (page) => page.chats.some((chatItem) => chatItem.chat_id === legacyChatId)
    })
    invalidateUnreadCount()
    dispatch(chatsSlice.actions.closeLegacyChat())
  }

  function handleChatCreated() {
    chatListInvalidateQuery.invalidate()
  }

  const resolveDoccenterId = (): string => {
    if (newChatParams?.doccenter_id) {
      return newChatParams.doccenter_id
    }

    if (newChatParams?.place_id) {
      const place = getPlaceById(newChatParams.place_id)

      // For RU remains older support links-tree, for International countries use new support links-tree
      if (place?.country_code) {
        return place?.country_code === 'RU' ? `RU_${place.brand.business_type}` : place.country_code
      }
    }

    if (userInfo?.country_code) {
      return userInfo?.country_code
    }

    return 'default'
  }

  return (
    <>
      <Drawer classes={{root: styles.root}} open={isDrawerOpen} onClose={closeChat} mobile={isMobile}>
        {!newChatParams && !chatQuery.data && <Spinner />}

        {!newChatParams && chatQuery.data && <Chat chat={chatQuery.data} mobile={isMobile} />}

        {newChatParams && (
          <NewChat
            chatCreationParams={{...newChatParams, doccenter_id: resolveDoccenterId().toLowerCase()}}
            mobile={isMobile}
            onChatCreated={handleChatCreated}
          />
        )}
      </Drawer>
      <WatchChatEvents />
    </>
  )
}

export default ChatContainer
