import {useDragScroll, useIsScrollable} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Box, CopyButton} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React, {useRef} from 'react'

import {useIsScrolledHorizontally} from '@restapp/core-legacy/hooks/useIsScrolledHorizontally'
import {usePlaces} from '@restapp/core-places'
import {Table, TableBody, TableCell, TableHead, TableHeaderCell, TableContainer, TableRow} from '@restapp/shared-ui'
import {Button} from '@restapp/shared-ui/Button'

import type {IChat} from '../../types'
import {formatSla} from '../../utils/formating'
import {getChatSlaHours} from '../../utils/getChatSlaHours'
import ChatStatusChip from '../ChatStatusChip'

import styles from './ChatsTable.module.css'

type ChatsTableProps = {
  chats: IChat[]
  hideRestaurantCell?: boolean
  onClickChat(chat: IChat): void
}

function ChatsTable({chats, hideRestaurantCell, onClickChat}: ChatsTableProps) {
  const {t} = useI18n()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const {getPlaceById} = usePlaces()

  const isTableHasScroll = useIsScrollable(tableContainerRef)
  const isTableScrolled = useIsScrolledHorizontally(tableContainerRef)

  useDragScroll(tableContainerRef, 'horizontal', isTableHasScroll.horizontal)

  function formatDate(date?: moment.Moment) {
    if (!date) {
      return '—'
    }

    return (
      <>
        <div>{date.format('DD.MM.YYYY')}</div>
        <div className={styles.secondaryText}>{date.format('HH:mm')}</div>
      </>
    )
  }

  function renderCsatOrSla(chat: IChat, chatSla: number | null) {
    if (chat.ask_csat) {
      return (
        <Button
          variant='contained'
          color='primary'
          size='small'
          fullWidth
          onClick={() => metrika({target: 'chat_rate_click', params: {id: chat.chat_id}})}
        >
          {t('main-chats.chats-table.otsenit', 'Оценить')}
        </Button>
      )
    }

    if (chatSla) {
      return formatSla(chatSla)
    }

    return <div className={styles.emptyString}>—</div>
  }

  return (
    <TableContainer
      ref={tableContainerRef}
      className={cn(styles.tableContainer, isTableScrolled && styles.shadowRight)}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell className={styles.statusCell}>
              {t('main-chats.chats-table.status', 'Статус')}
            </TableHeaderCell>
            <TableHeaderCell className={styles.topicCell}>{t('main-chats.chats-table.tema', 'Тема')}</TableHeaderCell>
            {!hideRestaurantCell && (
              <TableHeaderCell>{t('main-chats.chats-table.restoran', 'Ресторан')}</TableHeaderCell>
            )}
            <TableHeaderCell align='center'>{t('main-chats.chats-table.№-zakaza', '№ Заказа')}</TableHeaderCell>
            <TableHeaderCell>{t('main-chats.chats-table.avtor', 'Автор')}</TableHeaderCell>
            <TableHeaderCell align='right'>{t('main-chats.chats-table.sozdano', 'Создано')}</TableHeaderCell>
            <TableHeaderCell>{t('main-chats.chats-table.vremya-do-resheniya', 'Время до решения')}</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chats.map((chat, i) => {
            const chatSla = getChatSlaHours({status: chat.status, sla: chat.sla})
            const chatPlace = chat.place_id ? getPlaceById(Number(chat.place_id)) : undefined
            return (
              <TableRow
                key={chat.chat_id}
                classes={{root: styles.row}}
                onClick={() => onClickChat(chat)}
                data-testid={`chats-table-row-${i}` /*Обращения | Кликабельная строка в таблице чатов*/}
              >
                <TableCell className={cn(styles.cell, styles.statusCell)}>
                  <ChatStatusChip chat={chat} />
                </TableCell>
                <TableCell className={cn(styles.cell, styles.topicCell)}>
                  <div>{chat.topic}</div>
                  <div className={styles.lastMessageWrapper}>
                    <div
                      data-testid={'chats-secondary-text' /*Обращения | Втоаря строчка текста (под темой)*/}
                      className={styles.secondaryText}
                    >
                      {chat.last_message_text}
                    </div>
                    {chat.new_message_count > 0 && (
                      <div className={styles.newMessagesCount}>{chat.new_message_count}</div>
                    )}
                  </div>
                </TableCell>
                {!hideRestaurantCell && (
                  <TableCell className={cn(styles.cell, styles.restaurantCell)}>
                    {chatPlace?.name ? <div>{chatPlace?.name}</div> : <div className={styles.emptyString}>—</div>}
                    <div className={styles.secondaryText}>{chatPlace?.address}</div>
                  </TableCell>
                )}
                <TableCell align='center' className={cn(styles.cell, styles.orderIdCell)}>
                  {chat.order_id ? (
                    <Box gap='s' className={styles.orderId}>
                      <div />
                      {chat.order_id}
                      <CopyButton className={styles.orderIdCopy} value={chat.order_id} />
                    </Box>
                  ) : (
                    <div className={styles.emptyString}>—</div>
                  )}
                </TableCell>
                <TableCell className={cn(styles.cell, styles.authorCell)}>{chat.author}</TableCell>
                <TableCell align='right' className={cn(styles.cell, styles.createdAtCell)}>
                  {formatDate(moment(chat.created_at))}
                </TableCell>
                <TableCell
                  className={cn(styles.cell, styles.slaCell, {[styles.error]: chatSla !== null && chatSla < 0})}
                >
                  {renderCsatOrSla(chat, chatSla)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ChatsTable
