import {makeStyles} from 'tss-react/mui'

export const useChatDesktopStyles = makeStyles()({
  header: {
    padding: 24
  },
  topRow: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0 8px'
  },
  closeButton: {
    marginLeft: 'auto',
    marginRight: -8
  },
  closeIcon: {
    fill: '#1F2021'
  },
  slaChip: {
    padding: '3px 8px',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500,
    backgroundColor: '#E8EBED',
    marginLeft: 8
  },
  chatIdRow: {
    marginBottom: 20,
    padding: '0 8px'
  },
  yangoTitle: {
    fontFamily: 'Yango Headline'
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '23px',
    marginTop: 24
  },
  textMinor: {
    fontSize: 14,
    lineHeight: '18px',
    color: '#999C9E'
  },
  metaDataGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    columnGap: 48,
    marginBottom: 24,
    padding: '0 8px'
  },
  newChat: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '23px'
  },
  clampId: {
    maxWidth: 91,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  yangoFont: {
    fontFamily: 'Yango Headline'
  }
})

export const useChatMobileStyles = makeStyles<{statusColor?: string}>()((theme, props) => {
  return {
    header: {
      position: 'relative',
      minHeight: 60,
      display: 'flex',
      flexDirection: 'column',
      padding: '11px 16px',
      borderBottom: '1px solid #BEC1C4',
      alignItems: 'center',
      justifyContent: 'center'
    },
    title: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '19px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    status: {
      color: props.statusColor,
      fontSize: 14,
      lineHeight: '18px'
    },
    infoIcon: {
      position: 'absolute',
      padding: 0,
      top: 18,
      left: 16,
      fill: '#000'
    },
    yangoFont: {
      fontFamily: 'Yango Headline'
    },
    yangoTitle: {}
  }
})
