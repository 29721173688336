import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import type {LegacyNewChatParams} from '../slice/chatsSlice'
import {chatsSlice} from '../slice/chatsSlice'

interface UseSupportChat {
  openNewSupportChat: (metadata?: LegacyNewChatParams) => void
  openChat: (chatId: string) => void
}

export function useSupportChat(): UseSupportChat {
  const {t} = useI18n()
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')
  const dispatch = useDispatch()
  const openSingleChat = useCallback(
    (meta: LegacyNewChatParams = {}) =>
      dispatch(
        chatsSlice.actions.openSingleChat({
          orderId: meta.order_id,
          placeId: meta.place_id
        })
      ),
    [dispatch]
  )

  return {
    openNewSupportChat: (metadata = {}) => {
      if (supportSingleChatConfig.overrideChatCreation) {
        openSingleChat(metadata)
        return
      }

      dispatch(chatsSlice.actions.setLegacyNewChatParams(metadata))
    },
    openChat: (chatId: string) => {
      if (supportSingleChatConfig.blockChatOpening) {
        Snackbar.error({
          text: t('main-chats.support-chats.chat-open-blocked', 'Извините, сейчас не можем открыть обращение')
        })
        return
      }

      dispatch(chatsSlice.actions.openLegacyChat(chatId))
    }
  }
}
