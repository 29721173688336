import {Box, Button, Tooltip, Typography} from '@eda-restapp/ui'
import React from 'react'

import styles from './AccountOnboardingTooltip.module.css'

type AccountOnboardingTooltipProps = {
  anchorEl: HTMLElement
  open: boolean
  text: string
  buttonText: string
  placement: 'top' | 'bottom' | 'right' | 'left' | 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end'
  offsetDistance?: number
  offsetSkidding?: number
  underSidebar?: boolean
  withProtal?: boolean
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

export const AccountOnboardingTooltip: React.FC<AccountOnboardingTooltipProps> = ({
  anchorEl,
  open,
  text,
  buttonText,
  placement,
  offsetDistance,
  offsetSkidding,
  underSidebar,
  withProtal,
  onClose
}) => {
  const onClickTooltip: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
  }

  return (
    <Tooltip.Onboarding
      onClick={onClickTooltip}
      anchorEl={anchorEl}
      open={open}
      placement={placement}
      offsetDistance={offsetDistance}
      offsetSkidding={offsetSkidding}
      style={{
        zIndex: underSidebar ? 99 : 1500 // 99 - чтобы было меньше чем zIndex у header'a (100)
      }}
      withPortal={withProtal}
    >
      <Box className={styles.tooltipRoot} flexDirection='column' gap='m'>
        <Typography className={styles.tooltipText} variant='body2' group='normal'>
          {text}
        </Typography>

        <Button.Text size='s' onClick={onClose}>
          {buttonText}
        </Button.Text>
      </Box>
    </Tooltip.Onboarding>
  )
}
