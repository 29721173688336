import {eventLogger} from '@eda-restapp/logger'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {memo, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

export const ProcessClickedNotifications = memo(() => {
  const navigate = useNavigate()

  useEffect(
    () =>
      notificationsChannel.subscribe((notification) => {
        if (!notification.meta.clicked) {
          return
        }

        eventLogger({
          name: 'notification_clicked',
          value: `notifications:${notification.meta.source}:${notification.event}:clicked`,
          additional: {notification}
        })

        switch (notification.event) {
          case 'order_changed_courier':
          case 'order_changed_items':
          case 'order_changed_status':
          case 'order_updated_courier':
          case 'order_new': {
            navigate(`/active/${notification.data.orderNr}`)
            break
          }

          case 'direct_low_balance': {
            navigate('/promotion/places/?modal=payment')
            break
          }

          case 'place_leader_changed': {
            navigate(`/place-leaders/${notification.data.place_id}`)
            break
          }

          default:
            break
        }
      }),
    [navigate]
  )

  return null
})
