import {keyBy} from 'lodash-es'
import type {Instance, SnapshotIn} from 'mobx-state-tree'
import {types, flow, getSnapshot} from 'mobx-state-tree'

import {injectFromEnv, injectPlaceIds} from '../../mixins'
import type {VendorApi} from '@restapp/shared-api'
import {isApiError} from '@restapp/shared-api'
import type {PlaceType} from '../Place'
import Place from '../Place'
import type {PlaceDeliveryType} from '../PlaceInfo'
import {errorLogger} from '@eda-restapp/logger'

export type RestTypes = PlaceDeliveryType | 'all'
export type RatingOrderTypes = 'desc' | 'asc'

const PlacesMap = types.map(Place)
type IPlacesMap = Instance<typeof PlacesMap>

const Places = types
  .compose(injectPlaceIds(), injectFromEnv('snackbarNotifier', 'fetchApiQuery'))
  .named('Places')
  .props({
    entities: types.optional(PlacesMap, {}),
    averageReceipts: types.maybeNull(types.map(types.number))
  })
  .views((self) => {
    const views = {
      get size() {
        return self.entities.size
      },
      get all() {
        return Array.from(self.entities.values())
      },
      get placeIds() {
        return views.all.map((place) => place.id)
      },
      get allPlacesId() {
        return this.all.map((place) => place.id)
      },
      getPlaceById(id: string | number): PlaceType | undefined {
        if (!self.entities.size || !id) {
          return undefined
        }

        return self.entities.get(id.toString())
      }
    }

    return views
  })
  .actions((self) => {
    const setRestaurants = (restaurants: IPlacesMap) => {
      self.entities = restaurants

      const selectedPlaceId = self.$placeIds.single || ''
      const selectedPlace = self.getPlaceById(selectedPlaceId)

      if (!selectedPlace) {
        return
      }
    }

    /**
     * @TODO убрать старую ручку
     */
    const fetchRestaurantsList = flow(function* () {
      try {
        const result: VendorApi['/4.0/restapp-front/places/v1/search']['GET']['response'] =
          yield self.$env.fetchApiQuery({
            url: '/4.0/restapp-front/places/v1/search',
            method: 'GET',
            params: {limit: 999},
            initialData: window.initialHeavyPlaces
          })

        if (result.payload?.length) {
          const mergeSnapshotWithExistingPlace = (snapshot: SnapshotIn<typeof Place>) => {
            const existingPlace = self.entities.get(snapshot.id.toString())

            return {...(existingPlace && getSnapshot(existingPlace)), ...snapshot}
          }
          const placesMap = PlacesMap.create(keyBy(result.payload.map(mergeSnapshotWithExistingPlace), 'id'))

          setRestaurants(placesMap)
        }
      } catch (error: unknown) {
        if (isApiError(error)) {
          errorLogger({level: 'error', additional: {eventSlug: 'places:load_list'}, error})
          self.$env.snackbarNotifier.enqueue({type: 'error', error})
        }
      }
    })

    return {
      fetchRestaurantsList
    }
  })

export default Places

export type Places = Instance<typeof Places>
