import {useMemo} from 'react'

import type {SendError, SendEvent} from './useChatterboxLogger'

type ChatterboxTimeoutLogger = {
  sendEvent: ({value, additional}: SendEvent) => undefined
  sendError: ({level, error, additional}: SendError) => undefined
}

export function useChatterboxTimeoutLogger({sendEvent, sendError}: ChatterboxTimeoutLogger) {
  return useMemo(() => {
    let startLoadingTime = 0
    let fastTimerId = 0
    let slowTimerId = 0

    return {
      start: () => {
        startLoadingTime = Date.now()

        fastTimerId = window.setTimeout(() => {
          sendError({
            level: 'warn',
            error: new Error('support-chats-iframe-loading-fast-timeout-error'),
            additional: {eventSlug: 'support-chats-iframe-loading-fast-timeout-error'}
          })
        }, 2_000)

        slowTimerId = window.setTimeout(() => {
          sendError({
            level: 'error',
            error: new Error('support-chats-iframe-loading-slow-timeout-error'),
            additional: {eventSlug: 'support-chats-iframe-loading-slow-timeout-error'}
          })
        }, 10_000)

        // Clear Tiomeouts if Chatterbox-window was closed before init
        return () => {
          window.clearTimeout(fastTimerId)
          window.clearTimeout(slowTimerId)
        }
      },

      stop: () => {
        window.clearTimeout(fastTimerId)
        window.clearTimeout(slowTimerId)
        sendEvent({
          value: 'support-chats-iframe-opened-chat',
          additional: {
            chatOpenningTime: Date.now() - startLoadingTime
          }
        })
      }
    }
  }, [sendEvent, sendError])
}
