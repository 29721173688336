import {useI18n} from '@eda-restapp/i18n'
import cn from 'classnames'
import React from 'react'

import {useServiceBrand} from '@restapp/shared'

import {useChatMobileStyles} from '../Chat/Chat.style'

function NewChatHeaderMobile() {
  const {t} = useI18n()
  const {classes: c} = useChatMobileStyles({})

  const serviceBrand = useServiceBrand()

  return (
    <div className={c.header}>
      <h1 className={cn(c.title, {[c.yangoFont]: serviceBrand === 'YangoEats'})} data-testid={'new-chat-header'}>
        {t('main-chats.new-chat-header-mobile.novoe-obrashchenie', 'Новое обращение')}
      </h1>
    </div>
  )
}

export default NewChatHeaderMobile
