enum Context {
  Router = 'EdaAppRouter',
  ProvideId = 'EdaAppProvideId',
  ResetId = 'EdaAppResetId',
  LegacyRoutes = 'EdaAppLegacyRoutes',
  ZIndex = 'EdaAppZIndex',
  ControlHistory = 'EdaAppControlHistory'
}

export default Context
