import {errorLogger} from '@eda-restapp/logger'
import type {GetDerivedStateFromError, SuspenseProps as ReactSuspenseProps} from 'react'
import React, {Component, Suspense as ReactSuspense} from 'react'

import type {ApiError} from '@restapp/shared-api'
import {isApiError} from '@restapp/shared-api'
import {Spinner} from '@restapp/shared-ui'

import SuspenseErrorPage from './SuspenseErrorPage'

type SuspenseProps = {
  slug?: string
  fallback?: ReactSuspenseProps['fallback']
  children?: React.ReactNode
}

type SuspenseState = {
  hasError: boolean
  error?: ApiError
  eventId?: string
}

/** Оборачивает компонент в Suspense и обрабатывает ошибки API */
class Suspense extends Component<SuspenseProps, SuspenseState> {
  constructor(props: SuspenseProps) {
    super(props)

    this.state = {
      hasError: false,
      error: undefined
    }
  }

  static getDerivedStateFromError: GetDerivedStateFromError<{}, SuspenseState> = (error: unknown) => {
    if (isApiError(error)) {
      return {hasError: true, error}
    }

    return {hasError: true}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (isApiError(error)) {
      errorLogger({
        error,
        level: 'error',
        sourceType: 'error-boundary-api',
        additional: {
          slug: this.props.slug,
          componentStack: errorInfo.componentStack
        }
      })

      this.setState((state) => {
        return {...state}
      })
    } else {
      throw error
    }
  }

  reset() {
    this.setState({hasError: false, error: undefined})
  }

  render() {
    const fallbackComponent = this.props.fallback ?? <Spinner absolute />

    if (this.state.hasError) {
      return <SuspenseErrorPage error={this.state.error} onRetry={() => this.reset()} eventId={this.state.eventId} />
    }

    return <ReactSuspense fallback={fallbackComponent}>{this.props.children}</ReactSuspense>
  }
}

export default Suspense
