import type {FC, ReactNode} from 'react'
// тут по-другому возникают цикл зависимости, которые кладут тесты, где нужен этот провайдер
import {PermissionsBaseProvider} from '@eda-restapp/permissions/src/PermisionsBaseProvider'
import {useUserInfo} from '@restapp/core-auth'

type PermissionsProviderProps = {
  children: ReactNode
}
export const PermissionsProvider: FC<PermissionsProviderProps> = ({children}) => {
  const userInfo = useUserInfo()

  return <PermissionsBaseProvider permissions={userInfo?.permissions ?? []}>{children}</PermissionsBaseProvider>
}
