import {makeStyles} from 'tss-react/mui'

export const usePageRootWrapperStyles = makeStyles({name: 'PageRootWrapper'})(() => {
  return {
    root: {
      width: '100%',
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'row',
      alignItems: 'stretch',
      minHeight: 0
    },
    sidebar: {
      alignSelf: 'stretch',
      display: 'flex',
      flex: '0 0 auto',
      transition: 'all .150s ease',
      ['@media print']: {
        display: 'none'
      }
    }
  }
})
