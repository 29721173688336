import {eventLogger} from '@eda-restapp/logger'
import {keyBy} from 'lodash-es'

import {useApiQuery} from '@restapp/core-api'
import {useUserInfo} from '@restapp/core-auth'
import {usePlaces} from '@restapp/core-places'
import type {Service} from '@restapp/shared-api/types/places'

type ChatsFormsAutofillMetadata = {
  city?: string
  /* Название ресторана */
  rest?: string
  /* Юридическое название (в billing) */
  company?: string
  /* Страна */
  region?: string
  /* ИНН */
  inn?: string
  email?: string
  rest_rating?: number
  photo_percent?: number
  service?: Service | 'all'
}

interface IChatMetadata {
  isLoading: boolean
  metadata: ChatsFormsAutofillMetadata
}

export default function useChatMetadata(placeId?: number): IChatMetadata {
  const {getPlaceById} = usePlaces({enabled: placeId !== undefined})
  const place = placeId ? getPlaceById(placeId) : undefined

  const userInfoQuery = useUserInfo({refetchOnWindowFocus: false})

  const ratingQuery = useApiQuery({
    url: '/4.0/restapp-front/eats-place-rating/v1/place-rating',
    method: 'GET',
    params: {place_id: placeId || NaN},
    enabled: placeId !== undefined,
    retry: false,
    refetchOnWindowFocus: false
  })

  const placeInfoQuery = useApiQuery({
    method: 'GET',
    url: '/4.0/restapp-front/places/v1/info',
    params: {
      place_id: placeId || NaN
    },
    enabled: placeId !== undefined,
    retry: false,
    refetchOnWindowFocus: false
  })

  const pictureCoverageQuery = useApiQuery({
    url: '/4.0/restapp-front/eats-restapp-menu/v1/menu/picture_coverage',
    method: 'GET',
    params: {
      place_id: placeId || NaN
    },
    enabled: placeId !== undefined,
    retry: false,
    refetchOnWindowFocus: false
  })

  function aggregateMetaData(): ChatsFormsAutofillMetadata {
    if (!place) {
      eventLogger({name: 'useDataForFormAutofill', value: 'support-chats-no-place-found'})
      return {}
    }

    const chatMetadata: ChatsFormsAutofillMetadata = {
      rest: place.name
    }

    if (ratingQuery.data) {
      chatMetadata.rest_rating = ratingQuery.data.final_rating.current_rating.rating
    }

    if (userInfoQuery?.email) {
      chatMetadata.email = userInfoQuery.email
    }

    if (pictureCoverageQuery.data) {
      chatMetadata.photo_percent = Number(pictureCoverageQuery.data.percent)
    }

    if (placeInfoQuery.data) {
      chatMetadata.city = placeInfoQuery.data?.payload.info.address.city
      chatMetadata.region = placeInfoQuery.data?.payload.info.address.country

      chatMetadata.company = placeInfoQuery.data?.payload.billing?.name
      chatMetadata.inn = placeInfoQuery.data?.payload.billing?.inn

      //#region Костыль для DC ресторанов чтобы получать инн и название из billing_info и additional_billing_info
      // Супер ненадежно потому что приходится получать ИНН по ключу который потенциально может менять из-за перевода и тп
      if (!chatMetadata.company || !chatMetadata.inn) {
        const billingInfoMap = keyBy(placeInfoQuery.data?.payload.billing_info, ({key}) => key.trim().toLowerCase())
        const additionalBillingInfoMap = keyBy(
          placeInfoQuery.data?.payload.additional_billing_info?.find(
            ({title}) =>
              title.trim().toLowerCase() === 'бухгалтерская информация delivery club' ||
              title.trim().toLowerCase() === 'бухгалтерская информация маркет деливери'
          )?.fields,
          ({key}) => key.trim().toLowerCase()
        )

        if (!chatMetadata.company) {
          chatMetadata.company =
            billingInfoMap['юридическое название']?.value || additionalBillingInfoMap['юридическое название']?.value
        }

        if (!chatMetadata.inn) {
          chatMetadata.inn = billingInfoMap['инн']?.value || additionalBillingInfoMap['инн']?.value
        }
      }
      //#endregion
    }

    if (place.services?.enabled) {
      chatMetadata.service = place.services.enabled.length > 1 ? 'all' : place.services.enabled[0]
    }

    return chatMetadata
  }

  const isAllSettled = [ratingQuery, placeInfoQuery, pictureCoverageQuery].every(
    (query) => query.isSuccess || query.isError
  )

  return {
    isLoading: !isAllSettled,
    metadata: isAllSettled ? aggregateMetaData() : {}
  }
}
