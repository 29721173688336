import {t} from '@eda-restapp/i18n'
import config from '../../../config'

import type {ErrorResponse} from './PlaceInfo'
import jsonp from './jsonp'

type GeoCodeResponse = {
  kind: string
  name: string
}

type AddressType = {city: string; address: string}
export function isValidUpdateErrors(e: any): e is ErrorResponse[] {
  return e.length && e[0].error && e[0].name
}

export function getFieldErrors(field: string, errors: ErrorResponse[]) {
  return errors.filter((item) => item.name.includes(field)).map((item) => item.error)
}

export function formatErrors(errors: string[]) {
  if (!errors.length) {
    return null
  }

  return errors.map((err, i) => <div key={i}>{err}</div>)
}

const getGeocode = async (address: string) =>
  jsonp(`https://enterprise.geocode-maps.yandex.ru/1.x/?apikey=${config.geocodeKey}&geocode=${address}&format=json`)

export async function parseAddress(address: string): Promise<AddressType> {
  const {response: geoCodeResponse} = (await getGeocode(address)) as any
  const placePartsArray: GeoCodeResponse[] =
    geoCodeResponse.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.Components

  return transformGeoResponse(placePartsArray)
}

export function transformGeoResponse(value: GeoCodeResponse[]) {
  let city = ''
  const placeAddress: string[] = []

  value.forEach((item: GeoCodeResponse) => {
    const {kind, name} = item

    if (kind === 'area' || kind === 'locality') {
      city = name
    } else if (kind !== 'country' && kind !== 'province') {
      placeAddress.push(name)
    }
  })

  return {city, address: placeAddress.join(', ')}
}

export const MAX_FIELD_LENGTH = 255

export const ERROR_FIELD_TEXTS = {
  get requiredLabel() {
    return t('core-legacy.utils.ne-mozhet-bit-pustim', 'Не может быть пустым')
  },
  get maxLengthError() {
    return t('core-legacy.utils.to-long', 'Слишком длинно. Не более {MAX_FIELD_LENGTH} символов', {
      MAX_FIELD_LENGTH
    })
  }
}

export function validateAddress(address?: string): string | undefined {
  if (!address) {
    return ERROR_FIELD_TEXTS.requiredLabel
  } else if (address.length > MAX_FIELD_LENGTH) {
    return ERROR_FIELD_TEXTS.maxLengthError
  }
}
