import React from 'react'
import {useExp3} from '@eda-restapp/configs'
import SnackbarNotifierComponent from '@restapp/core-legacy/common/components/App/SnackbarNotifier'
import {SnackbarsContainer} from './snackbars'
import {useEnv} from '@restapp/core-di'

export const SnackbarsExperiment: React.FC = () => {
  const snackbarsConfig = useExp3('restapp_snackbars')
  const {snackbarNotifier} = useEnv()

  return snackbarsConfig.enableNewSnackbars ? (
    <SnackbarsContainer />
  ) : (
    <SnackbarNotifierComponent snackbarNotifier={snackbarNotifier} />
  )
}
