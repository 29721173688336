import type {OldFullRestaurant, ShortRestaurant} from '@restapp/shared-api'

import {createMockFactory} from '../mocks/createMockFactory'

export const mockPlace = createMockFactory<ShortRestaurant>({
  id: 0,
  name: 'НанаНуна',
  address: 'Российская Федерация, Москва, Озерковская набережная, 22/24',
  is_available: true,
  is_switching_on_requested: false,
  integration_type: 'native',
  type: 'native',
  services: {
    enabled: ['eda'],
    available: [{service: 'eda', name: 'Яндекс Еда'}]
  }
})

export const mockFullPlace = createMockFactory<OldFullRestaurant>({
  id: 0,
  name: 'НанаНуна',
  is_available: true,
  is_switching_on_requested: false,
  is_plus_enabled: false,
  currency: {code: 'RUB', sign: '₽', decimal_places: 2},
  country_code: 'RU',
  region_slug: 'moscow',
  address: 'Озерковская набережная, 22/24, Москва, Российская Федерация',
  type: 'native',
  show_shipping_time: true,
  integration_type: 'native',
  slug: 'nananunatest',
  brand: {slug: 'nananuna', business_type: 'restaurant'},
  subscription: {
    tariff_type: 'free',
    is_trial: false,
    need_alerting_about_finishing_trial: false,
    valid_until_iso: '1970-01-01T00:00:00+00:00'
  },
  services: {available: [{service: 'eda', name: 'Яндекс Еда'}], enabled: ['eda']}
})
