import type {LegacyFetcher} from '@restapp/core-api'
import type {VendorApi as Api} from '@restapp/shared-api'
import type {ApiDeps, ApiQueryConfig, ApiResponse} from '@restapp/core-api/types'
import createReactQueryOptions from '@restapp/core-api/createReactQueryOptions'

export default function createLegacyFetcher(getDeps: () => ApiDeps): LegacyFetcher {
  return <TUrl extends keyof Api, TMethod extends keyof Api[TUrl], TSelectedData = ApiResponse<Api[TUrl][TMethod]>>({
    url,
    method,
    body,
    headers,
    params,
    ...options
  }: ApiQueryConfig<TUrl, TMethod, TSelectedData>) => {
    const deps = getDeps()
    return deps.queryClient.fetchQuery({
      ...createReactQueryOptions({url, method, body, headers, params}, deps),
      ...options
    }) as Promise<TSelectedData>
  }
}
