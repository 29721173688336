/* eslint-disable */
import {isBrowser} from '../Device'

const VENDOR_PREFIXES = ['ms', 'moz', 'webkit', 'o']

let lastTime = 0

const requestAnimationFrameFallback: typeof window.requestAnimationFrame = (callback) => {
  const currTime = new Date().getTime()
  const timeToCall = Math.max(0, 16 - (currTime - lastTime))
  lastTime = currTime + timeToCall

  return setTimeout(callback, timeToCall)
}

const cancelAnimationFrameFallback: typeof window.cancelAnimationFrame = clearTimeout

function getRequestAnimationFrame(): typeof window.requestAnimationFrame {
  if (!isBrowser()) {
    return requestAnimationFrameFallback
  }

  if (window.requestAnimationFrame) {
    return window.requestAnimationFrame.bind(window)
  }

  for (const prefix of VENDOR_PREFIXES) {
    const raf = (window as any)[prefix + 'RequestAnimationFrame']

    if (raf) {
      return raf.bind(window)
    }
  }

  return requestAnimationFrameFallback
}

function getCancelAnimationFrame(): typeof window.cancelAnimationFrame {
  if (!isBrowser()) {
    return cancelAnimationFrameFallback
  }

  if (window.cancelAnimationFrame) {
    return window.cancelAnimationFrame.bind(window)
  }

  for (const prefix of VENDOR_PREFIXES) {
    const cancelRaf =
      (window as any)[prefix + 'CancelAnimationFrame'] || (window as any)[prefix + 'CancelRequestAnimationFrame']

    if (cancelRaf) {
      return cancelRaf.bind(window)
    }
  }

  return cancelAnimationFrameFallback
}

const requestAnimationFrame = getRequestAnimationFrame()
const cancelAnimationFrame = getCancelAnimationFrame()

export function throttle<T extends (...args: any[]) => void>(callback: T): T {
  let pending = false
  let lastArgs: any[] = []

  const fn = (...args: any[]) => {
    lastArgs = args
    if (pending) {
      return
    }
    pending = true

    requestAnimationFrame(() => {
      callback(...lastArgs)
      pending = false
    })
  }

  return fn as T
}
