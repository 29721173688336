import {makeStyles} from 'tss-react/mui'

export const useChatStatusChipStyles = makeStyles()({
  status: {
    display: 'inline-block',
    padding: '3px 8px',
    borderRadius: 6,
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500
  },
  statusOpen: {
    background: '#FCE45E'
  },
  statusPending: {
    background: '#0596FA',
    color: '#FFFFFF'
  },
  statusWaitingCsat: {
    color: '#FFFFFF'
  },
  statusSolved: {
    background: '#CDD0D2',
    color: '#FFFFFF'
  }
})
