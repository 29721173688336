import qs from 'querystring'

import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Snackbar} from '@eda-restapp/snackbars'
import React, {createRef, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'

import {selectAuthHeaders, selectToken} from '@restapp/core-auth'
import {useOldHeavyPlaces} from '@restapp/core-places'
// TODO: Circle deps
import withSuspense from '@restapp/shared-boundary/withSuspense'
import Spinner from '@restapp/shared-ui/Spinner'

import {useChatterboxLogger} from '../../hooks/useChatterboxLogger'
import type {ChatterboxPostMessageData} from '../../types'

import styles from './ChatterboxContainer.module.css'

export interface ChatterboxIframeQueryParams {
  [key: string]: string | number | undefined | string[]
  place_id?: number
  order_id?: string
  path?: string
}

type ChatterboxContainerProps = {
  params?: ChatterboxIframeQueryParams
  chatId?: string
  onChatCreated?(chatId: string): void
}

function ChatterboxContainer({chatId, params = {}, onChatCreated}: ChatterboxContainerProps) {
  const configs = useExp3()
  const [loading, setLoading] = useState(true)
  const {lang, t} = useI18n()
  const {getPlaceById} = useOldHeavyPlaces({enabled: Boolean(params?.place_id)})

  const frameRef = createRef<HTMLIFrameElement>()
  const legacyToken = useSelector(selectToken)
  const authHeaders = useSelector(selectAuthHeaders)

  const fetchToken = async () => {
    const res = await fetch('/4.0/restapp-front/authorizer/v2/session', {headers: authHeaders})
    const token = res.headers.get('X-Eats-Restapp-Token')
    if (token) {
      return token
    } else {
      throw new Error('failed to get token')
    }
  }

  const showTokenError = () => {
    Snackbar.error({
      text: t('main-chats.chatterbox-container.failed-to-get-chat-token', 'Не удалось авторизоваться в чатах'),
      errorCode: 'failed_to_get_chat_token'
    })
  }

  const sessionQuery = useQuery({
    queryFn: fetchToken,
    onError: showTokenError,
    enabled: !legacyToken,
    refetchOnWindowFocus: false
  })
  const fetchedToken = sessionQuery.data

  useEffect(() => {
    if (chatId) {
      return
    }

    const handleMessage = (event: MessageEvent<ChatterboxPostMessageData>) => {
      if (typeof event.data === 'object' && event.data.type === 'CHAT_CREATED' && event.data.data.id) {
        void onChatCreated?.(event.data.data.id)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [chatId, onChatCreated])

  const tokenToUse = legacyToken || fetchedToken || null
  const frameSrc = tokenToUse ? buildFrameSrc(tokenToUse) : ''

  useChatterboxLogger({
    chatId,
    frameSrc: tokenToUse ? frameSrc?.replace(tokenToUse, 'REPLACED_TOKEN') : '',
    params,
    frameRef
  })

  function buildFrameSrc(token: string): string | undefined {
    const chatterbox = configs.restapp_support_chats?.chatterbox

    if (!chatterbox) {
      const baseUrl = configs.restapp_support_chats?.chatterboxUrl

      return baseUrl ? getFramePath(baseUrl, '/eda-restaurants/from-order.html', token) : undefined
    }

    const placeCountryCode = params?.place_id && getPlaceById(params.place_id)?.country_code

    const basePathWithLocalization = chatterbox.basePath
      .replace(chatterbox.basePathLangTemplate, lang)
      .replace(chatterbox.basePathCountryTemplate, placeCountryCode || lang.toUpperCase())

    return getFramePath(chatterbox.host + basePathWithLocalization, chatterbox.ordersTopicPath, token)
  }

  function handleLoad() {
    setLoading(false)
  }

  function getFramePath(baseUrl: string, ordersPath: string, token: string) {
    let path = ''

    const {path: _path, ...queryParams} = params

    if (!token) {
      throw new Error('token not found')
    }

    if (chatId) {
      path = `/chat/${chatId}`

      return baseUrl + path + `?${qs.stringify({...queryParams, token})}`
    }

    if (queryParams.order_id) {
      // Сразу открываем поддерево с вопросами по заказам
      path = ordersPath
    }

    if (params.path) {
      path = params.path
    }

    return baseUrl + path + `?${qs.stringify({...queryParams, token, ui_controls: 'content_back_button'})}`
  }

  const spinnerCaption = tokenToUse
    ? t('main-chats.chatterbox-container.opening-support', 'Открываем поддержку')
    : t('main-chats.chatterbox-container.fetching-auth-token', 'Получаем токен авторизации')

  return (
    <div className={styles.root}>
      {loading && <Spinner caption={spinnerCaption} absolute size={32} />}
      {!!tokenToUse && (
        <iframe
          ref={frameRef}
          src={frameSrc}
          frameBorder={0}
          width='100%'
          height='100%'
          onLoad={handleLoad}
          className={styles.iframe}
        />
      )}
    </div>
  )
}

export default withSuspense(ChatterboxContainer, {slug: 'ChatterboxContainer'})
