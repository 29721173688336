import {useContext} from 'react'

import type {AppStateType} from '@restapp/core-legacy/AppState'
import type {IEnv} from '@restapp/core-legacy/types'

import {AppStateContext, EnvContext} from './context'

/**
 * @deprecated
 */
export function useAppState(): AppStateType {
  const appState = useContext(AppStateContext)

  if (appState === null) {
    throw new Error('AppState not found. You should wrap components with di/Provider')
  }

  return appState
}

/**
 * @deprecated
 */
export function useEnv(): IEnv {
  const env = useContext(EnvContext)

  if (env === null) {
    throw new Error('Env not found. You should wrap components with di/Provider')
  }

  return env
}
