import {Box, Typography} from '@eda-restapp/ui'
import React, {type FC} from 'react'

import type {PlaceBlock} from '@restapp/shared-api'

import {useBlockInfoElementLabel} from '../hooks/useBlockInfoElementLabel'

type BlockInfoElementProps = {
  blockInfo?: PlaceBlock
  blockInfoId: string
  isBlockTypeInInfinityList?: boolean
}

export const BlockInfoElement: FC<BlockInfoElementProps> = ({
  blockInfo,
  blockInfoId,
  isBlockTypeInInfinityList = false
}) => {
  const infoElementData = useBlockInfoElementLabel({blockInfo, blockInfoId, isBlockTypeInInfinityList})

  return (
    <Box flexDirection='column' gap='xs'>
      <Typography color='minor' group='normal' variant='body2'>
        {infoElementData.label}
      </Typography>

      <Typography group='normal' variant='body2'>
        {infoElementData.data}
      </Typography>
    </Box>
  )
}
