import type {Instance} from 'mobx-state-tree'
import {types, applySnapshot, flow} from 'mobx-state-tree'

import Places from './models/Places/Places/Places'

const AppState = types
  .model('AppState', {
    places: types.optional(Places, {}),
    reduxState: types.maybe(types.frozen<unknown>()),
    isReady: false
  })
  .actions((self) => {
    const initData = flow(function* () {
      yield self.places.fetchRestaurantsList()

      self.isReady = true
    })

    const resetStore = () => {
      applySnapshot(self, {
        places: {},
        reduxState: self.reduxState,
        isReady: self.isReady
      })
    }

    return {
      initData,
      resetStore,
      setReduxState(state: unknown) {
        self.reduxState = state
      }
    }
  })

export default AppState

export type AppStateType = Instance<typeof AppState>
