import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Messenger} from '@eda-restapp/microfrontend'
import {Box, ChevronLeftMIcon} from '@eda-restapp/ui'
import type {DrawerProps} from '@mui/material'
import {Drawer} from '@mui/material'
import cn from 'classnames'
import {observer} from 'mobx-react'
import React from 'react'
import {useLocation} from 'react-router-dom'

import {useIsLargeDesktop, useServiceBrand} from '@restapp/shared'
import {LanguageSelector} from '@restapp/shared/components'

import GarlandImage from './newYearAssets/garland.webp'
import SnowImage from './newYearAssets/snow.png'
import YandexEatsImage from './newYearAssets/YandexEats.png'
import YandexEdaImage from './newYearAssets/YandexEda.png'
import YangoEatsImage from './newYearAssets/YangoEats.png'
import {useSidebarStyles} from './Sidebar.styles'
import type {SidebarElement} from './Sidebar.types'
import SidebarNavElement from './SidebarNavElement'
import {SidebarNavGroup} from './SidebarNavGroup'

export type SidebarProps = {
  logoPath: string
  isVisible: boolean
  setVisibility: (isVisible: boolean) => void
  webVersion: string
  appVersion?: string
  mainElements: SidebarElement[]
  footerElements: SidebarElement[]
  Widget?: React.ReactNode
  variant?: DrawerProps['variant']
  renderAccount: (mini: boolean) => React.ReactNode
  onNavigationPerformed: () => void
}

const Sidebar: React.FC<SidebarProps> = ({
  logoPath,
  setVisibility,
  isVisible,
  webVersion,
  mainElements,
  footerElements,
  Widget,
  variant,
  appVersion,
  renderAccount,
  onNavigationPerformed
}) => {
  const {t} = useI18n()
  const {classes: c} = useSidebarStyles()
  const isLargeDesktop = useIsLargeDesktop()
  const toggleSidebar = () => setVisibility(!isVisible)
  const serviceBrand = useServiceBrand()
  const location = useLocation()

  const newYearConfig = useExp3('restapp_new_year')
  const promotionOnboardingLandingConfig = useExp3('restapp_promotion_onboarding_landing')

  Messenger.useReceiveEvent(Messenger.MessageType.OPEN_SIDEBAR, toggleSidebar)

  const isMiniDrawer = !isVisible && isLargeDesktop
  const isSquareDrawer = promotionOnboardingLandingConfig.enabled && location.pathname === '/promotion'

  const renderElement = (sidebarElement: SidebarElement) => {
    // TODO: Причесать после выпила Sidebar.legacy
    if (sidebarElement.type === 'separator') {
      return (
        <div key={sidebarElement.label} className={cn(c.separator, {[c.separatorMini]: isMiniDrawer})}>
          {sidebarElement.label}
        </div>
      )
    }

    if (sidebarElement.type === 'group') {
      return (
        <SidebarNavGroup
          key={sidebarElement.label}
          mini={isMiniDrawer}
          navGroup={sidebarElement}
          onLinkClick={onNavigationPerformed}
        />
      )
    }

    if (!sidebarElement.visible) {
      return null
    }

    const onClick = () => {
      if (sidebarElement.onClick) {
        sidebarElement.onClick()
      }

      onNavigationPerformed()
    }

    if (sidebarElement.path === '/logout') {
      // TODO: Remove, when logout will be deleted from configs.
      return null
    }

    return (
      <SidebarNavElement
        mini={isMiniDrawer}
        key={sidebarElement.path}
        path={sidebarElement.path}
        label={sidebarElement.name}
        color={sidebarElement.color}
        onClick={onClick}
        icon={sidebarElement.icon}
        badge={sidebarElement.badge}
        external={sidebarElement.external}
        target={sidebarElement.target}
      />
    )
  }

  const getVersions = () => {
    const text = isMiniDrawer ? '' : `${t('main-layout.sidebar.versiya', 'Версия:')} `
    const VERSION_REGEXP = /(\d+\.)?(\d+\.)?(\*|\d+)?(hotfix)?(\d+)?/
    const arrayMatchWebVersionWithRegexp = webVersion.match(VERSION_REGEXP)
    const matchWebVersion =
      arrayMatchWebVersionWithRegexp && arrayMatchWebVersionWithRegexp[0] ? arrayMatchWebVersionWithRegexp[0] : 'DEV'

    const versions = [appVersion, matchWebVersion].filter((v) => v)

    return text + versions.join(isMiniDrawer ? ' ' : ' / ')
  }

  const getNewYearLogo = () => {
    if (serviceBrand === 'YangoEats') {
      return YangoEatsImage
    }

    if (serviceBrand === 'YandexEats') {
      return YandexEatsImage
    }

    return YandexEdaImage
  }

  const newYearLogo = getNewYearLogo()

  // Можем не ренедрить на мобилках свернутый сайдбар
  if (!isLargeDesktop && !isMiniDrawer && !isVisible) {
    return null
  }

  return (
    <Drawer
      classes={{
        paper: cn(c.drawerPaper, c.styledScrollbar, {
          [c.drawerPaperSquare]: isSquareDrawer,
          [c.drawerPaperMini]: isMiniDrawer,
          [c.miniSidebarScroll]: isMiniDrawer,
          [c.drawerPaperHidden]: !isVisible && !isLargeDesktop,
          [c.newYearDrawerPaper]: newYearConfig.enabled
        })
      }}
      data-isminidrawer={isMiniDrawer} // Используется в стилях бейджей для правильного отображения в свернутом сайдбаре
      variant={variant || (!isLargeDesktop ? 'temporary' : 'permanent')}
      anchor='left'
      open={isVisible}
      ModalProps={{keepMounted: !isLargeDesktop}}
      data-testid={'ui-sidebar-drawer' /* UI | Шторка сайдбара (навигация) */}
      elevation={16}
      onClose={() => setVisibility(false)}
    >
      <Box className={c.wrapper} flex={1} flexDirection='column'>
        {newYearConfig.enabled && (
          <>
            <img src={GarlandImage} className={cn(c.newYearImage, {[c.newYearImageHidden]: isMiniDrawer})} />
            {serviceBrand !== 'YangoEats' && (
              <img src={SnowImage} className={cn(c.newYearImage, {[c.newYearImageHidden]: isMiniDrawer})} />
            )}
          </>
        )}
        <Box flex={1} flexDirection='column'>
          <div
            className={cn(c.head, {
              [c.newYearHead]: newYearConfig.enabled,
              [c.headWhenOpen]: isVisible
            })}
          >
            {isVisible && !newYearConfig.enabled && (
              <div className={c.logo} style={{backgroundImage: `url(${logoPath})`}} data-testid='sidebar-label' />
            )}
            {newYearConfig.enabled && (
              <div
                className={cn(c.newYearLogoContainer, {
                  [c.newYearLogoContainerVisible]: isVisible
                })}
              >
                <img
                  src={newYearLogo}
                  className={cn(c.newYearLogo, {[c.newYearLogoYandexEats]: serviceBrand === 'YandexEats'})}
                />
              </div>
            )}
            <ChevronLeftMIcon
              data-testid='sidebar-toggle-icon'
              className={cn(c.toggleChevron, {
                [c.newYearToggleChevron]: newYearConfig.enabled,
                [c.rotate180]: !isVisible
              })}
              onClick={toggleSidebar}
            />
          </div>
          {!isMiniDrawer && Widget}
          <Box className={c.navList} flex={1} flexDirection='column' mt='s'>
            {mainElements.map((link) => renderElement(link))}
          </Box>
          <Box flexDirection='column' mt='l'>
            {footerElements.map((link) => renderElement(link))}
            {renderAccount(isMiniDrawer)}
          </Box>
          <Box
            flexDirection={isMiniDrawer ? 'column' : 'row'}
            justifyContent='space-between'
            pl={isMiniDrawer ? undefined : 'm'}
          >
            <div data-testid='sidebar-version' className={cn(c.versionBlock, {[c.versionBlockMini]: isMiniDrawer})}>
              {getVersions()}
            </div>
            <LanguageSelector
              classes={{
                button: cn({
                  [c.newYearLanguageSelectorButton]: newYearConfig.enabled,
                  [c.laguageSelectorHoverButton]: isMiniDrawer
                }),
                menu: c.languageSelectorMenu
              }}
              anchorOrigin={{
                vertical: isLargeDesktop ? 'bottom' : 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: isLargeDesktop ? 'left' : 'right'
              }}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default observer(Sidebar)
