import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {Box, Typography, useIsMobileStrict, PhoneNumber, Informer} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import {SingleChatPlateContainer} from '@restapp/main-chats/messenger'
import {Page, PageContent} from '@restapp/main-layout'
import {SectionFeedbackContainer} from '@restapp/shared/components'
import {SectionSlug} from '@restapp/shared-api'
import {Suspense} from '@restapp/shared-boundary'

import ChatsHeader from '../../components/ChatsHeader'
import {ChatsListContainer} from '../../containers/ChatsListContainer'
import {useSupportChat} from '../../hooks/useSupportChat'

import styles from './ChatsPage.module.css'

const ChatsPage: React.FC = () => {
  const {t} = useI18n()
  const supportConfig = useExp3('restapp_support')
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')
  const isMobile = useIsMobileStrict()
  const {openNewSupportChat} = useSupportChat()

  // TODO: Switch to using libgeobase and reading supportInfo to scheme <restapp_common>[internationalSupportContacts][domain][libgeobaseCountry]
  const {supportPhoneNumber, timeRange} = supportConfig.footerCallCenterSupportInfo

  const handleCreateChat = (placeId: number) => {
    metrika({
      target: 'chats_new_chat_click_main'
    })

    return openNewSupportChat({place_id: placeId})
  }

  const feedbackSelectionMessages = {
    initial: t('main-chats.chats-page.feedback-selection-message-initial', 'Насколько удобно работать с поддержкой?'),
    lowRate: t('main-chats.chats-page.feedback-selection-message-low-rate', 'Что пошло не так?'),
    highRate: t('main-chats.chats-page.feedback-selection-message-high-rate', 'Спасибо! Что здесь можно улучшить?'),
    placeholderLowRate: t(
      'main-chats.chats-page.feedback-selection-message-placeholder-low-rate',
      'Поделитесь, с какими трудностями вы столкнулись при работе с этим разделом'
    ),
    placeholderHighRate: t(
      'main-chats.chats-page.feedback-selection-message-placeholder-high-rate',
      'Поделитесь, как сделать этот раздел более удобным и полезным для вас'
    )
  }

  return (
    <Page className={styles.backgroundGray}>
      <ChatsHeader onCreateChat={handleCreateChat} />

      <PageContent
        className={cn(styles.content, supportConfig.footerCallCenterSupportInfo.enabled && styles.roundBottom)}
      >
        <Suspense>
          <Box pt='m' pb='m' flexDirection='column' gap='m' flexGrow={1} style={{maxWidth: 1440}}>
            <Box flexDirection='column' gap='m'>
              {supportConfig.showFeedbackSection && (
                <Box>
                  <SectionFeedbackContainer slug={SectionSlug.chats} messages={feedbackSelectionMessages} />
                </Box>
              )}

              {supportSingleChatConfig.displayReadOnlyInformer && (
                <Informer type='error'>
                  {t('main-chats.support-chats.read-only-info', 'Чат с оператором доступен только для чтения')}
                </Informer>
              )}

              {supportSingleChatConfig.displayDefaultInformer && (
                <Informer type='error'>
                  {t(
                    'main-chats.support-chats.default-info',
                    'У нас что-то сломалось, вы не можете открыть старые обращения, но можете открыть чат с оператором'
                  )}
                </Informer>
              )}

              <SingleChatPlateContainer style={{zIndex: 1}} />
            </Box>

            <Box flexGrow={1} flexDirection='column'>
              <Suspense>
                <ChatsListContainer className={styles.list} />
              </Suspense>
            </Box>
          </Box>
        </Suspense>
      </PageContent>

      {supportConfig.footerCallCenterSupportInfo.enabled && (
        <Box className={styles.footer}>
          <Box
            gap={isMobile ? 's' : 'm'}
            justifyContent='end'
            flexGrow={1}
            style={{maxWidth: 1440}}
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'center' : 'start'}
          >
            <Box flexDirection='column' alignItems={isMobile ? 'center' : 'start'}>
              <Typography variant='body2'>
                {t('core-legacy.landing-app.partner-support', 'Поддержка партнёров')}
              </Typography>

              <PhoneNumber variant='body2' thickness='medium' enableCopy value={supportPhoneNumber} />
            </Box>

            <Box flexDirection='column' alignItems={isMobile ? 'center' : 'start'}>
              <Typography variant='body2'>
                {t('core-legacy.landing-app.yango-partner-support-time-range-label', 'Время работы')}
              </Typography>

              <Typography variant='body2' thickness='medium'>
                {timeRange}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Page>
  )
}

export default ChatsPage
