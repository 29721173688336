import {useExp3} from '@eda-restapp/configs'
import {errorLogger, eventLogger, setSensitiveStrings} from '@eda-restapp/logger'
import {omit} from 'lodash-es'
import {observer} from 'mobx-react'
import {useDispatch} from 'react-redux'

import {useApiMutation} from '@restapp/core-api'
import {usePassportEnabled} from '@restapp/core-auth/passport/hooks'
import {LandingApp} from '@restapp/core-legacy/common/components/LandingApp'
import {useIsEnabledInCurrentNetwork} from '@restapp/shared'
import {isApiError} from '@restapp/shared-api'

import {setToken} from '../../slice'

import {Auth} from './Auth'
import OldAuth from './components/OldAuth/OldAuth'

function AuthContainer() {
  const dispatch = useDispatch()
  const passportEnabled = usePassportEnabled()
  const authConfig = useExp3('restapp_auth')
  const newAuthPage = useIsEnabledInCurrentNetwork(authConfig.enableNewAuthPage)

  const loginMutation = useApiMutation(
    {url: '/4.0/restapp-front/api/v1/client/login', method: 'POST'},
    {
      onError: () => {
        // ignore snackbar
      }
    }
  )

  const login = async (email: string, password: string): Promise<string | undefined> =>
    new Promise((resolve) => {
      setSensitiveStrings(password)
      setSensitiveStrings(email)
      loginMutation.mutate(
        {headers: {'X-Eats-Restapp-Auth-Creds': password}, body: {email}},
        {
          onSuccess: (data) => {
            if (data.isSuccess) {
              dispatch(setToken(data.payload.token))
              eventLogger({
                name: 'authentication',
                additional: {eventData: omit(data.payload, 'token', 'credentials')}
              })
              resolve(undefined)
            }
          },
          onError: (error) => {
            if (isApiError(error)) {
              errorLogger({
                level: 'error',
                error: error,
                additional: {eventSlug: 'login'}
              })
              // FIXME: Legacy ручка со старым форматом ошибок
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line
              resolve(error.responseData.errors && error.responseData.errors[0].message)
            }
          }
        }
      )
    })

  return (
    <LandingApp isAuthPage>
      {newAuthPage ? (
        <Auth login={login} />
      ) : (
        <OldAuth login={login} isPassportAuthAvailable={passportEnabled.enabled} />
      )}
    </LandingApp>
  )
}

export default observer(AuthContainer)
