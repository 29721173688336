import {useI18n} from '@eda-restapp/i18n'
import {Placeholder, Box, LockIcon} from '@eda-restapp/ui'
import React from 'react'

import {Header, HeaderRow, Page, PageContent, SidebarBurger} from '@restapp/main-layout'

import styles from './Placeholders.module.css'

type Props = {
  screenTitle: string
}

function NoPermissionsScreen({screenTitle}: Props) {
  const {t} = useI18n()

  return (
    <Page>
      <Header>
        <HeaderRow stickyPosition={0}>
          <SidebarBurger />
          <h1>{screenTitle}</h1>
        </HeaderRow>
      </Header>
      <PageContent>
        <Box px='m' className={styles.container}>
          <Placeholder
            img={<LockIcon fontSize={87} color={'#b0b0b0'} />}
            title={t(
              'core-legacy.no-permissions.u-vas-net-dostupa-k-etomu-razdelu',
              'У вас нет доступа к этому разделу'
            )}
          />
        </Box>
      </PageContent>
    </Page>
  )
}

export default NoPermissionsScreen
