import {combineReducers} from 'redux'

import {PLACES_DROPDOWN_REDUCER_KEY} from '@restapp/core-places'
import {authSlice} from '@restapp/core-auth/slice'
import {LAYOUT_REDUCER_KEY, layoutReducer} from '@restapp/main-layout'
import networkSlice from '@restapp/core-legacy/common/components/App/Network/networkSlice'
import {makePersistent} from '@restapp/core-store'
import {internationalSlice} from '@restapp/shared/slice'
import {MenuStore} from '@restapp/shared-menu'
import {nativeSlice} from './native/slice'
import {snackbarsSlice} from './snackbars'
import {serviceSlice} from './serviceSlice'
import {COMMUNICATIONS_REDUCER_KEY, communicationsReducer} from '@restapp/main-communications/slice'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import {soundsSlice} from './sounds'
import {placesDropdownReducer} from '@restapp/core-places/slice'
import {PLACES_SCHEDULE_SLICE_KEY, placesScheduleReducer} from '@restapp/main-places'

declare module 'react-redux' {
  interface DefaultRootState extends ReturnType<typeof appReducer> {}
}

const appReducer = combineReducers({
  [PLACES_DROPDOWN_REDUCER_KEY]: placesDropdownReducer,
  [PLACES_SCHEDULE_SLICE_KEY]: placesScheduleReducer,
  [authSlice.name]: makePersistent(authSlice.reducer, authSlice.name),
  [internationalSlice.name]: makePersistent(internationalSlice.reducer, internationalSlice.name),
  [soundsSlice.name]: makePersistent(soundsSlice.reducer, soundsSlice.name),
  [LAYOUT_REDUCER_KEY]: layoutReducer,
  [networkSlice.name]: networkSlice.reducer,
  [MenuStore.slice.name]: MenuStore.slice.reducer,
  [nativeSlice.name]: nativeSlice.reducer,
  [snackbarsSlice.name]: snackbarsSlice.reducer,
  [serviceSlice.name]: serviceSlice.reducer,
  [COMMUNICATIONS_REDUCER_KEY]: communicationsReducer,
  [chatsSlice.name]: chatsSlice.reducer
})
export type ReduxState = ReturnType<typeof appReducer>

export default appReducer
