import {useI18n} from '@eda-restapp/i18n'
import cn from 'classnames'
import React from 'react'

import {useServiceBrand} from '@restapp/shared'

import {useChatDesktopStyles} from '../Chat/Chat.style'

function NewChatHeaderDesktop() {
  const {t} = useI18n()
  const {classes: c} = useChatDesktopStyles()

  const serviceBrand = useServiceBrand()

  return (
    <div className={c.header}>
      <div
        className={cn(c.newChat, {[c.yangoFont]: serviceBrand === 'YangoEats'})}
        data-testid={'new-chat-header' /* Шторка поддержки | Заголовок */}
      >
        {t('main-chats.new-chat-header-desktop.novoe-obrashchenie', 'Новое обращение')}
      </div>
    </div>
  )
}

export default NewChatHeaderDesktop
