import type {Category, MenuItem, Modifier, ModifierGroup} from '@restapp/shared-api'

import strawberryThumbnails from '../assets/strawberry-thumbnails.jpg'
import strawberry from '../assets/strawberry.jpg'
import {createMockFactory} from '../mocks/createMockFactory'

export const mockMenuCategory = createMockFactory<Category>({
  id: '1',
  available: true,
  name: 'Супы',
  sortOrder: 100
})

export const mockMenuItem = createMockFactory<MenuItem>({
  id: '10192708',
  categoryId: '103263',
  name: 'Земляника с сахаром',
  description: '',
  price: 200.0,
  vat: 0,
  measure: 50.0,
  measureUnit: 'г',
  sortOrder: 100,
  modifierGroups: [],
  images: [{url: strawberry}],
  thumbnails: [{url: strawberryThumbnails}],
  available: true
})

export const mockModifierGroup = createMockFactory<ModifierGroup>({
  id: '1000',
  maxSelectedModifiers: 3,
  minSelectedModifiers: 1,
  modifiers: [],
  name: 'Группа опций',
  sortOrder: 100
})

export const mockModifier = createMockFactory<Modifier>({
  available: true,
  id: '5000',
  maxAmount: 1,
  minAmount: 1,
  name: 'Опция',
  price: 0,
  reactivatedAt: null
})
