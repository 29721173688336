import {InfoIcon} from '@eda-restapp/ui'
import {IconButton} from '@mui/material'
import cn from 'classnames'
import React from 'react'

import type {WithPortalProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'
import {useServiceBrand} from '@restapp/shared'

import type {IChat} from '../../types'
import {getChatStatusText} from '../../utils/getChatStatusText'
import ChatInfoModal from '../ChatInfoModal'

import {useChatMobileStyles} from './Chat.style'

type ChatMobileProps = {
  chat: IChat
}

function getStatusColor(status: IChat['status']) {
  switch (status) {
    case 'open':
      return '#FCE45E'
    case 'pending':
      return '#0596FA'
    case 'solved':
      return '#CDD0D2'
  }
}

function ChatHeaderMobile({chat, openPortal}: ChatMobileProps & WithPortalProps) {
  const {classes: c} = useChatMobileStyles({statusColor: getStatusColor(chat.status)})
  const serviceBrand = useServiceBrand()

  function handleInfoClick() {
    openPortal(<ChatInfoModal chat={chat} />, {initialZIndex: 9999})
  }

  return (
    <div className={c.header}>
      <IconButton size='small' onClick={handleInfoClick} className={c.infoIcon}>
        <InfoIcon />
      </IconButton>

      <h1 className={cn(c.title, {[c.yangoTitle]: serviceBrand === 'YangoEats'})}>{chat.topic}</h1>
      <div className={c.status}>{getChatStatusText(chat.status)}</div>
    </div>
  )
}

export default withPortals(ChatHeaderMobile)
