import {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useAppState} from '@restapp/core-di'
import {notificationsChannel} from '@eda-restapp/microfrontend'

const WatchPlaceToggle = () => {
  const {places} = useAppState()

  useEffect(() => {
    const unsubscribe = notificationsChannel.subscribe((notification) => {
      if (notification.event === 'place_toggle') {
        places.fetchRestaurantsList()
      }
    })

    return unsubscribe
  }, [places])

  return null
}

export default observer(WatchPlaceToggle)
