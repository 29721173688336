import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Modal, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import {isNull} from 'lodash-es'
import React, {type FC, type Dispatch} from 'react'

import type {ShortRestaurant, PlaceDisablingReasonCode} from '@restapp/shared-api'

import {Timers} from '../../DisablePlaceModal.constants'
import type {PlaceDisableParams} from '../../DisablePlaceModal.types'
import {CustomPeriodSelectorContainer} from '../CustomPeriodSelector/CustomPeriodSelector.container'
import {DisablePlacePeriodChipsGroup} from '../DisablePlacePeriodChipsGroup/DisablePlacePeriodChipsGroup'
import {DisablePlaceReasonChipsGroup} from '../DisablePlaceReasonChipsGroup/DisablePlaceReasonChipsGroup'

export type DisablePlaceWithoutOrdersModalProps = {
  disableReasons?: PlaceDisableParams[]
  errorMessage?: string
  isNotSelectedAllDisableSettings: boolean
  isPlaceDisabling: boolean
  isValidSettings: boolean
  placeAddress: ShortRestaurant['address']
  placeName: ShortRestaurant['name']
  placeTimezone: string
  placeType: ShortRestaurant['type']
  reasonCode: PlaceDisablingReasonCode | null
  timeId: Timers | null
  handleCustomTimePickerChange: (customDate: moment.Moment) => void
  handleDisablePeriodDurationChange: Dispatch<React.SetStateAction<Timers | null>>
  onChangeDisableReasonCodeClick: (reasonCode: PlaceDisablingReasonCode) => void
  onCloseClick: () => void
  onDisableButtonClick: () => void
}

export const DisablePlaceWithoutOrdersModal: FC<DisablePlaceWithoutOrdersModalProps> = ({
  disableReasons,
  errorMessage,
  isNotSelectedAllDisableSettings,
  isPlaceDisabling,
  isValidSettings,
  placeAddress,
  placeName,
  placeTimezone,
  placeType,
  reasonCode,
  timeId,
  handleCustomTimePickerChange,
  handleDisablePeriodDurationChange,
  onChangeDisableReasonCodeClick,
  onCloseClick,
  onDisableButtonClick
}) => {
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()

  const maxTurnOffDaysForReason = disableReasons?.find((disableParam) => disableParam.value === reasonCode)?.days_limit

  return (
    <Modal open onClose={onCloseClick}>
      <Modal.Header title={t('core-legacy.disable-reason-form.modal-header', 'Отключим ресторан')} />
      <Modal.Content>
        <Box flexDirection='column' gap='l'>
          <Box flexDirection='column' gap='xs'>
            <div>{`${placeName} - ${placeAddress}`}</div>
            <Typography color='minor' group='normal' variant='caption1'>
              {placeType === 'native'
                ? t(
                    'core-legacy.disable-place-modal.content-native-place-delivery-hint-text-with-service',
                    'Доставка сервисом {eatsServiceBrandLabel}'
                  )
                : t('core-legacy.disable-place-modal.content-marketplace-delivery-hint-text', 'Доставка ресторана')}
            </Typography>
          </Box>

          <Box flexDirection='column' gap='s'>
            <Box flexDirection='column'>
              <Typography variant='caption1'>
                {t('core-legacy.disable-reason-form.disable-reason-group-title', 'Почему нужно отключить:')}
              </Typography>
              <Box flexWrap='wrap'>
                <DisablePlaceReasonChipsGroup
                  disablingParams={disableReasons}
                  disableReasonCode={reasonCode}
                  onChangeDisableReasonCode={onChangeDisableReasonCodeClick}
                />
              </Box>
            </Box>
            {!isNull(reasonCode) && (
              <Box flexDirection='column'>
                <Typography variant='caption1'>
                  {t('core-legacy.disable-place-without-orders-modal.disable-period-group-title', 'На сколько:')}
                </Typography>
                <Box flexWrap='wrap'>
                  <DisablePlacePeriodChipsGroup
                    disablingParams={disableReasons}
                    disablePeriodDuration={timeId}
                    disableReasonCode={reasonCode}
                    onChangeDisablePeriodDuration={handleDisablePeriodDurationChange}
                  />
                </Box>
              </Box>
            )}
            {timeId === Timers.OTHER && (
              <CustomPeriodSelectorContainer
                disableReasonCode={reasonCode}
                maxTurnOffDaysCount={maxTurnOffDaysForReason}
                placeTimezone={placeTimezone}
                onChange={handleCustomTimePickerChange}
              />
            )}
          </Box>
        </Box>

        {!!errorMessage && !isValidSettings && (
          <Typography color='error' justifyContent='center' mt='m' variant='caption1'>
            {errorMessage}
          </Typography>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Box flexDirection={isMobile ? 'column' : 'row'} justifyContent='space-between' gap={isMobile ? 's' : 'm'}>
          <Button.Text fullWidth variant='secondary' onClick={onCloseClick}>
            {t('core-legacy.disable-reason-form.cancel-footer-button-text', 'Не отключать')}
          </Button.Text>
          <Button.Text
            data-testid={'disable-place-modal-button' /* Disable Reason Form | Кнопка отключить */}
            disabled={!isValidSettings || isNotSelectedAllDisableSettings}
            fullWidth
            loading={isPlaceDisabling}
            variant='primary'
            onClick={onDisableButtonClick}
          >
            {t('core-legacy.disable-reason-form.action-footer-button-text', 'Отключить')}
          </Button.Text>
        </Box>
      </Modal.Footer>
    </Modal>
  )
}
