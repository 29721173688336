import {t} from '@eda-restapp/i18n'
import type {Instance} from 'mobx-state-tree'
import {flow, getEnv, getParent, types} from 'mobx-state-tree'

import type {PlaceType} from '../Place'
import type {Options, PollingResponseType} from '../../../api/polling'
import {createPolling} from '../../../api/polling'
import {NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS} from '../../../constants/notifications'
import {ERROR_REQUEST_OPERATIONS} from '../../../constants/messages/errors'
import type {ApiEntities} from '@restapp/shared-api'
import type {IEnv} from '../../../types'
import {SUCCESS_REQUEST_CHANGES_MODERATION} from '../../../constants/messages/success'
import {isApiError, HttpStatusCode} from '@restapp/shared-api'
import {tryCatch} from '@restapp/shared/utils'
import type {Billing, KeyValueArray, PlaceCommissions} from '@restapp/shared-api/types/place'
import {errorLogger} from '@eda-restapp/logger'

export type ErrorResponse = {
  name: string
  error: string
}
export const CommonPhoneTypes = ['auto_call', 'place', 'lpr', 'official']
const PhoneType = types.enumeration('PhoneType', [...CommonPhoneTypes, 'accountancy'])
export const enum RestaurantType {
  NATIVE = 'native',
  MARKETPLACE = 'marketplace'
}

export const typeEnum = types.optional(
  types.enumeration<'native' | 'marketplace'>('RestType', ['native', 'marketplace']),
  RestaurantType.NATIVE
)
export type PlaceDeliveryType = Instance<typeof typeEnum>

export type PhoneType = Instance<typeof PhoneType>

const DEFAULT_POLLING_INTERVAL = 5e3
const MAX_POLLING_COUNT = 10

const Phone = types.model({
  number: types.string,
  type: PhoneType,
  description: types.maybeNull(types.string)
})

export type PlaceUpdateData = ApiEntities['placeEditingData']

const PlaceInfo = types
  .model('PlaceInfo', {
    info: types.model({
      name: types.string,
      type: typeEnum,
      address: types.model({
        country: types.string,
        city: types.string,
        street: types.string,
        building: types.maybeNull(types.string),
        full: types.maybeNull(types.string)
      }),
      phones: types.optional(types.array(Phone), []),
      email: types.string,
      lpr_email: types.maybeNull(types.string),
      payments: types.optional(types.array(types.string), []),
      address_comment: types.maybeNull(types.string),
      client_comment: types.maybeNull(types.string)
    }),
    has_plus: types.boolean,
    first_plus_activation_date: types.maybeNull(types.string),
    place_part: types.maybeNull(types.number),
    billing: types.optional(types.frozen<Billing>(), {} as Billing),
    billing_info: types.optional(types.frozen<KeyValueArray>(), []),
    commissions: types.optional(types.frozen<PlaceCommissions>(), {})
  })
  .volatile(() => {
    return {
      moderationReady: true,
      pollingInterval: DEFAULT_POLLING_INTERVAL,
      pollingInstance: {} as PollingResponseType,
      pollingInProgress: false,
      pollingErrorCount: 0
    }
  })
  .views((self) => {
    return {
      get isPickupEnabled() {
        const {eda, dc} = self.commissions
        const edaPickup = eda?.filter((commission) => commission.conditions[0].type === 'pickup') || []
        const dcPickup = dc?.filter((commission) => commission.conditions[0].type === 'dc_pickup') || []

        return Boolean(edaPickup.length) || Boolean(dcPickup.length)
      }
    }
  })
  .actions((self) => {
    const {api, snackbarNotifier} = getEnv<IEnv>(self)

    return {
      setModerationReadyStatus: (status: boolean) => {
        self.moderationReady = status
      },
      setPollingInProgressStatus: (status: boolean) => {
        self.pollingInProgress = status
      },
      setPollingErrorCount: (count: number) => {
        self.pollingErrorCount = count
      },
      editPlace: flow(function* (placeId: number, data: PlaceUpdateData, withExternalError?: boolean) {
        try {
          yield api.request.patch('/4.0/restapp-front/places/v1/update', data, {
            params: {
              place_id: placeId
            }
          })

          snackbarNotifier.enqueue({
            type: NOTIFICATION_TYPE_SUCCESS,
            message: SUCCESS_REQUEST_CHANGES_MODERATION()
          })
        } catch (error: unknown) {
          if (isApiError(error)) {
            if (
              error.status === HttpStatusCode.BAD_REQUEST &&
              error.responseData?.details?.errors &&
              withExternalError
            ) {
              throw error.responseData.details.errors
            }
            snackbarNotifier.enqueue({
              type: NOTIFICATION_TYPE_ERROR,
              message: ERROR_REQUEST_OPERATIONS.changes,
              error
            })
          }
        }
      })
    }
  })
  .actions((self) => {
    const {api, snackbarNotifier} = getEnv<IEnv>(self)
    return {
      startPolling: (options?: Options) => {
        if (self.pollingInProgress) {
          return
        }

        self.setPollingInProgressStatus(true)

        const placeModel = tryCatch(() => getParent(self) || {}) as PlaceType
        const {id: placeId} = placeModel

        if (!placeId) {
          return
        }

        self.pollingInstance = createPolling(
          () => {
            try {
              void api.request
                .get('/4.0/restapp-front/places/v1/update', {
                  params: {
                    place_id: placeId
                  }
                })
                .then((response) => {
                  const {status} = response as any as ApiEntities['placeEditingStatusData']

                  if (status === 'ready') {
                    self.pollingInstance.stop()
                    self.setPollingInProgressStatus(false)

                    if (!self.moderationReady) {
                      self.setModerationReadyStatus(true)

                      placeModel
                        .loadPlaceInfo()
                        .then(() => {
                          const updatedPlaceName = placeModel.info?.info.name || placeModel.name

                          snackbarNotifier.enqueue({
                            type: NOTIFICATION_TYPE_SUCCESS,
                            message: t(
                              'core-legacy.place-info.place-updated-snackbar-text',
                              'Обновили данные ресторана {placeName}',
                              {placeName: `«${updatedPlaceName}»`}
                            )
                          })

                          // TODO: Вернуть, когда бэк даст возможность определять какое поле изменилось, см. ответ поллинга(где status)
                          // if (self.info.type === 'marketplace' && field === 'address') {
                          //   snackbarNotifier.enqueue({
                          //     type: NOTIFICATION_TYPE_SUCCESS,
                          //     message: 'Проверьте зоны доставки — возможно, их надо изменить'
                          //   })
                          // }
                        })
                        .catch((e) => {
                          throw e
                        })
                    }
                  }

                  if (status === 'progress') {
                    self.setModerationReadyStatus(false)
                  }

                  self.setPollingErrorCount(0)
                  self.setPollingInProgressStatus(false)
                })
            } catch (error: unknown) {
              self.setPollingErrorCount(self.pollingErrorCount + 1)

              if (self.pollingErrorCount === MAX_POLLING_COUNT) {
                self.setPollingInProgressStatus(false)

                snackbarNotifier.enqueue({
                  type: NOTIFICATION_TYPE_ERROR,
                  message: ERROR_REQUEST_OPERATIONS.changes
                })
              }

              if (isApiError(error)) {
                errorLogger({error, level: 'error'})
              }
            }
          },
          self.pollingInterval,
          {fireImmediately: false, ...options}
        )

        self.pollingInstance.start()
      }
    }
  })

export type PlacePhoneType = Instance<typeof Phone>
export default PlaceInfo
