import {useI18n} from '@eda-restapp/i18n'
import {MenuIcon} from '@eda-restapp/ui'
import {resetNavigation} from '@eda-restapp/utils'
import {toString} from 'lodash-es'
import React from 'react'

import {useSidebar} from '@restapp/main-layout'
import {Button} from '@restapp/shared-ui'

import styles from './ErrorPage.module.css'

interface ErrorPageProps {
  error: unknown
  eventId?: string
  onReset?: () => void
}

function ErrorPage({error, onReset, eventId}: ErrorPageProps) {
  const {t} = useI18n()

  const {setVisibility} = useSidebar()

  return (
    <>
      <div className={styles.header}>
        <button
          className={styles.burger}
          onClick={() => setVisibility(true)}
          data-testid={'ui-sidebar-burger' /*UI | Кнопка показа/скрытия сайдбара*/}
        >
          <MenuIcon fontSize={40} />
        </button>
      </div>
      <div className={styles.root}>
        <div className={styles.title}>{t('shared-boundary.error-page.title', 'Ошибка')}</div>

        <div className={styles.text}>{t('shared-boundary.error-page.text', 'Извините, что-то пошло не так.')}</div>

        <div className={styles.error}>{toString(error)}</div>

        {!!eventId && <div className={styles.error}>{`EventId: ${eventId}`}</div>}

        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={onReset} variant='contained' color='primary'>
            {t('shared-boundary.error-page.button-retry', 'Попробовать еще раз')}
          </Button>

          <Button className={styles.button} onClick={() => resetNavigation()} variant='contained'>
            {t('shared-boundary.error-page.button-refresh', 'Перезагрузить приложение')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ErrorPage
