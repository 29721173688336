import {errorLogger} from '@eda-restapp/logger'
import {throttle} from 'lodash-es'
import type {GetDerivedStateFromError} from 'react'
import React, {Component} from 'react'

import ErrorPage from './ErrorPage'

type ErrorBoundaryProps = {
  slug?: string
  children?: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
  error?: unknown
  eventId?: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private reset = throttle(() => this.setState({hasError: false, error: undefined}), 1500, {
    trailing: false,
    leading: true
  })

  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      error: undefined
    }
  }

  static getDerivedStateFromError: GetDerivedStateFromError<{}, ErrorBoundaryState> = (error: unknown) => {
    return {hasError: true, error}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    errorLogger({
      sourceType: 'error-boundary-nested',
      level: 'error',
      error,
      additional: {
        slug: this.props.slug,
        componentStack: errorInfo.componentStack
      }
    })

    this.setState((state) => {
      return {...state}
    })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} onReset={() => this.reset()} eventId={this.state.eventId} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
