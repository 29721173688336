import React from 'react'

import ChatterboxContainer from '../../containers/ChatterboxContainer'
import type {IChat} from '../../types'

import ChatHeaderDesktop from './ChatHeaderDesktop'
import ChatHeaderMobile from './ChatHeaderMobile'

type ChatProps = {
  chat: IChat
  mobile?: boolean
}

function Chat({chat, mobile}: ChatProps) {
  const ChatHeader = mobile ? ChatHeaderMobile : ChatHeaderDesktop

  return (
    <>
      <ChatHeader chat={chat} />
      <ChatterboxContainer params={{place_id: Number.parseInt(chat.place_id)}} chatId={chat.chat_id} />
    </>
  )
}

export default Chat
