import {metrika} from '@eda-restapp/logger'

import {useApiQueryInvalidate} from '@restapp/core-api'
import {DesktopDevice, MobileDevice} from '@restapp/shared-ui/Device'

import ChatsHeaderDesktop from './ChatsHeaderDesktop'
import ChatsHeaderMobile from './ChatsHeaderMobile'

export type ChatsHeaderProps = {
  onCreateChat(restaurantId: number): void
}

function ChatsHeader({onCreateChat}: Omit<ChatsHeaderProps, 'filtersActions'>) {
  const chatListInvalidateQuery = useApiQueryInvalidate(['/4.0/restapp-front/support_chat/v1/chat/list', 'GET'])

  const refreshHandler = () => {
    metrika({target: 'chats_refresh_click'})
    return chatListInvalidateQuery.invalidateAsync()
  }

  return (
    <>
      <DesktopDevice>
        <ChatsHeaderDesktop onCreateChat={onCreateChat} onRefresh={refreshHandler} />
      </DesktopDevice>
      <MobileDevice>
        <ChatsHeaderMobile onCreateChat={onCreateChat} onRefresh={refreshHandler} />
      </MobileDevice>
    </>
  )
}

export default ChatsHeader
