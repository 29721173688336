import {MpdModal} from '@restapp/shared'
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'

const SHOW_MPD_MODAL_HASH = 'show_mpd_modal'

export const WatchMpdModal = () => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const location = useLocation()
  const parsedHash = useMemo(() => new URLSearchParams(location.hash.substring(1)), [location.hash])

  useEffect(() => {
    if (parsedHash.has(SHOW_MPD_MODAL_HASH)) {
      setDialogOpened(true)
    } else if (dialogOpened && !parsedHash.has(SHOW_MPD_MODAL_HASH)) {
      setDialogOpened(false)
    }
  }, [parsedHash, setDialogOpened, dialogOpened])

  const onCloseHandler = () => {
    parsedHash.delete(SHOW_MPD_MODAL_HASH)

    if (parsedHash.toString()) {
      window.location.hash = parsedHash.toString()
    } else {
      window.location.hash = ''
      history.replaceState('', '', location.pathname)
    }
    setDialogOpened(false)
  }

  return <MpdModal open={dialogOpened} onClose={onCloseHandler} />
}
