import {makeStyles} from 'tss-react/mui'
import {keyframes} from 'tss-react'

const useStyles = () =>
  ({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    absolute: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    },
    spinner: {
      display: 'inline-block',
      animation: `${keyframes`
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(720deg);
      }
      `} 1500ms infinite`,
      animationDirection: 'alternate',
      animationTimingFunction: 'cubic-bezier(0.42,0,0.58,1)'
    }
  }) as const

export default makeStyles({name: 'Spinner'})(useStyles)
