import type {ComponentType} from 'react'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import Markdown from '@restapp/core-legacy/common/components/Markdown'
import type {PortalComponentProps} from '@restapp/core-legacy/hocs/withPortals'
import {withPortals} from '@restapp/core-legacy/hocs/withPortals'
import {ModalBody, ModalHeader, ModalLayout} from '@restapp/shared-ui'

import type {NotificationType} from '../../../types'

interface NotificationModalProps {
  notification: NotificationType
}

const useNewsModalStyles = makeStyles()({
  image: {
    maxWidth: '100%'
  }
})

type InnerProps = NotificationModalProps & PortalComponentProps

function NotificationModal({notification, ...portalProps}: InnerProps) {
  const {classes} = useNewsModalStyles()

  return (
    <ModalLayout {...portalProps} closingByEscAvailable closingByClickOutside>
      <ModalHeader>{notification.preview.title}</ModalHeader>
      <ModalBody data-testid={'notification-modal-body'} /* Нотификации | Тело модального окна нотификации */>
        {!!notification.content.media_id && (
          <img
            className={classes.image}
            src={notification.content.media_id}
            alt=''
            loading='lazy'
            data-testid={
              'notification-modal-body-img'
            } /* Нотификации | Изображение в теле модального окна нотификации */
          />
        )}
        <Markdown markdown={notification.content.text} onLinkClick={() => void portalProps.close()} />
      </ModalBody>
    </ModalLayout>
  )
}

export default withPortals(NotificationModal) as ComponentType<NotificationModalProps>
