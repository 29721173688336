import React from 'react'
import {observer} from 'mobx-react'

import HideBodyScroll from '@restapp/shared/components/HideBodyScroll'
import {MobileDevice} from '../Device'
import styles from './Modal.module.css'

type ModalProps = {
  onClose?: () => void
  children: React.ReactNode
}

class Modal extends React.Component<ModalProps, {}> {
  private backdropRef = React.createRef<HTMLDivElement>()

  componentDidUpdate() {
    const modalRoot = this.backdropRef.current
    if (!modalRoot) {
      return
    }
    const activeElement = document.activeElement
    if (!activeElement || !this.isChild(activeElement, modalRoot)) {
      modalRoot.focus()
    }
  }

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      this.closeModal()
    }
  }

  isChild(childNode: Element, parentNode: Element): boolean {
    let node = childNode.parentElement
    while (node) {
      if (node === parentNode) {
        return true
      }
      node = node.parentElement
    }
    return false
  }

  closeModal = () => {
    const {props} = this
    props.onClose && props.onClose()
  }

  render() {
    const {props} = this
    return (
      <div tabIndex={0} className={styles.backdrop} onClick={this.closeModal} onKeyDown={this.onKeyDown}>
        {props.children}
        <MobileDevice>
          <HideBodyScroll />
        </MobileDevice>
      </div>
    )
  }
}

export default observer(Modal)
