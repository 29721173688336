import {useExp3} from '@eda-restapp/configs'
import {metrika, eventLogger} from '@eda-restapp/logger'
import {Modal, Typography} from '@eda-restapp/ui'
import React from 'react'

import {Story} from '@restapp/shared-modals'

import type {INewsItemPage} from './NewsPage'
import NewsPage from './NewsPage'

interface NewsModalProps {
  id: string
  open: boolean
  title: string
  isStory: boolean
  pages: INewsItemPage[]
  onClose: () => void
  onStoryEnd?: () => void
}

function NewsModal({id, open, title, pages, isStory, onStoryEnd, onClose}: NewsModalProps) {
  const {stories_autoplay} = useExp3('restapp_communications')

  function onLinkClick(href: string) {
    metrika({target: 'news-modal-close-on-link-click', params: {eventSlug: 'news-modal-close-on-link-click', id, href}})
    onClose()
  }

  function handleStoryEnd() {
    eventLogger({name: 'news-modal-close-story-end', additional: {id, title}})
    onStoryEnd ? onStoryEnd() : onClose()
  }

  function handleClose() {
    eventLogger({name: 'news-modal-close', additional: {id, title}})
    onClose()
  }

  const renderedPages = pages.map((page) => <NewsPage key={page.media_id} page={page} onLinkClick={onLinkClick} />)

  if (isStory) {
    return (
      <Story open={open} autoplay={stories_autoplay} title={title} onStoryEnd={handleStoryEnd} onClose={handleClose}>
        {renderedPages}
      </Story>
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Header title={title} />
      <Modal.Content>
        <Typography variant='body2' group='loose' flexDirection='column'>
          {renderedPages}
        </Typography>
      </Modal.Content>
    </Modal>
  )
}

export default NewsModal
