import {useI18n} from '@eda-restapp/i18n'
import {Button, Tooltip, Typography, UserAvatar} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {useState} from 'react'

import type {ExternalAccountSuggest} from '../../../../../types'
import {usePassportName} from '../../../../hooks'
import {PassportIcon} from '../../../PassportIcon'

import styles from './PassportRestrictionPageButtons.module.css'

type PassportRestrictionPageButtonsProps = {
  suggestedAccount?: ExternalAccountSuggest
  maxLinkedAccountLimitReached?: boolean
  hasLinkedAccounts?: boolean
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
}
export const PassportRestrictionPageButtons = ({
  suggestedAccount,
  onBindSuggestedAccount,
  onBindOtherAccount,
  maxLinkedAccountLimitReached,
  hasLinkedAccounts
}: PassportRestrictionPageButtonsProps) => {
  const {t} = useI18n()
  const passportName = usePassportName()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  if (hasLinkedAccounts) {
    if (suggestedAccount && !maxLinkedAccountLimitReached) {
      return (
        <>
          <div className={styles.button}>
            <Button.Text variant='secondary' onClick={onBindOtherAccount} fullWidth>
              {t(
                'core-auth.passport.passport-restriction-page-buttons.linked-accounts.other-account',
                'Выбрать другой аккаунт'
              )}
            </Button.Text>
          </div>

          <div className={styles.button}>
            <Button.MultiSpace
              variant='dark'
              startIcon={<PassportIcon />}
              endIcon={<UserAvatar src={suggestedAccount?.avatar} />}
              onClick={onBindSuggestedAccount}
              fullWidth
            >
              {t(
                'core-auth.passport.passport-restriction-page-buttons.linked-accounts.login-with-suggested-account',
                'Привязать {login}',
                {
                  login: suggestedAccount?.login
                }
              )}
            </Button.MultiSpace>
          </div>
        </>
      )
    }

    return (
      <>
        {anchorEl && maxLinkedAccountLimitReached && (
          <Tooltip.Info anchorEl={anchorEl} placement='top' className={styles.tooltipBro}>
            <Typography variant='caption1' thickness='medium'>
              {t(
                'core-auth.passport.passport-restriction-page-buttons.linked-accounts.too-much-accounts-linked',
                'Уже привязано максимальное количество аккаунтов'
              )}
            </Typography>
          </Tooltip.Info>
        )}

        <Button.MultiCenter
          ref={setAnchorEl}
          variant='dark'
          startIcon={<PassportIcon style={maxLinkedAccountLimitReached ? {opacity: 0.5} : {}} />}
          onClick={onBindOtherAccount}
          disabled={maxLinkedAccountLimitReached}
          fullWidth
        >
          {t(
            'core-auth.passport.passport-restriction-page-buttons.linked-accounts.link-login',
            'Привязать {passportName}',
            {
              passportName
            }
          )}
        </Button.MultiCenter>
      </>
    )
  }

  if (suggestedAccount) {
    return (
      <>
        <div className={cn(styles.button, {[styles.buttonLast]: !hasLinkedAccounts})}>
          <Button.Text variant='secondary' onClick={onBindOtherAccount} fullWidth>
            {t('core-auth.passport.passport-restriction-page-buttons.other-account', 'Привязать другой аккаунт')}
          </Button.Text>
        </div>

        <div className={styles.button}>
          <Button.MultiSpace
            variant='dark'
            fullWidth
            onClick={onBindSuggestedAccount}
            startIcon={<PassportIcon />}
            endIcon={<UserAvatar className={styles.round} src={suggestedAccount?.avatar} />}
          >
            {t(
              'core-auth.passport.passport-restriction-page-buttons.login-with-suggested-account',
              'Привязать {login}',
              {
                login: suggestedAccount?.login
              }
            )}
          </Button.MultiSpace>
        </div>
      </>
    )
  }

  return (
    <Button.MultiCenter variant='dark' startIcon={<PassportIcon />} fullWidth onClick={onBindOtherAccount}>
      <Typography variant='body2' group='tight' thickness='medium'>
        {t('core-auth.passport.passport-restriction-page-buttons.link-login', 'Привязать Яндекс ID')}
      </Typography>
    </Button.MultiCenter>
  )
}
