import {EventEmitter} from 'events'

import hoistNonReactStatics from 'hoist-non-react-statics'
import PropTypes from 'prop-types'
import type {ComponentType} from 'react'
import React, {Component} from 'react'
import {wrapDisplayName} from 'recompose'

import Context from '@restapp/core-legacy/constants/Context'
import type {WindowEventsProps} from '@restapp/core-legacy/hocs/window-events'

import {isAndroidDevice, isBrowser, acquire, isLocked, Lock, release} from '../utils'

// иногда браузер может присылать несколько popstate событий подряд, эта задержка нужно чтобы не обрабатывать
// больше 1го события в промежуток времени
const POPSTATE_DELAY = 150

// Задержка нужна, чтобы в течении этого времени browser-router не реагировал на изменения
const LOCK_DELAY = 150

// Задержка нужна, чтобы успел изминиться location.href при history.push
const REMOVE_CALLBACK_DELAY = 150

// Задержка нужна, чтобы не делать forward, если сразу за ним произойдет back
// Это происходит после анмаунта компонента, в котором используется <ControlHistory />
const GO_FORWARD_TIMEOUT = 600

const historyEmitter = new EventEmitter()

const contextTypes = {
  [Context.ControlHistory]: PropTypes.shape({
    addCallback: PropTypes.func,
    removeCallback: PropTypes.func
  })
}

type CallbackType = (reason: undefined) => void
type ControlHistoryProps = {
  onBack: CallbackType
}

// Какая-то лабуда для withPortals. Надо обязательно выпилить https://st.yandex-team.ru/EDARESTAPP-7651
export class ControlHistory extends Component<ControlHistoryProps> {
  static contextTypes = contextTypes

  onBack = this.props.onBack

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    // ABRO считает, что это двойное нажатие на кнопку Назад и оно должно закрывать браузер
    // Ждем задачи https://st.yandex-team.ru/ABRO-38063
    if (isAndroidDevice()) {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      this.context[Context.ControlHistory].addCallback(this.onBack)
    }
  }

  componentWillUnmount() {
    if (isAndroidDevice()) {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      this.context[Context.ControlHistory].removeCallback(this.onBack)
    }
  }

  render() {
    return null
  }
}

function withHistoryLock(callback: () => void) {
  acquire(Lock.BrowserHistory)
  callback()
  setTimeout(() => {
    release(Lock.BrowserHistory)
  }, LOCK_DELAY)
}

function pushFakeHistoryRecord() {
  window.history.pushState(null, '', location.href)
}

function listenHistoryEvents() {
  if (isBrowser()) {
    window.addEventListener('popstate', historyEmitter.emit.bind(historyEmitter, 'popstate'))
  }
}

let forwardTimeout: number | null = null

function goHistoryForward() {
  if (forwardTimeout !== null) {
    window.clearTimeout(forwardTimeout)
  }
  forwardTimeout = window.setTimeout(() => {
    withHistoryLock(() => window.history.forward())
    forwardTimeout = null
  }, GO_FORWARD_TIMEOUT)
}

function goHistoryBack() {
  if (forwardTimeout !== null) {
    window.clearTimeout(forwardTimeout)
    forwardTimeout = null
  } else {
    withHistoryLock(() => window.history.back())
  }
}

export function withControlHistoryProvider<P>(OriginalComponent: ComponentType<P>): ComponentType<P> {
  type InnerProps = P & WindowEventsProps

  class WrappedComponent extends Component<InnerProps> {
    static displayName = wrapDisplayName(OriginalComponent, 'withControlHistoryProvider')
    static childContextTypes = contextTypes

    private stack: CallbackType[] = []
    private currentHref: string | null = null
    private lastPopStateHandling = 0

    onPopState = (e: PopStateEvent) => {
      if (isLocked(Lock.BrowserHistory) || !this.stack.length) {
        return
      }
      withHistoryLock(() => {
        e.preventDefault()
        e.stopPropagation()
        // Эту запись ставим в очередь
        goHistoryForward()
        // Иногда могут приходить 2 события подряд с разницей в 150мс
        // В этом случае обрабатываем только первое, последнее игонорируем
        const lastPopStateHandling = Date.now()
        if (lastPopStateHandling - this.lastPopStateHandling > POPSTATE_DELAY) {
          this.stack[this.stack.length - 1](undefined)
        }
        this.lastPopStateHandling = lastPopStateHandling
      })
    }

    getChildContext() {
      return {
        [Context.ControlHistory]: {
          addCallback: this.addCallback,
          removeCallback: this.removeCallback
        }
      }
    }

    componentDidMount() {
      historyEmitter.addListener('popstate', this.onPopState)
    }

    componentWillUnmount() {
      historyEmitter.removeListener('popstate', this.onPopState)
    }

    addCallback = (callback: CallbackType) => {
      this.stack.push(callback)
      if (this.stack.length === 1) {
        withHistoryLock(pushFakeHistoryRecord)
        this.currentHref = location.href
      }
    }

    removeCallback = (callback: CallbackType) => {
      const indexOfCallback = this.stack.indexOf(callback)
      if (indexOfCallback !== -1) {
        this.stack.splice(indexOfCallback, 1)
        if (!this.stack.length) {
          setTimeout(() => {
            if (this.currentHref === location.href) {
              goHistoryBack()
            }
          }, REMOVE_CALLBACK_DELAY)
        }
      }
    }

    render() {
      return <OriginalComponent {...this.props} />
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hoistNonReactStatics(WrappedComponent as any, OriginalComponent)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return WrappedComponent as any as ComponentType<P>
}

// Начинаем слушать события history здесь, чтобы подписаться раньше react-router
listenHistoryEvents()
