import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Modal, Informer, Typography, useIsMobileStrict, UserAvatar} from '@eda-restapp/ui'
import React from 'react'

import {OutsideLink} from '@restapp/shared-ui'

import type {ExternalAccountSuggest} from '../../../../../../types'
import {usePassportHelpURL, usePassportName} from '../../../../../hooks'
import {PassportIcon} from '../../../../PassportIcon'
import idWithAlertIcon from '../../../PassportDrawer/PassportDrawerCards/images/idWithAlertIcon.png'
import {PassportFullScreenCloseButton} from '../PassportFullScreenCloseButton'

import styles from './NewFeaturesContent.module.css'

type NewFeaturesContentProps = {
  closeDelay?: number
  suggestedAccount?: ExternalAccountSuggest
  onClose: () => void
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
}

export const NewFeaturesContent = ({
  closeDelay,
  suggestedAccount,
  onBindOtherAccount,
  onBindSuggestedAccount,
  onClose
}: NewFeaturesContentProps) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()
  const passportName = usePassportName()
  const helpUrl = usePassportHelpURL()

  const renderButtonsWrapper = () => (
    <Box mt={isMobile ? undefined : 'l'} className={styles.buttonsWrapper}>
      {suggestedAccount ? (
        <Box
          gap={isMobile ? 's' : 'm'}
          flexDirection={isMobile ? 'column' : 'row'}
          className={styles.suggestedAccountButtons}
        >
          <Button.Text variant='secondary' onClick={onBindOtherAccount} fullWidth>
            {t('core_auth.passport.new-features-content.other-account', 'Выбрать другой аккаунт')}
          </Button.Text>

          <Button.MultiSpace
            variant='dark'
            startIcon={<PassportIcon />}
            endIcon={<UserAvatar className={styles.round} src={suggestedAccount?.avatar} />}
            onClick={onBindSuggestedAccount}
            fullWidth
          >
            {t('core_auth.passport.new-features-content.login-with-suggested-account', 'Привязать {login}', {
              login: suggestedAccount?.login
            })}
          </Button.MultiSpace>
        </Box>
      ) : (
        <Button.MultiCenter variant='dark' startIcon={<PassportIcon />} onClick={onBindOtherAccount} fullWidth>
          {t('core_auth.passport.new-features-content.link-login', 'Привязать {passportName}', {
            passportName
          })}
        </Button.MultiCenter>
      )}
    </Box>
  )

  return (
    <>
      <PassportFullScreenCloseButton delay={closeDelay} onClick={onClose} />

      <Modal.Content>
        <Box className={styles.root}>
          <img src={idWithAlertIcon} className={styles.icon} alt='id icon with alert' loading='lazy' />
          <Typography variant='title2' color='main' thickness='bold' group='normal' className={styles.title}>
            {t(
              'core_auth.passport.new-features-content.title',
              'Без привязки Яндекс ID доступ будет ограничен 5 апреля'
            )}
          </Typography>
          <Typography variant='body2' color='main' group='normal' className={styles.description}>
            {t(
              'core_auth.passport.new-features-content.description',
              'Если продолжать авторизоваться по электронной почте, доступ будет ограничен – вы сможете только принимать заказы. '
            )}
          </Typography>
          <Informer type='warn' className={styles.informer}>
            <Box className={styles.informerContent}>
              <Typography variant='body2' group='normal'>
                {t(
                  'core_auth.passport.new-features-content.informer-main-text',
                  'Привяжите Яндекс ID, чтобы пользоваться всеми функциями — например, работать с меню, управлять зонами доставки или ставить ресторан на паузу.'
                )}
              </Typography>
              <Box className={styles.linkBlock}>
                <OutsideLink href={helpUrl} target='_blank'>
                  <Typography variant='body2' group='tight'>
                    {t('core_auth.passport.new-features-content.informer-about-new-auth', 'О новой авторизации')}
                  </Typography>
                </OutsideLink>
              </Box>
            </Box>
          </Informer>
          {!isMobile && renderButtonsWrapper()}
        </Box>
      </Modal.Content>

      {isMobile && <Modal.Footer>{renderButtonsWrapper()}</Modal.Footer>}
    </>
  )
}
