import type {ComponentType, ReactElement} from 'react'
import React, {cloneElement, Component, Fragment} from 'react'
import {createPortal} from 'react-dom'
import {wrapDisplayName} from 'recompose'
import hoistNonReactStatics from 'hoist-non-react-statics'

import {isIos, md, Defer} from '@restapp/shared/utils'
import {ControlHistory} from '@restapp/shared/components/ControlHistory'

import type {WithIdProps} from './provide-id'
import {withId, IdTypes} from './provide-id'
import {rootId} from '../constants'
import {PORTALS_INITIAL_ZINDEX} from '../constants/z-indexes'

export type CloseCallback<T = void> = (reason?: T, force?: boolean) => void
export type WithPortalProps = {
  /**
   * @deprecated async/await внутри компонентов вне закона - используйте модели/хуки для модалок:
   * import {Modal, useModal} from '@restapp/shared-modals'
   */
  openPortal<T = void>(portalNode: ReactElement<any>, options?: PortalOptions): OpenPortalResponse<T>
}
export type PortalOptions = {
  /**
   * Z-Index элемента портала.
   */
  zIndex?: number
  /**
   * Стартовое значение автоинкремируемоего Z-Index'а элемента портала.
   *
   * Игнорируется, если передан `zIndex`.
   */
  initialZIndex?: number
  closeOnBack?: boolean
}
type PortalObject<T = void> = {
  id: number
  close: CloseCallback<T>
  closedDefer: Defer<T>
  portalNode: ReactElement<any>
  DOMContainer: HTMLDivElement
  options: PortalOptions
}
export type PortalComponentProps<T = void> = {
  visible: boolean
  close(reason?: T, force?: boolean): void
  onMount(): void
  onShow(): void
  onHide(): void
  /**
   * Возващает элемент портала в переданную функцию.
   *
   * Это может быть использовано для добавление атрибутов к порталу.
   *
   * @example
   *
   * class MyModal extends Component<PortalComponentProps> {
   *   componentDidMount () {
   *     this.props.portalRef((el) => {
   *       // Do your magic here.
   *       if (el) {
   *         el.className = 'myAwesomePortal'
   *       }
   *     })
   *   }
   * }
   */
  portalRef(cb: (el: HTMLDivElement | null) => void): void
}
type OpenPortalResponse<T = void> = {
  close: CloseCallback<T>
  opened: Promise<void>
  closed: Promise<T | undefined>
}

// Позволяем контролировать кнопку "Назад" только на Android
// Глобально считаем количество открытых порталов
let portalsCount = 0

export function withPortals<P>(OriginalComponent: ComponentType<WithPortalProps & P>) {
  class EnhancedComponent extends Component<WithIdProps & P> {
    static displayName = wrapDisplayName(OriginalComponent, 'withPortals')
    private portals: Array<PortalObject<any>> = []
    private initialRootInlinePointerEvents = ''
    private rootElement = document.getElementById(rootId)
    private hasFixedBackground = isIos && (md.tablet() || md.mobile())

    componentWillUnmount() {
      // Не синхронно удаляем оставшиеся ноды
      this.portals.map((portal) => {
        if (portal) {
          portal.closedDefer.resolve()
          setTimeout(() => {
            document.body.removeChild(portal.DOMContainer)
          }, 50)
        }
      })
    }

    open = <T,>(portalNode: ReactElement<any>, options: PortalOptions = {}): OpenPortalResponse<T> => {
      const {initialZIndex = PORTALS_INITIAL_ZINDEX} = options
      const id = this.props.provideId(IdTypes.Portals)
      const zIndex = options.zIndex === undefined ? initialZIndex + id : options.zIndex
      const openedDefer = new Defer<void>()
      const closedDefer = new Defer<T>()

      const createDOMContainer = () => {
        const DOMContainer = document.createElement('div')
        DOMContainer.setAttribute('data-portal', '')
        DOMContainer.style.position = 'fixed'
        DOMContainer.style.zIndex = String(zIndex)

        // EDARESTAPP-77
        this.addRootElementFixedStyles()

        document.body.appendChild(DOMContainer)
        return DOMContainer
      }

      const portalInDOM = () => this.portals.indexOf(portal) !== -1

      const onHide = (reason?: T) => {
        cleanUp()
        closedDefer.resolve(reason)
      }

      const cleanUp = () => {
        if (!portalInDOM) {
          return
        }

        this.portals.splice(this.portals.indexOf(portal), 1)
        portalsCount--

        this.forceUpdate(() => {
          document.body.removeChild(portal.DOMContainer)
        })

        if (!portalsCount) {
          this.props.resetId(IdTypes.Portals)
        }
      }

      const close = (reason?: T, force: boolean = process.env.NODE_ENV === 'test') => {
        // EDARESTAPP-77
        this.clearRootElementFixedStyles()

        if (!portalInDOM()) {
          return
        }

        if (force) {
          onHide(reason)
        } else {
          portal.portalNode = cloneElement(portal.portalNode, {
            visible: false,
            onHide: () => {
              onHide(reason)
            }
          })
        }

        this.forceUpdate()
      }

      const DOMContainer = createDOMContainer()
      const portalComponentProps: PortalComponentProps<T> = {
        visible: false,
        close,
        // Добавляем отдельное свойство onMount, т.к. CSS-правила могут применяться асинхронно
        onMount: () => {
          if (portalNode) {
            portal.portalNode = cloneElement(portal.portalNode, {visible: true})
            this.forceUpdate()
          }
        },
        onShow: () => {
          openedDefer.resolve()
        },
        onHide: () => {
          onHide(undefined)
        },
        portalRef: (cb) => {
          cb(DOMContainer)
        }
      }
      const portal: PortalObject<T> = {
        id,
        options,
        close,
        closedDefer,
        portalNode: cloneElement(portalNode, portalComponentProps),
        DOMContainer
      }

      this.portals.push(portal)
      portalsCount++

      this.forceUpdate()

      return {
        close,
        opened: openedDefer.promise,
        closed: closedDefer.promise
      }
    }

    addRootElementFixedStyles = () => {
      const isFirstPortal = portalsCount === 0

      if (this.hasFixedBackground && this.rootElement && isFirstPortal) {
        this.initialRootInlinePointerEvents = this.rootElement.style.pointerEvents
        this.rootElement.style.pointerEvents = 'none'
      }
    }

    clearRootElementFixedStyles = () => {
      const isLastPortal = portalsCount === 1

      if (!this.rootElement || !this.hasFixedBackground || !isLastPortal) {
        return
      }

      this.rootElement.style.pointerEvents = this.initialRootInlinePointerEvents

      if (this.rootElement.getAttribute('style') === '') {
        this.rootElement.removeAttribute('style')
      }
    }

    render() {
      return (
        <>
          <OriginalComponent {...this.props} openPortal={this.open} />
          {this.portals.map((portal) => (
            <Fragment key={String(portal.id)}>
              {portal.options.closeOnBack !== false && <ControlHistory onBack={portal.close} />}
              {createPortal(portal.portalNode, portal.DOMContainer)}
            </Fragment>
          ))}
        </>
      )
    }
  }

  hoistNonReactStatics<any, any>(EnhancedComponent, OriginalComponent)

  return withId(EnhancedComponent)
}
