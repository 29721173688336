import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'
import React, {useMemo} from 'react'

import {FOREVER_REACTIVATE_DATE} from '@restapp/core-legacy/constants/menu'
import {Option, Select} from '@restapp/shared-ui'

import {formatReactivatedAt} from '../helpers/formatReactivatedAt'
import type {ReactivatedAt} from '../types'

import useStyles from './NotAvailableSelect.style'

type NotAvailableSelectProps = {
  timestamps: Array<string | null>
  isOption?: boolean
  isMounted?: boolean
  disabled?: boolean
  handleChange?: (value: string) => void
  reactivatedAt?: ReactivatedAt
}

const NotAvailableSelect: React.FC<NotAvailableSelectProps> = ({
  timestamps,
  isMounted = true,
  handleChange,
  reactivatedAt,
  disabled
}) => {
  const {t} = useI18n()
  const {classes: c} = useStyles()

  const reactivatedDate = formatReactivatedAt(reactivatedAt?.value, timestamps[0])

  const selectOptions = useMemo(() => {
    const isEndlessOrAbsent = reactivatedDate === ' ' || !reactivatedDate
    const isReactivatedTimeInTimestamps = reactivatedDate && timestamps.includes(reactivatedDate)

    if (isEndlessOrAbsent || isReactivatedTimeInTimestamps) {
      return timestamps
    }

    return [reactivatedDate, ...timestamps]
  }, [timestamps, reactivatedDate])

  return (
    <div className={c.available}>
      {isMounted && (
        <div className={c.notAvailableContainer}>
          <div data-testid={'unavailable-text'} className={c.notAvailableText}>
            {t('shared-menu.not-available-select.not-available', 'Недоступно')}
            {reactivatedDate !== ' ' ? ` ${t('shared-menu.not-available-select.do', 'до')}` : ''}:
          </div>

          <Select
            data-testid={'menu-stop-list-popup-btn' /*Меню | Стрелочка открытия попапа с опциями в стоп-листе*/}
            disabled={disabled}
            value={reactivatedDate}
            onChangeValue={handleChange ?? reactivatedAt?.onChange}
            className={c.notAvailableSelect}
          >
            {selectOptions?.map((date) => (
              <Option
                data-testid={'menu-options-in-stop-list-popup' /*Меню | Опция в попапе со сроками в стоп-листе*/}
                key={`${date || 'endless_date'}`}
                value={date || ' '}
              >
                {date !== FOREVER_REACTIVATE_DATE
                  ? moment.parseZone(date).format('D MMMM HH:mm') // ignore time zone
                  : t('shared-menu.not-available-select.infinitely', 'Бессрочно')}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

export default NotAvailableSelect
