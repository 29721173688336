import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography, SurgeM, useIsMobileStrict} from '@eda-restapp/ui'
import {last} from 'lodash-es'
import moment from 'moment'
import React, {type Dispatch, type FC} from 'react'

import {usePlaces} from '@restapp/core-places'
import type {IBusyModeResponse} from '@restapp/shared-api/types/places'
import {InfoWidget, PlacesListItem, Tab, Tabs} from '@restapp/shared-ui'

// Here we cann't use module.css because this hook redefines MUI-components (Tab & Tabs) styles, but not CSS - so we should use <important>
import {useBusyModeModalLayoutStyles} from './BusyModeModalLayout.styles'

type BusyModeModalLayoutProps = {
  additionalMinutes: number
  busyModeData: IBusyModeResponse
  durationMinutes: number
  placeId: number
  setAdditionalMinutes: Dispatch<React.SetStateAction<number>>
  setDurationMinutes: Dispatch<React.SetStateAction<number>>
}

export const BusyModeModalLayout: FC<BusyModeModalLayoutProps> = ({
  additionalMinutes,
  busyModeData,
  durationMinutes,
  placeId,
  setAdditionalMinutes,
  setDurationMinutes
}) => {
  const {getPlaceById, placesIds} = usePlaces()
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()
  const {classes: c} = useBusyModeModalLayoutStyles()

  const place = getPlaceById(placeId)

  const handleChangeAdditionalMinutes = (_: React.ChangeEvent<{}>, value: number) => {
    setAdditionalMinutes(value)
  }

  const handleChangeDuration = (_: React.ChangeEvent<{}>, value: number) => {
    setDurationMinutes(value)
  }

  const getEnabledBusyModeInfo = () =>
    t('main-orders.busy-mode-modal.enabled-time-and-duration-info', 'Время готовки увеличено на {minutes} до {time}', {
      minutes: moment.duration(busyModeData.busy_mode.extra_time, 'minutes').humanize(),
      time: moment(busyModeData.busy_mode.valid_until).format('HH:mm')
    })

  const getLabelByPlaceType = () => {
    if (busyModeData.busy_mode.enabled) {
      if (place?.type === 'native') {
        return t('main-orders.busy-mode-modal.change-time-label', 'Изменить время готовки на заказы')
      } else {
        return t(
          'main-orders.busy-mode-modal.change-time-label-marketplace',
          'Изменить время готовки и доставки на заказы'
        )
      }
    } else {
      if (place?.type === 'native') {
        return t('main-orders.busy-mode-modal.increase-time-label', 'Увеличить время готовки на заказы')
      } else {
        return t(
          'main-orders.busy-mode-modal.increase-time-label-marketplace',
          'Увеличить время готовки и доставки на заказы'
        )
      }
    }
  }

  return (
    <Typography group='normal' variant='body2'>
      <Box flexDirection='column' gap={isMobile ? 'm' : 'l'}>
        {busyModeData.busy_mode.enabled ? (
          placesIds.length === 1 && (
            <Typography variant='body2' color='error'>
              <Box mr='s'>
                <SurgeM />
              </Box>
              {getEnabledBusyModeInfo()}
            </Typography>
          )
        ) : (
          <div data-testid={'busy-mode-info-text' /* Busy Mode Modal Layout | Обший информер про фичу. */}>
            {place?.type === 'native'
              ? t(
                  'main-orders.busy-mode-modal.description',
                  'Увеличьте время готовки, чтобы успеть всё приготовить. Пользователи увидят новое время, а курьер заберёт заказ позже.'
                )
              : t(
                  'main-orders.busy-mode-modal.description-marketplace',
                  'Увеличьте время готовки и доставки, чтобы привезти заказ вовремя. Пользователи увидят новое время.'
                )}
          </div>
        )}
        {placesIds.length > 1 && place && (
          <Box flexDirection='column'>
            <PlacesListItem place={place} />
            {busyModeData.busy_mode.enabled && (
              <Typography
                className={c.busyModeEnabledPlaceCaption}
                variant='caption1'
                ml='xl'
                color='error'
                data-testid='busy-modal-mode-state'
              >
                {getEnabledBusyModeInfo()}
              </Typography>
            )}
          </Box>
        )}
        <div>
          <b
            data-testid={
              'busy-mode-label' /* Busy Mode Modal Layout | Лейбл для конкретного типа доставки, разный для включения/изменения фичи. */
            }
          >
            {getLabelByPlaceType()}
          </b>
          <Typography
            data-testid={'busy-mode-current-time' /* Busy Mode Modal Layout | Текущее время готовки ресторана. */}
            group='normal'
            mt='xs'
            variant='caption1'
          >
            {busyModeData.avg_preparation_time.text}
          </Typography>
          <Tabs
            slug='busy_mode_additional_minutes'
            className={c.tabs}
            value={additionalMinutes}
            onChange={handleChangeAdditionalMinutes}
          >
            <Tab className={c.emptyTab} value={-1} />
            {busyModeData.available_parameters.extra_time_intervals.map((extraTimeInterval) => (
              <Tab
                key={extraTimeInterval.value}
                className={c.tab}
                label={extraTimeInterval.text}
                value={extraTimeInterval.value}
              />
            ))}
          </Tabs>
          {additionalMinutes === last(busyModeData.available_parameters.extra_time_intervals)?.value && (
            <InfoWidget
              data-testid={
                'busy-mode-warning-text' /* Busy Mode Modal Layout | Ворнинг об аффекте от увеличения времени готовки */
              }
              className={c.warning}
              type='warning'
            >
              {place?.type === 'native'
                ? t(
                    'main-orders.busy-mode-modal.warning-text',
                    'Время готовки влияет на место в выдаче и количество заказов ― их может стать меньше.'
                  )
                : t(
                    'main-orders.busy-mode-modal.warning-text-for-marketplace',
                    'Старайтесь готовить быстрее — время влияет на место в выдаче'
                  )}
            </InfoWidget>
          )}
        </div>
        <div>
          <b>{t('main-orders.busy-mode-modal.duration-label', 'Новое время будет действовать')}</b>

          <Tabs
            slug='busy_mode_duration_minutes'
            className={c.tabs}
            value={durationMinutes}
            onChange={handleChangeDuration}
          >
            <Tab className={c.emptyTab} value={-1} />
            {busyModeData.available_parameters.valid_for_intervals.map((validForInterval) => (
              <Tab
                key={validForInterval.value}
                className={c.tab}
                label={validForInterval.text}
                value={validForInterval.value}
              />
            ))}
          </Tabs>
        </div>
      </Box>
    </Typography>
  )
}
