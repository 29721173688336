import {useI18n} from '@eda-restapp/i18n'
import {Messenger} from '@eda-restapp/microfrontend'
import {observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {lockLandscapeTablet, unlockLandscapeTablet} from '@restapp/shared/utils'
import {isFirefox} from '@restapp/shared/utils/Device'
import {Suspense} from '@restapp/shared-boundary'
import {AuthGuard, PassportMigrationContainer, WatchUnauthorizedEvents} from '@restapp/core-auth'
import {withControlHistoryProvider} from '@restapp/shared/components/ControlHistory'
import {withIdProvider} from '../../../hocs/provide-id'
import {DialogContainer} from '@restapp/shared-modals'
import {OrderSounds} from '@restapp/orders'
import WatchNetworkStatus from './Network/WatchNetworkStatus'
import WatchPlaceToggle from './watchers/WatchPlaceToggle'
import WatchConfigs from './watchers/WatchConfigs'
import {WatchMpdModal} from './watchers/WatchMpdModal'
import {PlusContainer} from '@restapp/shared-places/containers/PlusContainer'
import {CourierFeedbackContainer} from '@restapp/shared/courier-feedback'
import ChatContainer from '@restapp/main-chats/containers/ChatContainer'
import {ChatsRouterBinding} from '@restapp/main-chats/components/ChatsRouterBinding'
import WatchCommunications from '@restapp/main-communications/watchers/WatchCommunications'
import {PartnerUidGuard} from '@restapp/core-auth/passport'
import {AssentSubscriptionUzbekistanContainer} from '@restapp/shared'

import AppTitle from '../../../AppTitle'
import DebugMenu from './DebugMenuContainer'
import AuthorizedAppRoot from './AuthorizedAppRoot'
import useAppStyles from './App.style'
import {selectIsOffline} from './Network/selectIsOffline'
import SurveyContainer from '@restapp/main-communications/containers/SurveyContainer'
import {BusyModeModalContainer} from '@restapp/shared-places/components/BusyModeModal'
import {PlaceStatusListContainer} from '@restapp/shared-places/components/PlaceStatusList'
import {Spinner} from '@restapp/shared-ui'
import {GlobalAlertContainer} from './GlobalAlert.container'
import {SetupUserTracking} from './SetupUserTracking'
import {UnauthorizedRouting} from './UnauthorizedRouting'
import {useExp3} from '@eda-restapp/configs'
import {useMemoryTracking} from './useMemoryTracking'
import {BrowserVersionAlertLazy} from '@restapp/shared/browser-version-alert'
import {Notifications} from '@restapp/bootstrap/notifications'
import {AutoSelectAllPlaces} from './AutoSelectAllPlaces'
import {errorLogger, eventLogger} from '@eda-restapp/logger'
import {SingleChatCounter, SingleChatDrawer} from '@restapp/main-chats/messenger'
import {StoplistAvailabilityNotificationContainer} from '@restapp/stoplist-notifications'

function App() {
  useMemoryTracking()
  const supportChatsConfig = useExp3('restapp_support_chats')
  const landscapeLockConfig = useExp3('restapp_landscape_lock')

  const {classes: c} = useAppStyles()
  const {t} = useI18n()
  const isNetOffline = useSelector(selectIsOffline)

  Messenger.useReceiveEvent(Messenger.MessageType.TRACKER_EVENT, (event) => {
    if (event.eventLevel === 'warn' || event.eventLevel === 'error' || event.eventLevel === 'fatal') {
      errorLogger({
        sourceType: event.eventType,
        level: event.eventLevel === 'fatal' ? 'critical' : event.eventLevel,
        error: event.eventError instanceof Error ? event.eventError : new Error(event.eventSlug),
        additional: {
          eventSlug: event.eventSlug,
          eventData: event.eventData,
          eventId: event.eventId,
          block: event.block,
          sevice: event.service
        }
      })
    } else {
      eventLogger({
        name: event.eventType || event.eventSlug || '',
        value: event.eventSlug,
        additional: {
          eventData: event.eventData,
          eventId: event.eventId,
          block: event.block,
          sevice: event.service
        }
      })
    }
  })

  const [, forceUpdate] = useState(0)
  useEffect(() => {
    window.onafterprint = () =>
      setTimeout(() => {
        forceUpdate((n) => n + 1)
      }, 50)

    if (isFirefox) {
      document.body.setAttribute('firefox', '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (landscapeLockConfig.enabled) {
      lockLandscapeTablet()
    }

    return () => {
      unlockLandscapeTablet()
    }
  }, [landscapeLockConfig.enabled])

  return (
    <div className={c.root}>
      <WatchConfigs />
      <WatchNetworkStatus />
      <WatchPlaceToggle />
      <WatchUnauthorizedEvents />
      <WatchMpdModal />
      <DialogContainer />
      <DebugMenu />

      <Suspense
        slug='global-suspense'
        fallback={
          <Spinner
            slug='global-suspense-spinner'
            absolute
            caption={t('shared-boundary.suspense.waiting-api-response', 'Загружаем данные')}
          />
        }
      >
        {isNetOffline && (
          <div className={c.offlineWarning} data-testid='no-internet-connection'>
            {t('core-legacy.app.net-soedineniya-c-internetom', 'Нет соединения c интернетом')}
          </div>
        )}
        <div className={c.contentClass}>
          <AuthGuard>
            {(isAuthorized) =>
              isAuthorized ? (
                <PassportMigrationContainer>
                  <PartnerUidGuard>
                    <AutoSelectAllPlaces />
                    <AppTitle withActiveOrders />

                    <Notifications />

                    <OrderSounds />
                    <WatchCommunications />
                    {supportChatsConfig?.enabled && <ChatContainer />}
                    <SingleChatCounter />
                    <ChatsRouterBinding />
                    <SingleChatDrawer />
                    <CourierFeedbackContainer />
                    <PlusContainer />
                    <SetupUserTracking />
                    <GlobalAlertContainer />

                    <AuthorizedAppRoot />

                    <SurveyContainer />
                    <BusyModeModalContainer />
                    <PlaceStatusListContainer />
                    <AssentSubscriptionUzbekistanContainer />
                    <StoplistAvailabilityNotificationContainer />
                  </PartnerUidGuard>
                </PassportMigrationContainer>
              ) : (
                <>
                  <AppTitle />
                  <UnauthorizedRouting />
                </>
              )
            }
          </AuthGuard>
        </div>

        <BrowserVersionAlertLazy />
      </Suspense>
    </div>
  )
}

export default withIdProvider(withControlHistoryProvider(observer(App)))
