import {useI18n} from '@eda-restapp/i18n'

import type {TariffType} from '@restapp/shared-api'

export const useSubscriptionConfirmSaveModalTexts = (
  selectedTariffType: TariffType,
  initialTariffType?: TariffType,
  trialTariffInfoType?: TariffType
) => {
  const {t} = useI18n()

  const getTitle = () => {
    if (selectedTariffType === 'business') {
      return t('shared-places.subscription-confirm-save-modal.title-business', 'Подписка Стандарт')
    }

    if (selectedTariffType === 'business_plus') {
      return t('shared-places.subscription-confirm-save-modal.title-business-plus', 'Подписка Бизнес')
    }

    return t('shared-places.subscription-confirm-save-modal.title-default', 'Подписка')
  }

  const getDescriptionTexts = () => {
    if (initialTariffType === 'business_plus' && initialTariffType === trialTariffInfoType) {
      return [
        t(
          'shared-places.subscription-confirm-save-modal.description-business-plus-to-standart-first',
          'Вам будут доступны все инструменты подписки, кроме личного менеджера.'
        ),
        t(
          'shared-places.subscription-confirm-save-modal.description-business-plus-trial-to-standart-second',
          'Подписка изменится на Стандарт, когда закончится бесплатный период тарифа Бизнес.'
        )
      ]
    }

    if (initialTariffType === 'business_plus') {
      return [
        t(
          'shared-places.subscription-confirm-save-modal.description-business-plus-to-standart-first',
          'Вам будут доступны все инструменты подписки, кроме личного менеджера.'
        ),
        t(
          'shared-places.subscription-confirm-save-modal.description-business-plus-to-standart-second',
          'Новый тариф начнёт действовать уже завтра.'
        )
      ]
    }

    const defaultTexts = []
    if (selectedTariffType === 'business_plus') {
      defaultTexts.push(
        t(
          'shared-places.subscription-confirm-save-modal.description-business-plus-v2',
          'Подберём личного менеджера, который расскажет, как настроить процессы в личном кабинете, даст рекомендации по привлечению заказов, а ещё поможет разобраться в показателях.'
        )
      )
    }

    defaultTexts.push(
      t(
        'shared-places.subscription-confirm-save-modal.description-default-2',
        'Пользоваться преимуществами подписки можно уже завтра. '
      )
    )

    return defaultTexts
  }

  const description = getDescriptionTexts()
  const title = getTitle()

  return {
    title,
    description
  }
}
