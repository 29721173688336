import {useApiMutation} from '@restapp/core-api'
import {memo, useEffect} from 'react'
import {useQuery} from 'react-query'
import {listenerMiddleware} from '../../coreDeps'
import {
  selectOauthToken,
  selectPartnerId,
  selectToken,
  setPartnerId,
  unauthorized,
  userLogout
} from '@restapp/core-auth'
import {isAnyOf} from '@reduxjs/toolkit'
import {errorLogger, eventLogger} from '@eda-restapp/logger'
import {useExp3} from '@eda-restapp/configs'
import {createHeaders} from '@restapp/shared/utils/createAuthHeaders'

const XIVA_SERVICE = 'eats-partner'

type WebPushSubscriptionProps = {
  vapidKeyUrl: string
  onSubscriptionError: () => void
  onSubscriptionSuccess: () => void
}

export const WebPushSubscription: React.FC<WebPushSubscriptionProps> = memo(
  ({vapidKeyUrl, onSubscriptionError, onSubscriptionSuccess}) => {
    const webPushConfig = useExp3('restapp_webpush')

    //#region Subscribe
    const subscribeMutation = useApiMutation(
      {
        method: 'POST',
        url: webPushConfig.newApiEnabled
          ? '/4.0/restapp-front/communications/v1/notify/subscribe'
          : '/4.0/restapp-front/notify/v1/subscribe'
      },
      {
        onSuccess: () => {
          eventLogger({value: 'webpush:subscribed', name: 'webpush'})
        },
        onError: (error) => {
          errorLogger({
            level: 'error',
            sourceType: 'webpush',
            error: error,
            additional: {eventSlug: 'webpush:subscribe-error'}
          })
        }
      }
    )

    const subscribeToWebPush = (subscription: PushSubscription) => {
      subscribeMutation.mutate({
        body: {
          channel: {name: 'webpush'},
          client: {app_name: 'restapp_web', device_type: 'web', app_install_id: VERSION},
          subscription,
          ...(!webPushConfig.newApiEnabled && {service: XIVA_SERVICE})
        }
      })
    }

    const vapidKeyQuery = useQuery({
      queryKey: 'web-push-vapid-key',
      queryFn: () => fetch(vapidKeyUrl).then((response) => response.text()),
      staleTime: Infinity,
      onError: (error) => {
        errorLogger({
          level: 'error',
          sourceType: 'webpush',
          error: error instanceof Error ? error : new Error('Error fetching vapid key', {cause: error}),
          additional: {eventSlug: 'webpush:get-vapid-key-error'}
        })
      }
    })

    const vapidKey = vapidKeyQuery.data

    useQuery({
      enabled: !!vapidKey,
      queryKey: 'get-webpush-subscription',
      staleTime: Infinity,
      queryFn: async () => {
        if (!vapidKey) {
          throw new Error('No VAPID key')
        }
        // eslint-disable-next-line compat/compat
        const sw = await navigator.serviceWorker.ready

        const existingSubscription = await sw.pushManager.getSubscription()

        if (existingSubscription) {
          await existingSubscription.unsubscribe()
        }

        return await sw.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidKey
        })
      },
      onError: (error) => {
        onSubscriptionError()

        errorLogger({
          level: 'error',
          sourceType: 'webpush',
          error:
            error instanceof Error
              ? error
              : new Error('Error getting webpush subscription object from browser', {cause: error}),
          additional: {eventSlug: 'webpush:get-subscription-object-error'}
        })
      },
      onSuccess: (subscription) => {
        subscribeToWebPush(subscription)
        onSubscriptionSuccess()
      }
    })
    //#endregion Subscribe

    //#region Unsubscribe
    const unsubscribeMutation = useApiMutation(
      {
        method: 'POST',
        url: webPushConfig.newApiEnabled
          ? '/4.0/restapp-front/communications/v1/notify/unsubscribe'
          : '/4.0/restapp-front/notify/v1/unsubscribe'
      },
      {
        onError: () => {
          // do not show snackbar
        }
      }
    )

    useEffect(() => {
      listenerMiddleware.startListening({
        // https://st.yandex-team.ru/RESTAPP-255
        matcher: isAnyOf(userLogout, unauthorized, setPartnerId),
        effect: (action, listenerApi) => {
          const token = selectToken(listenerApi.getOriginalState())
          const oauth = selectOauthToken(listenerApi.getOriginalState())
          const partnerId = selectPartnerId(listenerApi.getOriginalState())

          eventLogger({
            value: 'webpush:unsubscribe',
            name: 'native',
            additional: {partnerId}
          })

          unsubscribeMutation.mutate({
            body: {
              channel: {name: 'webpush'},
              ...(webPushConfig.newApiEnabled
                ? {
                    app_install_id: VERSION,
                    client_id: partnerId || undefined
                  }
                : {
                    service: XIVA_SERVICE,
                    client: {app_install_id: VERSION, partner_uid: partnerId || undefined}
                  })
            },
            ...createHeaders(token, oauth, partnerId)
          })

          listenerApi.unsubscribe()
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //#endregion Unsubscribe

    return null
  }
)
