import {observer} from 'mobx-react'
import React from 'react'

import {useEnv} from '@restapp/core-di'

import {Dialog} from '../components'

export default observer(function DialogContainer() {
  const {dialogs} = useEnv()

  return <Dialog {...dialogs.dialogProps} {...dialogs.params} />
})
