import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Box, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'
import React, {useMemo} from 'react'

import {usePlaces} from '@restapp/core-places'
import type {Service, PlacePlus, PlusStatusByService} from '@restapp/shared-api'
import {YaPlusStatusEnum, RATING_REASONS} from '@restapp/shared-api'
import {HelpLink} from '@restapp/shared-support'
import {InfoWidget, Spinner} from '@restapp/shared-ui'

import {useYaPlusStatus} from '../../../../hooks/useYaPlusStatus'
import {getPlusStatusTextSlug} from '../../../../utils'
import plusLogo from '../assets/plusLogo.png'
import EnablePlusConditions from '../EnablePlusConditions'

import styles from './Header.module.css'

type HeaderProps = {
  drawerPlaces?: PlacePlus[]
  notActivePlaces?: PlacePlus[]
  isDesktop: boolean
  activePlacesLength: number
  isUnavailable?: boolean
  initialServices?: Service[]
  isLoading: boolean
  className?: string
}

export const Header: FC<HeaderProps> = ({
  isDesktop,
  isUnavailable,
  isLoading,
  drawerPlaces,
  activePlacesLength,
  notActivePlaces,
  initialServices,
  className
}) => {
  const {t} = useI18n()

  const {plusCommission, enabled} = useExp3('restapp_plus')

  const isOnePlace = drawerPlaces && drawerPlaces.length === 1

  const isNotEnabledPlace =
    isOnePlace && (!drawerPlaces[0]?.services?.enabled || drawerPlaces[0]?.services?.enabled.length < 1)

  const {selectedPlaceId} = usePlaces()

  const {data: plus} = useYaPlusStatus([selectedPlaceId], {
    enabled: enabled && !!selectedPlaceId,
    select: (data) => data.places.find((place) => place.place_id === selectedPlaceId)
  })

  const plusInfoByService = plus?.place_info_by_service

  const unavailableReason = plusInfoByService?.find((plusInfo) => plusInfo.statuses?.[0]?.reasons?.[0])?.statuses?.[0]
    ?.reasons?.[0]

  const isAllServicesDisabled = drawerPlaces?.every((place) =>
    place.plus?.every((plusInfo) => plusInfo.statuses[0].status === YaPlusStatusEnum.CAN_BE_ACTIVATED)
  )

  const allPlacesStatuses = useMemo(() => {
    const statusPositions = Object.values(YaPlusStatusEnum)
    let placesStatuses = [] as PlusStatusByService[]

    drawerPlaces?.forEach((place) => {
      place.plus?.forEach((placeServiceInfo) => {
        const service = placeServiceInfo.service

        const accServiceStatuses = placesStatuses.find((plusInfo) => plusInfo.service === service)

        const placeStatus = placeServiceInfo.statuses[placeServiceInfo.statuses.length - 1].status
        const placeStatusIndex = statusPositions.findIndex((i) => i === placeStatus)

        const accServiceStatus = accServiceStatuses?.statuses[accServiceStatuses.statuses.length - 1].status
        const accServiceStatusIndex = statusPositions.findIndex((i) => i === accServiceStatus)

        if (!accServiceStatuses) {
          placesStatuses.push(placeServiceInfo)
        } else if (accServiceStatusIndex < placeStatusIndex) {
          placesStatuses = placesStatuses.filter((item) => item.service !== service)

          placesStatuses.push(placeServiceInfo)
        }
      })
    })

    return placesStatuses
  }, [drawerPlaces])

  const getStatusText = () => {
    const textSlug = getPlusStatusTextSlug(allPlacesStatuses)

    const inYourPlaceText = t('shared-places.plus-drawer.in-your-places-text', '{count} в ваших ресторанах', {
      none: 'в ваших ресторанах',
      one: 'в вашем ресторане',
      some: 'в ваших ресторанах',
      many: 'в ваших ресторанах',
      count: activePlacesLength > 1 ? 2 : 1 // текст только для одного и больше одного ресторанов
    })

    const allPlacesActive = notActivePlaces && notActivePlaces.length < 1

    const enablePlusInOtherPlacesText = t(
      'shared-places.plus-drawer.has-active-places-additonal-text',
      '\n\nЧтобы привлечь ещё больше пользователей, подключите Плюс во всех ресторанах.'
    )

    const activePlacesAdditionalText = !allPlacesActive ? enablePlusInOtherPlacesText : ''

    switch (textSlug) {
      case 'EDA_ACTIVE_DC_ACTIVATING':
      case 'BOTH_SERVICES_ACTIVE':
        return (
          t(
            'shared-places.plus-drawer.some-services-active-text',
            'Вы в Плюсе на сервисах {eatsServiceBrandLabel} и {dcServiceBrandLabel}. Пользователи могут копить и тратить баллы {text}.',
            {
              text: inYourPlaceText
            }
          ) + activePlacesAdditionalText
        )
      case 'EDA_ACTIVE_DC_DISABLED':
        return (
          t(
            'shared-places.plus-drawer.eda-active-dc-disabled-text-v1',
            'Вы в Плюсе на сервисе {eatsServiceBrandLabel}. Пользователи могут копить и тратить баллы {text}.',
            {
              text: inYourPlaceText
            }
          ) +
          (isOnePlace
            ? t(
                'shared-places.plus-drawer.to-attract-even-more-users-dc-text',
                '\n\nЧтобы привлечь ещё больше пользователей, подключите Плюс на сервисе {dcServiceBrandLabel}.'
              )
            : enablePlusInOtherPlacesText)
        )
      case 'EDA_DISABLED_DC_ACTIVATING':
      case 'EDA_DISABLED_DC_ACTIVE':
        return (
          t(
            'shared-places.plus-drawer.eda-disabled-dc-active-text',
            'Вы в Плюсе на сервисе {dcServiceBrandLabel}. Пользователи могут копить и тратить баллы {text}.',
            {
              text: inYourPlaceText
            }
          ) +
          (isOnePlace
            ? t(
                'shared-places.plus-drawer.to-attract-even-more-users-eda-text',
                '\n\nЧтобы привлечь ещё больше пользователей, подключите Плюс на сервисе {eatsServiceBrandLabel}.'
              )
            : enablePlusInOtherPlacesText)
        )

      case 'EDA_ACTIVE':
        return (
          t(
            'shared-places.plus-drawer.plus-active-in-eda-text',
            'Вы в Плюсе на сервисе {eatsServiceBrandLabel}. Пользователи могут копить и тратить баллы {text}.',
            {
              text: inYourPlaceText
            }
          ) + activePlacesAdditionalText
        )
      case 'DC_ACTIVATING':
      case 'DC_ACTIVE':
        return (
          t(
            'shared-places.plus-drawer.dc-active-text',
            'Вы в Плюсе на сервисе {dcServiceBrandLabel}. Пользователи могут копить и тратить баллы {text}.',
            {
              text: inYourPlaceText
            }
          ) + activePlacesAdditionalText
        )
      case 'DEFAULT':
      default:
        return t(
          'shared-places.plus-drawer.disabled-every-service-text-v1',
          'Рестораны в Плюсе привлекают более лояльных пользователей, которые заказывают чаще. А ещё их средний чек выше.\n\nС Яндекс Плюсом пользователи смогут копить и тратить баллы {text}.',
          {
            text: inYourPlaceText
          }
        )
    }
  }

  const unavailablePlacesErrorText = t(
    'main-places.plus-section.unavailable-reason',
    'Пока вы не можете подключить Плюс ни в одном ресторане, так как не выполнены условия подключения'
  )

  const hasActivePlace = activePlacesLength > 0

  return (
    <Box flexDirection='column' px='l' py='m' className={className}>
      <img className={cn(styles.icon, {[styles.iconDesktop]: isDesktop})} src={plusLogo} />

      {!isDesktop && <div className={styles.divider} />}

      {isLoading ? (
        <Box mt='l' justifyContent='center'>
          <Spinner />
        </Box>
      ) : (
        <div>
          {isUnavailable && (
            <InfoWidget
              data-testid={'plus-drawer-error-block' /* Шторка Плюса | Красный блок с ошибкой */}
              className={styles.plusUnavailableInfo}
              type={'error'}
            >
              {drawerPlaces && (
                <>
                  {drawerPlaces.length > 1 && unavailablePlacesErrorText}

                  {drawerPlaces.length < 2 && (
                    <>
                      {isNotEnabledPlace
                        ? unavailablePlacesErrorText
                        : unavailableReason?.message && (
                            <>
                              {unavailableReason.message}
                              {RATING_REASONS.includes(unavailableReason.code) && (
                                <HelpLink linkKey='legacy.rating'>
                                  {t('main-places.plus-section.kak-podnyat-reiting', 'Как поднять рейтинг')}
                                </HelpLink>
                              )}
                            </>
                          )}
                    </>
                  )}
                </>
              )}
            </InfoWidget>
          )}

          <Typography
            data-testid={'plus-drawer-status-text' /* Шторка Плюса | Статус подключения Плюса */}
            variant='body2'
            group='loose'
            thickness='regular'
            mt={!isDesktop && !isUnavailable ? 'xl' : 'm'}
          >
            {getStatusText()}
          </Typography>

          {(isAllServicesDisabled || isUnavailable || !hasActivePlace) && (
            <Box flexDirection='column' mt='m'>
              <EnablePlusConditions services={initialServices ?? ['eda']} commission={plusCommission} />
            </Box>
          )}
        </div>
      )}
    </Box>
  )
}
