import {useEffect} from 'react'
import type {WindowPerformance} from '../../../types/global'
import {useExp3} from '@eda-restapp/configs'
import {eventLogger} from '@eda-restapp/logger'

const bytesToMegabytes = (bytes: number) => Math.round(bytes / 1000 ** 2)

export function useMemoryTracking() {
  const logsConfig = useExp3('restapp_logs')

  useEffect(() => {
    // Deprecated https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
    const memory = (window?.performance as WindowPerformance)?.memory

    if (!memory) {
      return
    }

    const reportMemoryUsage = async () => {
      eventLogger({
        name: 'memory_usage',
        additional: {
          heap_size_limit: bytesToMegabytes(memory.jsHeapSizeLimit),
          total_js_heap_size: bytesToMegabytes(memory.totalJSHeapSize),
          used_js_heap_size: bytesToMegabytes(memory.usedJSHeapSize)
        }
      })
    }

    reportMemoryUsage()
    const interval = setInterval(reportMemoryUsage, Math.max(logsConfig.memoryReportInterval, 3000))

    return () => {
      clearInterval(interval)
    }
  }, [logsConfig.memoryReportInterval])
}
