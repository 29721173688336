import {types} from 'mobx-state-tree'

const DisableDetails = types
  .model()
  .named('DisableDetails')
  .props({
    disable_at: types.maybeNull(types.string),
    available_at: types.maybeNull(types.string),
    status: types.maybeNull(types.number),
    reason: types.maybeNull(types.number)
  })

export default DisableDetails
