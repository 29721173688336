import {errorLogger, eventLogger} from '@eda-restapp/logger'
import type {RefObject} from 'react'
import {useEffect} from 'react'
import {useEvent} from 'react-use-event-hook'

import type {ChatterboxIframeQueryParams} from '../containers/ChatterboxContainer'
import type {ChatterboxPostMessageData} from '../types'

import {useChatterboxTimeoutLogger} from './useChatterboxTimeoutLogger'

type ChatterboxLogger = {
  chatId?: string
  frameSrc?: string
  params: ChatterboxIframeQueryParams
  frameRef: RefObject<HTMLIFrameElement>
}

export type SendEvent = {value?: string | number | undefined; additional?: Record<string, unknown>}
export type SendError = {
  level?: 'error' | 'critical' | 'warn'
  error: string | Error | ErrorEvent
  additional?: Record<string, unknown>
}

export function useChatterboxLogger({chatId, frameSrc, params, frameRef}: ChatterboxLogger) {
  const sendEvent = useEvent(({value, additional}: SendEvent) =>
    eventLogger({name: 'ChatterboxContainer', value, additional: {chatId, params, isNewChat: !chatId, ...additional}})
  )

  const sendError = useEvent(({level, error, additional}: SendError) =>
    errorLogger({
      sourceType: 'ChatterboxContainer',
      level,
      error,
      additional: {chatId, params, isNewChat: !chatId, ...additional}
    })
  )

  const timeoutLogger = useChatterboxTimeoutLogger({sendEvent, sendError})

  useEffect(() => timeoutLogger.start(), [timeoutLogger])

  useEffect(() => {
    const loggerFunc = (event: MessageEvent<ChatterboxPostMessageData>) => {
      if (frameRef?.current?.contentWindow !== event.source) {
        return
      }

      if (typeof event.data === 'object') {
        switch (event.data.type) {
          case 'CHAT_CREATED':
            sendEvent({
              value: 'support-chats-iframe-chat-created'
            })

            break

          case 'CHAT_INITED_SUCCESS':
            timeoutLogger.stop()

            sendEvent({
              value: 'support-chats-iframe-chat-inited-success',
              additional: {frameSrc}
            })

            break

          case 'CHAT_INITED_ERROR':
            sendError({
              level: 'error',
              error: new Error('support-chats-iframe-chat-inited-error'),
              additional: {eventSlug: 'support-chats-iframe-chat-inited-error'}
            })

            break

          case 'CHATS_STATUS':
            sendEvent({
              value: 'support-chats-iframe-chats-status'
            })

            break
        }
      } else if (
        typeof event.data === 'string' &&
        event.data.startsWith('{') &&
        event.data.includes('"type":"initToParent"')
      ) {
        timeoutLogger.stop()
        sendEvent({
          value: 'support-chats-iframe-categories-shown-on-new-chat-creation'
        })
      }
    }

    window.addEventListener('message', loggerFunc)

    return () => window.removeEventListener('message', loggerFunc)
  }, [frameRef, frameSrc, timeoutLogger, sendEvent, sendError])
}
