export const enum ALL_PLACES_STATUS {
  AVAILABLE,
  PART_DISABLED,
  DISABLED
}

export enum TIMERS {
  FEEDBACK_POLLING = 600e3 * 6, // 1h
  NET_AVAILABILITY = 5e3, // 5 sec
  ORDERS_POLLING = 15e3, // 15 sec
  STATE_POLLING = 30e3, // 30 sec
  AUDIO_NOTIFY_DURATION = 20e3, // 30 sec
  REFRESH_TIMER = 60e3 // 1 min
}

export const EMAIL_REGEXP = /^\S+@\S+\.\S+/

export const SUPPORT_MAIL = 'rest@eda.yandex.ru'

export const rootId = 'root'
