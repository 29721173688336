import {observer} from 'mobx-react'
import React from 'react'

import Markdown from '@restapp/core-legacy/common/components/Markdown'

import {useNewsPageStyles} from './NewsPage.style'

export type INewsItemPage = {
  text: string
  media_id?: string
}

interface NewsPageProps {
  page: INewsItemPage
  onLinkClick: (href: string) => void
}

function NewsPage({page, onLinkClick}: NewsPageProps) {
  const {classes} = useNewsPageStyles()

  return (
    <div style={{whiteSpace: 'normal'}}>
      {!!page.media_id && <img className={classes.image} src={page.media_id} alt='' />}
      <Markdown markdown={page.text} onLinkClick={onLinkClick} />
    </div>
  )
}

export default observer(NewsPage)
