import type {Theme} from '@mui/material'
import {useMediaQuery} from '@mui/material'

/**
 *
 * @deprecated используй useIsDesktopStrict из eda-restapp/ui
 */
export default function useIsLargeDesktop(): boolean {
  return useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {noSsr: true})
}
