import type {Instance} from 'mobx-state-tree'
import {flow, getEnv, getRoot, types} from 'mobx-state-tree'
import {ENABLE_NOT_ALLOWED_REASONS, RESTAURANT_DISABLE_REASONS} from '@restapp/shared'
import {clientUrl} from '../../api'
import {injectFromEnv, injectFromRoot} from '../mixins'
import type {AppStateType} from '../../AppState'
import DisableDetails from './DisableDetails'
import Currency from './Currency'
import PlaceInfo, {typeEnum} from './PlaceInfo'
import type {VendorApi} from '@restapp/shared-api'
import {isApiError} from '@restapp/shared-api'
import {getConfigsStore} from '@eda-restapp/configs'
import {errorLogger} from '@eda-restapp/logger'

const configsStore = getConfigsStore()

const Service = types.enumeration('Service', ['eda', 'dc'])

const Place = types
  .compose(injectFromRoot('reduxState'), injectFromEnv('api', 'snackbarNotifier'))
  .named('Restaurant')
  .props({
    id: types.identifierNumber,
    slug: types.string,
    name: types.string,
    brand: types.optional(
      types.frozen<{
        slug: string
        business_type: string
      }>(),
      {
        slug: '',
        business_type: ''
      }
    ),
    address: '',
    info: types.maybeNull(PlaceInfo),
    disable_details: types.maybeNull(DisableDetails),
    type: typeEnum,
    services: types.maybe(
      types.model('Services', {
        enabled: types.optional(types.array(Service), []),
        available: types.optional(
          types.array(
            types.model({
              service: Service,
              name: types.string
            })
          ),
          []
        )
      })
    ),
    currency: Currency,
    country_code: types.optional(types.string, ''),
    is_available: types.boolean,
    region_slug: types.optional(types.string, ''),
    is_switching_on_requested: false,
    show_shipping_time: false,
    can_be_enabled: types.maybe(types.boolean),
    // EDAFRONT-3356
    integration_type: types.optional(types.enumeration(['native', 'custom']), 'native' as const),
    subscription: types.frozen<{
      tariff_type: 'free' | 'business' | 'business_plus'
      is_trial: boolean
      need_alerting_about_finishing_trial: boolean
      valid_until_iso: string
    }>()
  })
  .volatile(() => {
    return {
      loading: false
    }
  })
  .views((self) => {
    return {
      get selfName(): string {
        // В self.info?.info.name лежит имя после обновления на странице ресторана. Но! Его может не быть при начальной загрузке приложения. Для этого используем фоллбэк self.name
        return self.info?.info.name || self.name
      },
      get selfAddress(): string {
        return self.info?.info.address.full || self.address
      },
      get fullName(): string {
        return `${self.name} - ${self.address}`
      },
      get isNative(): boolean {
        return self.type === 'native'
      },
      // TODO: заюзать геттеры из taxi/frontend/services/eda-restapp-core/packages/shared-places/placeStatusGetters.ts
      get isSelfRegPending() {
        return self.disable_details
          ? self.disable_details.reason === RESTAURANT_DISABLE_REASONS.SELF_REGISTRATION_PENDING
          : false
      },
      get isSelfRegComplete() {
        return self.disable_details
          ? self.disable_details.reason === RESTAURANT_DISABLE_REASONS.SELF_REGISTRATION_DONE
          : false
      },
      get isSelfReg(): boolean {
        const exp3 = configsStore.getConfigs()

        return !!exp3?.restapp_place?.new_design_self_registration && (this.isSelfRegPending || this.isSelfRegComplete)
      },

      get isDisabledByYandex() {
        return self.disable_details?.reason ? ENABLE_NOT_ALLOWED_REASONS.includes(self.disable_details.reason) : false
      },
      get placeLink() {
        return `${clientUrl}/restaurant/${self.slug}`
      },
      get placeQRMenuLink() {
        return `${clientUrl}/qr/${self.slug}`
      },
      get similarDeliveryTypePlaces(): unknown[] {
        const {places} = getRoot<AppStateType>(self)

        return places.all.filter((place) => self.id !== place.id && self.type === place.type)
      }
    }
  })
  .actions((self) => {
    const actions = {
      loadPlaceInfo: flow(function* () {
        const result: VendorApi['/4.0/restapp-front/places/v1/info']['GET']['response'] =
          yield self.$env.api.request.get('/4.0/restapp-front/places/v1/info', {
            params: {
              place_id: self.id
            }
          })

        self.info = PlaceInfo.create(result.payload, getEnv(self))
      }),
      loadFull: flow(function* () {
        const {loadPlaceInfo} = actions

        try {
          let promisesArray: Promise<void>[] = []

          if (!self.info) {
            promisesArray = [loadPlaceInfo()]
          }

          if (promisesArray.length === 0) {
            return
          }

          self.loading = true

          yield Promise.all(promisesArray)
          self.info!.startPolling({fireImmediately: true})

          self.loading = false
        } catch (error: unknown) {
          if (isApiError(error)) {
            errorLogger({
              level: 'error',
              error: error,
              additional: {eventSlug: 'place:load_common_info', placeId: self.id}
            })
            self.$env.snackbarNotifier.enqueue({type: 'error', error})
          }

          self.loading = false
        }
      })
    }

    return actions
  })

export default Place

export type PlaceType = Instance<typeof Place>
