import {useEffect} from 'react'
import {useUserInfo} from '@restapp/core-auth'
import {useEnv} from '@restapp/core-di'
import {sessionStore, setSensitiveStrings} from '@eda-restapp/logger'

export const SetupUserTracking: React.FC = () => {
  const userInfo = useUserInfo()
  const env = useEnv()

  useEffect(() => {
    if (userInfo) {
      setSensitiveStrings(userInfo.email)
      sessionStore.setData({partnerId: userInfo.partner_uuid})
    }
  }, [userInfo, env])

  return null
}
