import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {FeatureMarket, Typography, OutsideLink, LegacyButton as Button} from '@eda-restapp/ui'
import type {FC} from 'react'
import React, {useEffect} from 'react'
import {useInView} from 'react-intersection-observer'

import {InfoWidget} from '@restapp/shared-ui'

import {useMarketBusiness} from '../../hooks/useMarketBusiness'

import styles from './MarketBusinessBanner.module.css'

type MarketBusinessBannerProps = {
  className?: string
}

export const MarketBusinessBanner: FC<MarketBusinessBannerProps> = ({className}) => {
  const {t, tRaw} = useI18n()

  const {marketBusinessBanner} = useExp3('restapp_tools')
  const {canShowMarketBusiness} = useMarketBusiness(Boolean(marketBusinessBanner?.enabled))

  const btnText = t('shared-places.market-business-banner.btn', 'За покупками')

  const {ref, inView} = useInView({triggerOnce: true, threshold: 0})

  useEffect(() => {
    if (inView) {
      eventLogger({
        name: 'on_screen',
        value: 'show-market-banner'
      })
    }
  }, [inView])

  if (!canShowMarketBusiness || !marketBusinessBanner || !marketBusinessBanner.enabled) {
    return null
  }

  return (
    <div ref={ref} className={className}>
      <InfoWidget
        icon={<FeatureMarket fontSize={'40'} />}
        style={{background: marketBusinessBanner.bg ? marketBusinessBanner.bg : undefined}}
      >
        <Typography className={styles.content} variant={'body2'} group={'normal'}>
          {tRaw(
            'shared-places.market-business-banner.text',
            'Покупайте в несколько кликов товары для ресторанов на Яндекс Маркете для бизнеса. Промокод START1000 подарит {discountValue} скидки на первый заказ.',
            {
              discountValue: (
                <span className={styles.discountValue}>
                  {t('shared-places.market-business-banner.discountValue', '1 000 ₽')}
                </span>
              )
            }
          )}
        </Typography>

        {Boolean(marketBusinessBanner.link) && (
          <OutsideLink
            href={marketBusinessBanner.link}
            target='_blank'
            rel='noopener noreferrer'
            className={styles.link}
          >
            <Button
              onClick={() => {
                eventLogger({
                  value: 'market_banner_link',
                  name: 'link_click',
                  additional: {
                    text: btnText
                  }
                })
              }}
              className={styles.button}
              size={'s'}
              variant={'secondary'}
              styles={{
                paddingLeft: 16,
                paddingRight: 16
              }}
            >
              <Typography variant={'caption1'} thickness={'medium'}>
                {btnText}
              </Typography>
            </Button>
          </OutsideLink>
        )}
      </InfoWidget>
    </div>
  )
}
