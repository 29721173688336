import noop from 'lodash-es/noop'

const PREFIX = '__jsonp_callback_'
const TIMEOUT = 15000

let count = 0

const jsonp = function <T>(url: string) {
  const callbackName = `${PREFIX}${count++}`

  let timer: number | undefined = undefined

  return new Promise<T>((resolve) => {
    const cleanup = () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script)
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-extra-semi
      ;(window as any)[callbackName] = noop

      if (timer) {
        clearTimeout(timer)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    timer = setTimeout(cleanup, TIMEOUT) as any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    ;(window as any)[callbackName] = (data: T) => {
      cleanup()
      resolve(data)
    }

    url += (~url.indexOf('?') ? '&' : '?') + 'callback' + '=' + encodeURIComponent(callbackName)
    url = url.replace('?&', '?')

    const script = document.createElement('script')
    script.src = url
    document.head?.appendChild(script)
  })
}

export default jsonp
