import '@eda-restapp/ui/src/css/global.css'
import '@eda-restapp/ui/src/css/fonts.css'
import '@restapp/shared-assets/styles/fonts.css'
import '@restapp/shared-assets/styles/global.css'
import '@restapp/shared-assets/styles/global.mobile.css'
import {LangProvider, SUPPORTED_LANGUAGES, createFetcher} from '@eda-restapp/i18n'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/hy-am'
import 'moment/locale/ka'
import 'moment/locale/kk'
import 'moment/locale/ky'
import 'moment/locale/ru'
import 'moment/locale/uz-latn'
import 'moment/locale/az'
import React, {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {QueryClient} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {Provider as ReduxProvider} from 'react-redux'
import {isAnyOf} from '@reduxjs/toolkit'
import {PersistGate} from 'redux-persist/integration/react'

import {ApiDepsProvider} from '@restapp/core-api'
import {Provider} from '@restapp/core-di'
import config from '@restapp/core-legacy/config'
import type {AppStateType} from '@restapp/core-legacy/AppState'
import AppState from '@restapp/core-legacy/AppState'
import App from '@restapp/core-legacy/common/components/App/App'
import {rootId} from '@restapp/core-legacy/constants'
import SnackbarNotifier from '@restapp/core-legacy/lib/snackbar-notifier'
import type {IEnv} from '@restapp/core-legacy/types'
import {XivaWebsocketObservable} from '@restapp/core-notifications'
import {CountrySelectSplitPage} from '@restapp/shared/components'
import {GlobalErrorBoundary} from '@restapp/shared-boundary'
import {DialogService} from '@restapp/shared-modals'
import '@restapp/shared-polyfills'

import createLegacyFetcher from './createLegacyFetcher'
import {selectToken, setToken, userLogout, unauthorized, setOauthToken} from '@restapp/core-auth'
import {initConfigs} from './initConfigs'
import {store, api, apiRequest, configsStore, listenerMiddleware, persistor} from './coreDeps'
import {patchWindowOpen} from './native/utils/patchWindowOpen'
import {ServiceWorkerContainer} from './sw'
import {broadcastWebSocketNotifications} from './notifications'
import {clearDirectToken, nativeCommunicationChannel, notificationsChannel} from '@eda-restapp/microfrontend'
import {SnackbarsExperiment} from './SnackbarsExperiment'
import {RUM} from '@eda-restapp/logger/src/rum/interface'
import {RedirectHandler} from './RedirectHandler'
import {SeamlessTransferToOtherDomain} from './SeamlessTransferToOtherDomain'
import {initSecretsWatcher} from './initSecretsWatcher'
import {errorLogger, eventLogger, metrika} from '@eda-restapp/logger'
import {PermissionsProvider, StylesProviders} from './providers'
import {Communications} from '@eda-restapp/communications'

eventLogger({
  name: 'bootstrap:init_app'
})

if (window.location.host.endsWith('.')) {
  location.host = location.host.slice(0, -1)
}

const fetchKeyset = createFetcher('https://yastatic.net/s3/restapp-static/i18n/eda-restapp-core')

// Preload RU lang
const PromisePreloadRULocale = fetchKeyset(SUPPORTED_LANGUAGES.RU)
moment.locale(SUPPORTED_LANGUAGES.RU)

patchWindowOpen()

const root = createRoot(document.getElementById(rootId) as HTMLElement)

const initApp = async () => {
  initSecretsWatcher()

  try {
    await initConfigs()
  } catch (error) {
    errorLogger({
      level: 'error',
      error: error instanceof Error ? error : new Error('error initializing configs'),
      additional: {eventSlug: 'bootstrap:init_configs_error'}
    })
  }

  let appState: AppStateType

  const snackbarNotifier = new SnackbarNotifier(() => {
    const configs = configsStore.getConfigs()
    const legacySnackbarConfig = configs.restapp_common.snackbar
    return {
      ...legacySnackbarConfig,
      enableNewSnackbars: configs.restapp_snackbars.enableNewSnackbars
    }
  })

  const dialogs = new DialogService()
  const queryClient = new QueryClient({
    defaultOptions: {
      // Retries are configured in /packages/core-legacy/api/Api.ts
      queries: {
        retry: false,
        useErrorBoundary: configsStore.getConfigs().restapp_api.enableUseErrorBoundary
      },
      mutations: {retry: false}
    }
  })

  const env: Omit<IEnv, 'socketEvent$'> = {
    api,
    snackbarNotifier,
    dialogs,
    fetchApiQuery: createLegacyFetcher(() => {
      return {
        request: apiRequest,
        socketEvent$,
        queryClient,
        snackbar: snackbarNotifier
      }
    })
  }

  appState = AppState.create({}, env)

  //#region store configuration

  // Logger
  const isSensitiveAction = isAnyOf(setToken, setOauthToken)
  listenerMiddleware.startListening({
    predicate: (action) =>
      Boolean(configsStore.getConfigs().restapp_logs.enable_redux_actions_logging) && !isSensitiveAction(action),
    effect: (action) => {
      eventLogger({
        name: 'redux-action',
        value: action.type,
        additional: {action, eventLevel: 'debug'}
      })
    }
  })

  // Logout cleanup
  listenerMiddleware.startListening({
    matcher: isAnyOf(userLogout, unauthorized),
    effect: async (action, {getOriginalState, extra}) => {
      if (userLogout.match(action)) {
        // User initiated logout
        metrika({target: 'log_out'})

        const token = selectToken(getOriginalState())

        if (token) {
          await extra.apiRequest({
            url: '/4.0/restapp-front/api/v1/client/logout',
            method: 'POST',
            headers: {'X-Token': token}
          })
        }
      }

      nativeCommunicationChannel.broadcast({type: 'userLogout'})

      clearDirectToken()

      queryClient.removeQueries()
    }
  })
  //#endregion store configuration

  appState.setReduxState(store.getState())
  store.subscribe(() => appState.setReduxState(store.getState()))

  const socketEvent$ = new XivaWebsocketObservable({
    api,
    store
  })

  // TODO: Move to Notifications module
  broadcastWebSocketNotifications({socketEvent$, channel: notificationsChannel})

  env.store = store
  env.dispatch = store.dispatch

  const nativeApi = window.AndroidInterface || window.webkit?.messageHandlers.restappNative
  let NativeContainer: React.FC<{children?: React.ReactNode}> = ({children}) => <>{children}</>

  if (nativeApi) {
    const nativeModule = await import('./native')
    NativeContainer = nativeModule.getNativeContainer(nativeApi)
  }

  function RootRouter() {
    return (
      <SeamlessTransferToOtherDomain>
        <RedirectHandler>
          <App />
        </RedirectHandler>
      </SeamlessTransferToOtherDomain>
    )
  }

  // Для 17 реакта
  const router = createBrowserRouter([{path: '*', Component: RootRouter}], {
    basename: config.BASENAME
  })

  const initedLocale = {
    [SUPPORTED_LANGUAGES.RU]: await PromisePreloadRULocale
  }

  root.render(
    <StrictMode>
      <StylesProviders>
        <GlobalErrorBoundary>
          <LangProvider
            defaultKeysets={initedLocale}
            fetcher={fetchKeyset}
            onChangeLanguage={(lang) => {
              api.setLanguage(lang)
              // Set all queries as obsolete to refetch them to pull up current translations
              queryClient.invalidateQueries()

              eventLogger({name: 'lang-change', value: lang})

              switch (lang) {
                case SUPPORTED_LANGUAGES.EN:
                  moment.locale('en-gb')
                  break
                case SUPPORTED_LANGUAGES['ES-LA']:
                  moment.locale('es')
                  break
                case SUPPORTED_LANGUAGES.FR:
                  moment.locale('fr')
                  break
                case SUPPORTED_LANGUAGES.HY:
                  moment.locale('hy-am')
                  break
                case SUPPORTED_LANGUAGES.KA:
                  moment.locale('ka')
                  break
                case SUPPORTED_LANGUAGES.KK:
                  moment.locale('kk')
                  break
                case SUPPORTED_LANGUAGES.KY:
                  moment.locale('ky')
                  break
                case SUPPORTED_LANGUAGES.UZ:
                  moment.locale('uz-latn')
                  break
                case SUPPORTED_LANGUAGES.AZ:
                  moment.locale('az')
                  break
                case SUPPORTED_LANGUAGES.DEV:
                  moment.locale('en')
                  break
                default:
                  moment.locale([lang, 'ru'])
                  break
              }
            }}
          >
            <Provider
              appState={appState}
              env={{
                ...env,
                socketEvent$
              }}
            >
              <ApiDepsProvider
                request={apiRequest}
                socketEvent$={socketEvent$}
                queryClient={queryClient}
                snackbar={snackbarNotifier}
              >
                {process.env.NODE_ENV === 'development' && (
                  <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
                )}
                <ReduxProvider store={store}>
                  <Communications.Provider>
                    <PermissionsProvider>
                      <PersistGate loading={null} persistor={persistor}>
                        <ServiceWorkerContainer />
                        <SnackbarsExperiment />
                        <CountrySelectSplitPage navigate={router.navigate}>
                          <NativeContainer>
                            <RouterProvider router={router} />
                          </NativeContainer>
                        </CountrySelectSplitPage>
                      </PersistGate>
                    </PermissionsProvider>
                  </Communications.Provider>
                </ReduxProvider>
              </ApiDepsProvider>
            </Provider>
          </LangProvider>
        </GlobalErrorBoundary>
      </StylesProviders>
    </StrictMode>
  )
  if ('requestIdleCallback' in window && typeof window.requestIdleCallback === 'function') {
    window.requestIdleCallback(() => RUM.sendTimeMark(RUM.Counter.ReactInited), {timeout: 1200000})
  } else {
    setTimeout(() => RUM.sendTimeMark(RUM.Counter.ReactInited), 0)
  }
}

void initApp().finally(() => {
  RUM.timeEnd(RUM.Counter.MainJS)
})
