import {useEffect} from 'react'
import {filter} from 'rxjs/operators'

import {useEnv} from '@restapp/core-di'
import type {SocketEvents} from '@restapp/core-legacy/types/socket'

import type {SocketEvent} from './types'
import mapMessagesToEvents from './XivaWebsocketObservable/mapMessagesToEvents'

/**
 * @deprecated Нужно использовать useNotification https://st.yandex-team.ru/EDARESTAPP-7172
 */
export function useSocketEvent<E extends keyof SocketEvents>(
  eventName: E,
  eventHandler: (data: SocketEvent<E>) => unknown
) {
  const {socketEvent$} = useEnv()

  useEffect(() => {
    const sub = socketEvent$
      .pipe(
        mapMessagesToEvents(),
        filter((eventData): eventData is SocketEvent<E> => eventData.event === eventName)
      )
      .subscribe((data) => eventHandler(data))
    return () => sub.unsubscribe()
  }, [eventName, eventHandler, socketEvent$])
}
