import {lazy} from 'react'
import {type MarkdownProps} from './Markdown'
import {Suspense} from '@restapp/shared-boundary'

const Markdown = lazy(() => import(/* webpackChunkName: "Markdown" */ './Markdown'))

export default (props: MarkdownProps) => (
  <Suspense>
    <Markdown {...props} />
  </Suspense>
)
