import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, PauseFillIcon, useIsMobileStrict, Modal} from '@eda-restapp/ui'
import React, {type FC, useState} from 'react'

import {RESTAURANT_DISABLE_REASONS} from '@restapp/shared'
import type {FullRestaurant, OldFullRestaurant} from '@restapp/shared-api'
import {getOldPlaceStatus} from '@restapp/shared-places'
import {usePlaceStatus} from '@restapp/shared-places/hooks/usePlaceStatus'

import AutostopInfoForNonComitent from '../AutostopInfoForNonComitent'
import {DisablePlaceModalContainer} from '../DisablePlaceModal'
import PlaceEnabledHelpIcon from '../PlaceEnabledHelpIcon'
import {PLACE_STATUS} from '../../../../models/Places/PlaceStatus'
import {AutostopDescriptionModal} from '../../Place/modals/AutostopDescriptionModal'
import {BlockDescriptionModalContainer} from '../../Place/modals/BlockDescriptionModal'

import styles from './PlaceAction.module.css'

type PlaceActionProps = {
  enablePlaceMutationLoading: boolean
  isHideDisableButton: boolean
  place: FullRestaurant | OldFullRestaurant
  size?: 's' | 'm' | 'l'
  smallDisableButtonMobile: boolean
  onEnableButtonClick: () => void
}

export const PlaceAction: FC<PlaceActionProps> = ({
  enablePlaceMutationLoading,
  isHideDisableButton,
  place,
  size = 's',
  smallDisableButtonMobile,
  onEnableButtonClick
}) => {
  const [isAutostopDescriptionModalOpen, setIsAutostopDescriptionModalOpen] = useState(false)
  const [isBlockDescriptionModalOpened, setIsBlockDescriptionModalOpened] = useState(false)
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false)

  const placeBlocksConfig = useExp3('restapp_place_blocks')
  const placeConfig = useExp3('restapp_place')
  const placeStatus = usePlaceStatus(place as FullRestaurant)
  const isMobile = useIsMobileStrict()
  const {t} = useI18n()

  const handleAutostopDescriptionButtonClick = () => setIsAutostopDescriptionModalOpen(true)
  const handleCloseAutostopDescriptionModal = () => setIsAutostopDescriptionModalOpen(false)
  const handleDisableButtonClick = () => setIsDisableModalOpen(true)

  const fullPlaceBlockInfo = (place as FullRestaurant).blocks?.[0]
  const resultPlaceStatus = placeConfig.newRestaurantSearchHandle
    ? placeStatus
    : getOldPlaceStatus(place as OldFullRestaurant)
  const isNewAutostopInfoModalEnabled =
    !!fullPlaceBlockInfo?.reason && placeBlocksConfig.autostopBlocksTypeList.includes(fullPlaceBlockInfo.reason)

  const enableButton = (
    <Box className={styles.button}>
      <Button.Text
        data-testid={'place-action-button-enable' /* Ресторан | Кнопка "Включить" ресторан*/}
        disabled={!place.can_be_enabled}
        fullWidth
        size={size}
        loading={enablePlaceMutationLoading}
        // TODO: Api query with places invalidation
        onClick={onEnableButtonClick}
      >
        {t('core-legacy.place-action.vklyuchit', 'Включить')}
      </Button.Text>
    </Box>
  )

  const learnMoreButton = (
    <Box className={styles.button}>
      <Button.Text
        data-testid={'place-action-button-learn-more' /* Ресторан | Кнопка "Подробнее" если выключен через автостоп */}
        fullWidth
        size={size}
        onClick={handleAutostopDescriptionButtonClick}
      >
        {t('core-legacy.place-action.podrobnee', 'Подробнее')}
      </Button.Text>
    </Box>
  )

  const disableButton = (
    <Box className={smallDisableButtonMobile ? undefined : styles.button}>
      {isMobile && smallDisableButtonMobile && (
        <Button.Icon
          loading={isDisableModalOpen}
          size={size}
          variant='secondary'
          onClick={handleDisableButtonClick}
          data-testid={'place-action-button-disable' /* Ресторан | Кнопка "Отключить" ресторан*/}
        >
          <Box justifyContent='center' alignItems='center' style={{width: 24, height: 24}}>
            <PauseFillIcon fontSize={18} />
          </Box>
        </Button.Icon>
      )}
      {!(isMobile && smallDisableButtonMobile) && (
        <Button.Text
          fullWidth={isMobile && !smallDisableButtonMobile}
          loading={isDisableModalOpen}
          size={size}
          variant='secondary'
          onClick={handleDisableButtonClick}
          data-testid={'place-action-button-disable' /* Ресторан | Кнопка "Отключить" ресторан*/}
        >
          {t('core-legacy.place-action.otklyuchit', 'Отключить')}
        </Button.Text>
      )}
    </Box>
  )

  const showBlockDescriptionButton = (
    <Box className={styles.buttonWrapperBlock}>
      <Button.Text
        data-testid={
          'place-action-button-about-block' /* Places | Button "About block" in Place card for place that blocked */
        }
        fullWidth
        size={size}
        variant='secondary'
        onClick={() => setIsBlockDescriptionModalOpened(true)}
      >
        {t('core-legacy.place-action.blocked-place-button-label', 'О блокировке')}
      </Button.Text>
    </Box>
  )

  const renderAction = () => {
    const isNewBlockInfoModalsEnabled =
      placeConfig.newRestaurantSearchHandle && placeBlocksConfig.newBlockInfoModalEnabled
    const isPartnerBlockEnabled = fullPlaceBlockInfo?.source === 'partner'
    const isPartnerRequestBlock =
      fullPlaceBlockInfo?.reason && placeBlocksConfig.partnerRequestBlocksTypeList.includes(fullPlaceBlockInfo.reason)

    if (
      isNewBlockInfoModalsEnabled &&
      fullPlaceBlockInfo &&
      (isNewAutostopInfoModalEnabled || (!isPartnerBlockEnabled && !isPartnerRequestBlock))
    ) {
      return showBlockDescriptionButton
    }

    switch (resultPlaceStatus) {
      case PLACE_STATUS.SELF_REG_PENDING:
        return <PlaceEnabledHelpIcon size={size === 'l' ? 'm' : size} />
      case PLACE_STATUS.ACTIVATION_PENDING: //  TODO: отобразить залоченную кнопку "Включается" ?
      case PLACE_STATUS.CAN_NOT_BE_ENABLED:
        return null
      case PLACE_STATUS.SELF_REG_COMPLETE:
      case PLACE_STATUS.DISABLED_BY_VENDOR:
      case PLACE_STATUS.DISABLED_BY_YANDEX:
      case PLACE_STATUS.DISABLED_BY_AUTOSTOP:
        return place.can_be_enabled ? enableButton : learnMoreButton
      case PLACE_STATUS.DISABLED:
        return enableButton
      default:
        return !isHideDisableButton && disableButton
    }
  }
  return (
    <>
      <div>{renderAction()}</div>
      <Modal open={isAutostopDescriptionModalOpen} onClose={handleCloseAutostopDescriptionModal}>
        {place.disable_details?.reason === RESTAURANT_DISABLE_REASONS.AUTOSTOP_NON_COMITENT_UZ_PARTNER ? (
          <AutostopInfoForNonComitent />
        ) : (
          <AutostopDescriptionModal placeBlockFinishDate={place.disable_details?.available_at} />
        )}
      </Modal>

      <BlockDescriptionModalContainer
        blockInfo={(place as FullRestaurant).blocks?.[0]}
        isAutostopInfo={isNewAutostopInfoModalEnabled}
        isOpen={isBlockDescriptionModalOpened}
        placeId={place.id}
        onModalClose={() => setIsBlockDescriptionModalOpened(false)}
      />

      <DisablePlaceModalContainer
        isOpen={isDisableModalOpen}
        place={place}
        onCloseClick={() => setIsDisableModalOpen(false)}
      />
    </>
  )
}
