import {useExp3} from '@eda-restapp/configs'
import {UnreadCounter} from '@yandex-int/messenger.unread-counter'
import type {FC} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {selectIsLoggedIn, selectOauthToken} from '@restapp/core-auth'

import {chatsSlice} from '../../../slice/chatsSlice'
import {UNREAD_COUNTER_TEST, UNREAD_COUNTER_PROD, DEBUG_BOT_GUID, DEBUG_SERVICE_ID} from '../../constants'

export const SingleChatCounter: FC = () => {
  const supportSingleChatConfig = useExp3('restapp_support_single_chat')
  const oauthToken = useSelector(selectOauthToken)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isSingleChatOpened = useSelector(chatsSlice.selectors.selectSingleChatOpen)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoggedIn || isSingleChatOpened || !supportSingleChatConfig.backgroundUnreadCounter) {
      return
    }

    const unreadCounter = new UnreadCounter({
      url: replaceTld(
        supportSingleChatConfig.backend === 'test' && !supportSingleChatConfig.debug
          ? UNREAD_COUNTER_TEST
          : UNREAD_COUNTER_PROD
      ),
      authToken: oauthToken ? `OAuth ${oauthToken}` : undefined,
      guid: supportSingleChatConfig.debug ? DEBUG_BOT_GUID : supportSingleChatConfig.botGuid,
      serviceId: supportSingleChatConfig.debug ? DEBUG_SERVICE_ID : supportSingleChatConfig.serviceId,
      workspaceId: supportSingleChatConfig.debug ? undefined : supportSingleChatConfig.workspace,
      callback: (data) => {
        if (data.value == null) {
          return
        }

        dispatch(chatsSlice.actions.setSingleChatUnreadCount(data.value))
      }
    })

    unreadCounter.start()

    return () => {
      unreadCounter.stop()
    }
  }, [dispatch, isLoggedIn, isSingleChatOpened, oauthToken, supportSingleChatConfig])

  return null
}

function replaceTld(url: string) {
  // не совсем тоже самое что делает сам мессенджер под капотом, но пока у нас только два tld: ru и com - должно быть ок
  return url.replace('{tld}', window.location.hostname.split('.').pop() as string)
}
