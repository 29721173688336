import {useInfiniteLoader} from '@eda-restapp/hooks'
import {metrika} from '@eda-restapp/logger'
import {Box, useIsMobileStrict} from '@eda-restapp/ui'
import {flatMapDeep, last} from 'lodash-es'
import {useCallback, type FC, useMemo} from 'react'
import {useSelector} from 'react-redux'

import {useApiInfiniteQuery, InfiniteQueryAdapters} from '@restapp/core-api'
import {usePlaces} from '@restapp/core-places'
import Spinner from '@restapp/shared-ui/Spinner'

import ChatsList from '../../components/ChatsList'
import ChatsTable from '../../components/ChatsTable'
import NoChats from '../../components/NoChats'
import {useSupportChat} from '../../hooks/useSupportChat'
import {chatsSlice} from '../../slice/chatsSlice'

type ChatsListContainerProps = {
  className?: string
}

export const ChatsListContainer: FC<ChatsListContainerProps> = ({className}) => {
  const isMobile = useIsMobileStrict()
  const {openChat} = useSupportChat()
  const chatsFilters = useSelector(chatsSlice.selectors.selectFilters)

  const {selectedPlaceIds, placesIds} = usePlaces({suspense: true})
  const isAllPlacesSelected = selectedPlaceIds.length === placesIds.length

  const LIMIT = 30
  const chatsListQuery = useApiInfiniteQuery(
    {
      url: '/4.0/restapp-front/support_chat/v1/chat/list',
      method: 'GET',
      params: {
        place_ids: isAllPlacesSelected ? undefined : selectedPlaceIds.map(String),
        status: chatsFilters.status === 'all' ? undefined : chatsFilters.status,
        author: chatsFilters.author === 'all' ? undefined : chatsFilters.author,
        sorting_order: chatsFilters.sortOrder,
        topic: chatsFilters.topic === 'all' ? undefined : chatsFilters.topic,
        newer_than: chatsFilters.dateFrom?.toISOString(),
        older_than: chatsFilters.dateTo?.toISOString(),
        offset: 0,
        limit: LIMIT
      }
    },
    {
      suspense: true,
      adapter: InfiniteQueryAdapters.CHATS_LIST,
      staleTime: 1000 * 60 * 3
    }
  )

  const chatsList = useMemo(
    () => flatMapDeep(chatsListQuery.data?.pages, (page) => page.chats),
    [chatsListQuery.data?.pages]
  )

  const hasNextPage = useMemo(() => {
    const lastPage = last(chatsListQuery.data?.pages)
    const lastPageLength = lastPage?.chats.length

    if (lastPageLength && lastPageLength < LIMIT) {
      return false
    }

    return !!chatsListQuery.hasNextPage
  }, [chatsListQuery.data?.pages, chatsListQuery.hasNextPage])

  const infiniteLoader = useInfiniteLoader<HTMLDivElement>({
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.75,
    loadMore: chatsListQuery.fetchNextPage,
    canLoadMore: hasNextPage
  })

  const chatClickHandler = useCallback(
    (chat: {chat_id: string}) => {
      openChat(chat.chat_id)
      metrika({target: 'chats_chat_click', params: {id: chat.chat_id}})
    },
    [openChat]
  )

  if (chatsList.length < 1) {
    return <NoChats />
  }

  return (
    <Box className={className} flexDirection='column' pt={isMobile ? 'm' : undefined}>
      {isMobile && <ChatsList chats={chatsList} onClickChat={chatClickHandler} />}

      {!isMobile && (
        <ChatsTable chats={chatsList} hideRestaurantCell={placesIds.length < 2} onClickChat={chatClickHandler} />
      )}

      {hasNextPage && (
        <Box ref={infiniteLoader.loaderRef} alignContent='center' justifyContent='center' p='m'>
          {<Spinner style={{visibility: chatsListQuery.isFetchingNextPage ? 'visible' : 'hidden'}} size={32} />}
        </Box>
      )}
    </Box>
  )
}
