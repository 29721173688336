import type {CropValue} from 'croppr'

const QUALITY = 0.8

type IImageSize = {
  height: number
  width: number
}

function loadImage(src: string) {
  const img = new Image()
  img.setAttribute('crossorigin', 'anonymous')

  return new Promise((resolve) => {
    img.addEventListener('load', () => resolve(img))
    img.setAttribute('src', src)
  })
}

const limitSize = (size: IImageSize) => {
  // width * height должна быть меньше 16777216 на ios
  const canvasSquareIosLimit = 16777216
  const {width, height} = size

  const requiredPixels = width * height
  if (requiredPixels <= canvasSquareIosLimit) {
    return {width, height}
  }

  const scalar = Math.sqrt(canvasSquareIosLimit) / Math.sqrt(requiredPixels)
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar)
  }
}

export async function getCroppedImg(src: string, crop: CropValue): Promise<Blob | null> {
  const img = (await loadImage(src)) as HTMLImageElement

  // Иногда изображение нельзя кадрировать с точностью до пикселя и либа выдает погрешность, из-за которой появляются черные полосы на фотографиях
  const adjustedCoords = {
    x: Math.max(crop.x, 0),
    y: Math.max(crop.y, 0)
  }

  const widthDiff = Math.max(crop.width + adjustedCoords.x - img.naturalWidth, 0)
  const heightDiff = Math.max(crop.height + adjustedCoords.y - img.naturalHeight, 0)

  const adjustedCrop = {
    ...adjustedCoords,
    width: crop.width - widthDiff,
    height: crop.height - heightDiff
  }

  // Уменьшаем при надобности размер канваса из-за ограничений по пикселям на ios
  const limitedImageSize = limitSize(adjustedCrop)

  const canvas = document.createElement('canvas')
  const scaleX = img.naturalWidth / img.width
  const scaleY = img.naturalHeight / img.height
  canvas.width = limitedImageSize.width
  canvas.height = limitedImageSize.height
  const ctx = canvas.getContext('2d')

  ctx?.drawImage(
    img,
    adjustedCrop.x / scaleX,
    adjustedCrop.y / scaleY,
    adjustedCrop.width / scaleX,
    adjustedCrop.height / scaleY,
    0,
    0,
    limitedImageSize.width,
    limitedImageSize.height
  )

  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      'image/jpeg',
      QUALITY
    )
  })
}
