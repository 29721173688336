import {useI18n} from '@eda-restapp/i18n'
import {
  Box,
  Button,
  Typography,
  Text,
  ArrowLeftIcon,
  useIsDesktopStrict,
  useIsTabletStrict,
  ChevronLeftMIcon
} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'
import React from 'react'

import type {Service, PlacePlus} from '@restapp/shared-api'

import PlusServiceSelector from '../PlusServiceSelector'

import styles from './ConnectMoreCondition.module.css'

export type ConnectMoreConditionProps = {
  selectedPlaces: number[]
  selectedServices: Service[]
  drawerPlaces?: PlacePlus[]
  isSingleServicePlaces: boolean
  isDisabledState: boolean
  switchBack: () => void
  setSelectedServices: (values: Service[]) => void
  setSelectedPlaces: (values: number[]) => void
  enablePlus: (values: number[], services: Service[]) => void
}

const ConnectMoreCondition: FC<ConnectMoreConditionProps> = ({
  selectedPlaces,
  selectedServices,
  drawerPlaces,
  isSingleServicePlaces,
  isDisabledState,
  setSelectedPlaces,
  setSelectedServices,
  switchBack,
  enablePlus
}) => {
  const {t} = useI18n()
  const isDesktopStrict = useIsDesktopStrict()
  const isTabletStrict = useIsTabletStrict()
  const isDesktop = isDesktopStrict || isTabletStrict

  const connectPlus = () => {
    if (selectedPlaces.length > 0) {
      enablePlus(selectedPlaces, selectedServices)
      switchBack()
    }
  }

  const handleCloseModal = () => {
    switchBack()
    setSelectedPlaces([])
  }

  const BackIcon = isDesktop ? ArrowLeftIcon : ChevronLeftMIcon

  return (
    <>
      <Box flexDirection='column' className={styles.container}>
        <Box
          gap='s'
          alignItems='center'
          className={cn(styles.block, {[styles.blockMobile]: !isDesktop})}
          p={isDesktop ? 'l' : 'm'}
        >
          <BackIcon onClick={handleCloseModal} className={styles.backIcon} />
          <Typography
            variant={isDesktop ? 'title4' : 'body2'}
            group='normal'
            thickness='medium'
            className={cn({[styles.mobileTitle]: !isDesktop})}
          >
            {isDesktop
              ? t('shared-places.plus-drawer.enable-for-plus-text', 'Подключить ещё в Плюс')
              : t('shared-places.plus-drawer.enable-for-plus-text-mobile', 'Подключить ещё')}
          </Typography>
          {!isDesktop && <div className={styles.divider} />}
        </Box>
        <Box
          gap='s'
          flexWrap='wrap'
          flexDirection='column'
          className={cn(styles.block, {[styles.blockMobile]: !isDesktop})}
          p='l'
          flex={1}
        >
          <PlusServiceSelector
            places={drawerPlaces}
            isSecondDrawer
            services={selectedServices}
            setServices={setSelectedServices}
            selectedPlaces={selectedPlaces}
            setSelectedPlaces={setSelectedPlaces}
            isSingleServicePlaces={isSingleServicePlaces}
          />
        </Box>
      </Box>

      <div className={cn(styles.footer, {[styles.footerDesktop]: isDesktop})}>
        <Button.Text fullWidth disabled={isDisabledState} onClick={connectPlus}>
          {t('shared-places.plus-drawer.enable-plus-text', 'Подключить')}
        </Button.Text>
        <Box mt='s'>
          <Text align='center'>
            <Typography variant='caption1' group='loose' color='minor'>
              {t(
                'shared-places.plus-connect-modal.enable-attention-text',
                'Нажимая «Подключить», вы соглашаетесь с условиями подключения'
              )}
            </Typography>
          </Text>
        </Box>
      </div>
    </>
  )
}

export default ConnectMoreCondition
