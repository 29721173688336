import {useEffect, useRef} from 'react'

import {useEnv} from '@restapp/core-di'

import type {DialogParams} from '../types'

type Params<TOpenParams> = ((params: TOpenParams) => DialogParams) | DialogParams

type Callbacks<TOpenParams> = {
  onConfirm?: (params: TOpenParams) => void
  onCancel?: (params: TOpenParams) => void
}

type Result<TOpenParams> = (params: TOpenParams) => void

export default function useSimpleDialog<TOpenParams = void>(
  dialogParams: Params<TOpenParams>,
  callbacks: Callbacks<TOpenParams> = {}
): Result<TOpenParams> {
  const callbacksRef = useRef(callbacks)
  const {dialogs} = useEnv()

  callbacksRef.current = callbacks

  useEffect(
    () => () => {
      callbacksRef.current = {}
    },
    []
  )

  return (openParams) => {
    const resolve = (isConfirmed: boolean) => {
      if (isConfirmed) {
        callbacksRef.current?.onConfirm?.(openParams)
      } else {
        callbacksRef.current?.onCancel?.(openParams)
      }
    }

    void dialogs
      .open(typeof dialogParams === 'function' ? dialogParams(openParams) : dialogParams)
      .then(resolve, () => resolve(false))
  }
}
