import {useCopyToClipboard} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Copy2Icon, Tooltip, Typography} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC, type ReactNode, useState} from 'react'

import styles from './CopyText.module.css'

type CopyTextProps = {
  className?: string
  copyValue: string
  label?: ReactNode
}

export const CopyText: FC<CopyTextProps> = ({className, copyValue, label = copyValue}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const {t} = useI18n()
  const clipboard = useCopyToClipboard({copiedTimeout: 2000})

  return (
    <Box className={cn(styles.root, className)} alignItems='center'>
      {label}
      <Button.Icon
        ref={setAnchorEl}
        size='s'
        variant='transparent'
        onClick={(event) => {
          event.stopPropagation()
          clipboard.copy(copyValue)
        }}
      >
        <Copy2Icon />
      </Button.Icon>

      {anchorEl && clipboard.copied && (
        <Tooltip.Info className={styles.tooltip} anchorEl={anchorEl} offsetDistance={12} leaveDelay={2000}>
          <Typography variant='caption1'>{t('main-chats.copy-text.skopirovano', 'Скопировано')}</Typography>
        </Tooltip.Info>
      )}
    </Box>
  )
}
