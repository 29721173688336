import type {Reducer, Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'

import {persistentStorage} from './persistentStorage'

export function makePersistent<TState, TAction extends Action>(
  reducer: Reducer<TState, TAction>,
  key: string,
  version = 0
) {
  return persistReducer({version, storage: persistentStorage, key}, reducer)
}
