import {errorLogger} from '@eda-restapp/logger'
import type React from 'react'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {useEnv} from '@restapp/core-di'
import type {UnauthorizedEventData} from '@restapp/core-legacy/types'
import {AppEvent, PassportError} from '@restapp/core-legacy/types'
import useDirect from '@restapp/promotion/hooks/useDirect'

import {unauthorized} from '../../slice'

const WatchUnauthorizedEvents: React.FC = () => {
  const env = useEnv()
  const {directLogout} = useDirect()
  const dispatch = useDispatch()

  useEffect(() => {
    const onUnauthorized = (data: UnauthorizedEventData) => {
      const isPassportError =
        data.error_slug === PassportError.PASSPORT_TOKEN_ERROR ||
        data.error_slug === PassportError.WRONG_PASSPORT_ACCOUNT

      if (isPassportError) {
        directLogout()
        errorLogger({
          level: 'error',
          sourceType: 'unauthorized',
          error: new Error('direct passport unauthorized error'),
          additional: {eventSlug: 'unauthorized-passport'}
        })
        return
      }

      dispatch(unauthorized())
      errorLogger({
        level: 'error',
        sourceType: 'unauthorized',
        error: new Error('unauthorized'),
        additional: {eventSlug: 'unauthorized', eventData: data}
      })
    }

    env.api.events.on(AppEvent.Unauthorized, onUnauthorized)

    return () => {
      env.api.events.removeListener(AppEvent.Unauthorized, onUnauthorized)
    }
  }, [env.api.events, directLogout, dispatch])

  return null
}

export default WatchUnauthorizedEvents
