import {useI18n} from '@eda-restapp/i18n'
import moment from 'moment'
import type {ComponentType, ReactNode} from 'react'
import React from 'react'

import type {PortalComponentProps} from '@restapp/core-legacy/hocs/withPortals'
import {usePlaces} from '@restapp/core-places'
import {ModalBody, ModalHeader, ModalLayout} from '@restapp/shared-ui'

import type {IChat} from '../../types'
import {formatSla, formatUpdatedDate} from '../../utils/formating'
import {getChatSlaHours} from '../../utils/getChatSlaHours'
import ChatStatusChip from '../ChatStatusChip'
import {CopyText} from '../CopyText'

import {useChatInfoModalStyles} from './ChatInfoModal.style'

type ChatMetaModalProps = {
  chat: IChat
}

function ChatInfoModal({chat, ...portalProps}: ChatMetaModalProps & PortalComponentProps) {
  const {t} = useI18n()
  const {classes: c} = useChatInfoModalStyles()
  const {getPlaceById} = usePlaces()
  const chatPlace = getPlaceById(Number(chat.chat_id))

  function row(label: string, value: ReactNode) {
    return (
      <>
        <div className={c.label}>{label}</div>
        <div>{value}</div>
      </>
    )
  }

  const chatSla = getChatSlaHours(chat)

  return (
    <ModalLayout {...portalProps}>
      <ModalHeader>
        {t('main-chats.chat-info-modal.informatsiya-po-obrashcheniyu', 'Информация по обращению')}
      </ModalHeader>
      <ModalBody>
        <div className={c.grid}>
          {row(t('main-chats.chat-info-modal.status', 'Статус'), <ChatStatusChip chat={chat} />)}
          {!!chatSla && row(t('main-chats.chat-info-modal.vremya-otveta', 'Время ответа'), formatSla(chatSla))}
          {!!chat.last_message_created_at &&
            row(
              t('main-chats.chat-info-modal.obnovleno', 'Обновлено'),
              formatUpdatedDate(moment(chat.last_message_created_at))
            )}
          {row(t('main-chats.chat-info-modal.sozdano', 'Создано'), moment(chat.created_at).format('HH:mm DD.MM.YYYY'))}
          {row(t('main-chats.chat-info-modal.id-obrashcheniya', 'ID обращения'), <CopyText copyValue={chat.chat_id} />)}
          {!!chat.order_id &&
            row(t('main-chats.chat-info-modal.zakaz-№', 'Заказ №'), <CopyText copyValue={chat.order_id} />)}
          {row(t('main-chats.chat-info-modal.tema', 'Тема'), chat.topic)}
          {row(t('main-chats.chat-info-modal.avtor', 'Автор'), chat.author)}
          {!!chatPlace?.name && row(t('main-chats.chat-info-modal.restoran', 'Ресторан'), chatPlace.name)}
          {!!chatPlace?.address && row(t('main-chats.chat-info-modal.adres', 'Адрес'), chatPlace.address)}
        </div>
      </ModalBody>
    </ModalLayout>
  )
}

export default ChatInfoModal as ComponentType<ChatMetaModalProps>
