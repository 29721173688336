import {useI18n} from '@eda-restapp/i18n'
import {Box, Button, Informer, Tooltip, Typography, useIsMobileStrict, UserAvatar, Modal} from '@eda-restapp/ui'
import React, {useState} from 'react'

import type {LinkedAccount} from '@restapp/shared-api/types/passport'
import {OutsideLink} from '@restapp/shared-ui'

import type {ExternalAccountSuggest} from '../../../../../../types'
import {usePassportHelpURL, usePassportName} from '../../../../../hooks'
import {PassportIcon} from '../../../../PassportIcon'
import idWithAlertIcon from '../../../PassportDrawer/PassportDrawerCards/images/idWithAlertIcon.png'
import {LinkedAccounts} from '../../PassportModal/ModalWithLinkedAccounts/LinkedAccounts'
import {PassportFullScreenCloseButton} from '../PassportFullScreenCloseButton'

import styles from './WithLinkedAccountsContent.module.css'

type WithLinkedAccountsContentProps = {
  accounts: LinkedAccount[]
  closeDelay?: number
  maxLinkedAccountLimitReached?: boolean
  suggestedAccount?: ExternalAccountSuggest
  onClose: () => void
  onBindOtherAccount(): void
  onBindSuggestedAccount(): void
  onAuthWithLinkedAccount(account: LinkedAccount): void
}
export const WithLinkedAccountsContent = ({
  accounts,
  suggestedAccount,
  onAuthWithLinkedAccount,
  maxLinkedAccountLimitReached,
  onBindSuggestedAccount,
  onBindOtherAccount,
  onClose,
  closeDelay
}: WithLinkedAccountsContentProps) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()
  const passportName = usePassportName()
  const helpUrl = usePassportHelpURL()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const renderButtons = () =>
    suggestedAccount && !maxLinkedAccountLimitReached ? (
      <Box
        gap={isMobile ? 's' : 'm'}
        flexDirection={isMobile ? 'column' : 'row'}
        className={styles.suggestedAccountButtons}
      >
        <Button.Text variant='secondary' onClick={onBindOtherAccount} fullWidth>
          {t('core_auth.passport.with-linked-accounts-content.other-account', 'Выбрать другой аккаунт')}
        </Button.Text>

        <Button.MultiSpace
          variant='dark'
          startIcon={<PassportIcon />}
          endIcon={<UserAvatar className={styles.round} src={suggestedAccount?.avatar} />}
          onClick={onBindSuggestedAccount}
          fullWidth
        >
          {t('core_auth.passport.with-linked-accounts-content.login-with-suggested-account', 'Привязать {login}', {
            login: suggestedAccount?.login
          })}
        </Button.MultiSpace>
      </Box>
    ) : (
      <>
        {anchorEl && maxLinkedAccountLimitReached && (
          <Tooltip.Info anchorEl={anchorEl} placement='top' className={styles.tooltipBro}>
            <Typography variant='caption1' thickness='medium'>
              {t(
                'core_auth.passport.with-linked-accounts-content.too-much-accounts-linked',
                'Уже привязано максимальное количество аккаунтов'
              )}
            </Typography>
          </Tooltip.Info>
        )}

        <Button.MultiCenter
          ref={setAnchorEl}
          variant='dark'
          startIcon={<PassportIcon style={maxLinkedAccountLimitReached ? {opacity: 0.5} : {}} />}
          onClick={onBindOtherAccount}
          disabled={maxLinkedAccountLimitReached}
          fullWidth
        >
          {t('core_auth.passport.with-linked-accounts-content.link-login', 'Привязать {passportName}', {
            passportName
          })}
        </Button.MultiCenter>
      </>
    )

  return (
    <>
      <PassportFullScreenCloseButton delay={closeDelay} onClick={onClose} />

      {isMobile && (
        <Modal.Header
          title={t('core_auth.passport.with-linked-accounts-content.header-mobile', 'Доступ ограничится 5 апреля')}
        />
      )}

      <Modal.Content>
        <Box className={styles.root}>
          <Box className={styles.main}>
            {!isMobile && (
              <>
                <img src={idWithAlertIcon} className={styles.icon} alt='id icon with alert' loading='lazy' />
                <Typography variant='title2' color='main' thickness='bold' group='normal' className={styles.title}>
                  {t(
                    'core_auth.passport.with-linked-accounts-content.title',
                    'Без привязки Яндекс ID доступ будет ограничен 5 апреля'
                  )}
                </Typography>
              </>
            )}
            <Informer type='warn' className={styles.informer}>
              <Box className={styles.informerContent}>
                <Typography variant='body2' group='normal'>
                  {t(
                    'core_auth.passport.with-linked-accounts-content.informer-main-text',
                    'Если продолжать входить по электронной почте, вы сможете только принимать заказы.\nПривяжите Яндекс ID, чтобы пользоваться всеми функциями — например, работать с меню, управлять зонами доставки или ставить ресторан на паузу.'
                  )}
                </Typography>
                <Box className={styles.linkBlock}>
                  <OutsideLink href={helpUrl} target='_blank'>
                    <Typography variant='body2' group='tight'>
                      {t(
                        'core_auth.passport.with-linked-accounts-content.informer-about-new-auth',
                        'О новой авторизации'
                      )}
                    </Typography>
                  </OutsideLink>
                </Box>
              </Box>
            </Informer>
            <Typography variant='body2' color='main' className={styles.description}>
              {t(
                'core_auth.passport.with-linked-accounts-content.description',
                'К этому личному кабинету привязаны аккаунты из списка ниже. Войдите через один из них или привяжите новый.'
              )}
            </Typography>
            <Box className={styles.linkedListWrapper}>
              <LinkedAccounts onAuthWithLinkedAccount={onAuthWithLinkedAccount} accounts={accounts} />
            </Box>
          </Box>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Box className={styles.footer} justifyContent='center'>
          <Box className={styles.footerButtons}>{renderButtons()}</Box>
        </Box>
      </Modal.Footer>
    </>
  )
}
