import {Provider as MobxProvider} from 'mobx-react'
import React from 'react'

import type {AppStateType} from '@restapp/core-legacy/AppState'
import type {IEnv} from '@restapp/core-legacy/types'

import {AppStateContext, EnvContext} from './context'

interface Props {
  appState: AppStateType
  env: IEnv
  children?: React.ReactNode
}

const Provider: React.FC<Props> = ({appState, env, children}) => (
  <AppStateContext.Provider value={appState}>
    <EnvContext.Provider value={env}>
      <MobxProvider appState={appState} env={env}>
        {children}
      </MobxProvider>
    </EnvContext.Provider>
  </AppStateContext.Provider>
)

export default Provider
