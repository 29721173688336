import {padStart, groupBy, omit} from 'lodash-es'
import moment from 'moment'

import {WEEKDAYS} from '@restapp/shared-api'

export type DayInterval = {
  date: string
  from: number
  to: number
}
export type WeekdayInterval = {
  day: number
  from: number
  to: number
}
export type PeriodRange = {
  dateFrom: Date | number
  dateTo: Date | number
  timeFrom: number
  timeTo: number
}
export type ScheduleRange = {
  days: number[]
  from: number
  to: number
  allDay: boolean
}

const PAD_DIGIT = 2
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const SECONDARY_DATE_FORMAT = 'DD.MM.YY'
const MINUTES_DAY_START = 0
const MINUTES_DAY_END = 1440

// '10:30' -> 630
const convertTimeToMinutes = (hm: string) => {
  const time = hm.split(':')

  return parseInt(time[0], 10) * 60 + parseInt(time[1], 10)
}
// 630 -> '10:30'
const convertMinutesToTime = (m: number) => {
  const hh = padStart(Math.floor(m / 60).toString(), PAD_DIGIT, '0')
  const mm = padStart(Math.floor(m % 60).toString(), PAD_DIGIT, '0')

  return `${hh}:${mm}`
}

// Moment('10:30') -> 630
const convertMomentTimeToMinutes = (date: moment.Moment | null) => {
  if (!date) {
    return
  }

  return date.diff(moment().startOf('day'), 'm')
}

export const convertMinutesToHumanable = (value: number) => moment.duration(value, 'm').humanize({m: 60})

const convertIntervalsToPeriodsByTime = (intervals: DayInterval[]): PeriodRange[] => {
  if (!intervals.length) {
    return []
  }

  const timeFrom = intervals[0].from
  const timeTo = intervals[0].to

  // Сортируем дни по возрастанию
  const momentDays = intervals.map((interval) => moment(interval.date)).sort((lhs, rhs) => lhs.diff(rhs, 'days'))

  const result: PeriodRange[] = []

  let initialDay = momentDays[0] // По нему смотрим, с какого дня начинать очередной диапазон
  let buffer = momentDays[0].clone() // Служит для проверки очередного дня на вхождение в диапазон

  for (let i = 1; i <= momentDays.length; ++i) {
    const nextDate = buffer.add(1, 'day') // Генерируем следующий день в диапазоне

    if (!momentDays[i] || momentDays[i].diff(nextDate, 'days')) {
      result.push({
        dateFrom: initialDay.toDate(),
        dateTo: momentDays[i - 1].toDate(),
        timeFrom,
        timeTo
      })

      initialDay = momentDays[i]
    }

    buffer = momentDays[i] && momentDays[i].clone()
  }

  return result
}

// Получает непересекающиеся диапазоны дней из массива дней
const convertIntervalsToPeriods = (intervals: DayInterval[]): PeriodRange[] => {
  if (!intervals.length) {
    return []
  }

  const intervalsCombinedByMidnight = intervals.reduce<DayInterval[]>((acc, interval) => {
    const previousInterval = acc && acc[acc.length - 1]
    const currentIntervalWorksAllDay = interval.from === MINUTES_DAY_START && interval.to === MINUTES_DAY_END

    if (!currentIntervalWorksAllDay) {
      const previousIntervalWorksTillMidnight = previousInterval && previousInterval.to === MINUTES_DAY_END
      const currentIntervalWorksFromMidnight = interval.from === MINUTES_DAY_START
      const currentIntervalWorksToNoon = interval.to > MINUTES_DAY_START && interval.to < MINUTES_DAY_END / 2

      if (previousIntervalWorksTillMidnight && currentIntervalWorksFromMidnight && currentIntervalWorksToNoon) {
        previousInterval.to = interval.to

        return acc
      }
    }

    return [...acc, interval]
  }, [])

  const intervalsByTime = groupBy(intervalsCombinedByMidnight, (interval) => `${interval.from}-${interval.to}`)
  const periods = Object.values(intervalsByTime).reduce<PeriodRange[]>(
    (acc, intervalArray) => [...acc, ...convertIntervalsToPeriodsByTime(intervalArray)],
    []
  )

  return periods.sort((lhs, rhs) => lhs.dateFrom.valueOf() - rhs.dateFrom.valueOf())
}

const convertPeriodsToIntervals = (period: PeriodRange, dateFormat: string = DEFAULT_DATE_FORMAT): DayInterval[] => {
  const result: DayInterval[] = []
  const {timeFrom, timeTo} = period

  let buffer = moment(period.dateFrom)

  const daysDiff = Math.abs(buffer.diff(moment(period.dateTo), 'days'))

  for (let i = 0; i <= daysDiff; i++) {
    const placeWorksAfterMidnight = period.timeFrom > period.timeTo && period.timeTo !== MINUTES_DAY_START
    const placeWorksTillMidnight = period.timeFrom && period.timeTo === MINUTES_DAY_START
    const formattedDate = buffer.format(dateFormat)

    buffer = buffer.add(1, 'day')
    const nextFormattedDate = buffer.format(dateFormat)

    if (placeWorksAfterMidnight) {
      result.push(
        {
          from: timeFrom,
          to: MINUTES_DAY_END,
          date: formattedDate
        },
        {
          from: MINUTES_DAY_START,
          to: timeTo,
          date: nextFormattedDate
        }
      )
    } else {
      result.push({
        from: timeFrom,
        to: placeWorksTillMidnight ? MINUTES_DAY_END : timeTo,
        date: formattedDate
      })
    }
  }

  return result
}

const convertWeekdayIntervalsToPeriods = (intervals: WeekdayInterval[]): ScheduleRange[] => {
  if (!intervals.length) {
    return []
  }

  const workingIntervals = omit(
    groupBy(intervals, (interval) => `${interval.from}-${interval.to}`),
    '0-0'
  )

  return Object.values(workingIntervals)
    .sort((a, b) => b.length - a.length)
    .map((item) =>
      item.reduce((acc, subItem) => {
        const {day, from, to} = subItem

        return {
          from,
          to,
          allDay: from === MINUTES_DAY_START && to === MINUTES_DAY_END,
          days: [...(acc.days || []), day]
        }
      }, {} as ScheduleRange)
    )
}

const convertWeekdayPeriodsToIntervals = (period: ScheduleRange): WeekdayInterval[] => {
  const {days, from, to} = period

  const isWorksAfterMidnight = from > to

  return days.reduce<WeekdayInterval[]>((acc, day) => {
    if (isWorksAfterMidnight) {
      const isSunday = day === WEEKDAYS.SUNDAY

      acc.push(
        {day, from, to: MINUTES_DAY_END},
        {day: isSunday ? WEEKDAYS.MONDAY : day + 1, from: MINUTES_DAY_START, to}
      )
    } else {
      acc.push({day, from, to})
    }

    return acc
  }, [])
}

const getMinutesAfterMidnight = (momentObj: moment.Moment): number => {
  if (!momentObj) {
    return 0
  }

  const midnight = momentObj.clone().startOf('day')

  return momentObj.diff(midnight, 'minutes')
}

const createMomentFromMinutesAfterMidnight = (minutes?: number): moment.Moment => {
  if (!minutes) {
    return moment().startOf('day')
  }

  return moment().startOf('day').add(minutes, 'minutes')
}

export {
  convertTimeToMinutes,
  convertMinutesToTime,
  convertMomentTimeToMinutes,
  convertIntervalsToPeriods,
  convertPeriodsToIntervals,
  getMinutesAfterMidnight,
  createMomentFromMinutesAfterMidnight,
  convertWeekdayIntervalsToPeriods,
  convertWeekdayPeriodsToIntervals
}
