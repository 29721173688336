import {selectAuth} from '@restapp/core-auth'
import {store} from './coreDeps'
import {setSensitiveStrings} from '@eda-restapp/logger'
import {getDirectToken} from '@eda-restapp/microfrontend'

export const initSecretsWatcher = () => {
  const currentDirectToken = getDirectToken()

  if (currentDirectToken) {
    setSensitiveStrings(currentDirectToken)
  }

  store.subscribe(() => {
    const {token, oauthToken} = selectAuth(store.getState())

    if (token) {
      setSensitiveStrings(token)
    }

    if (oauthToken) {
      setSensitiveStrings(oauthToken)
    }
  })
}
