import {types} from 'mobx-state-tree'

export interface CurrencyType {
  code: string
  sign: string
  decimal_places: number
}

/** @deprecated */
const Currency = types.frozen<CurrencyType>()

export default Currency
