import {useI18n} from '@eda-restapp/i18n'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {usePermission} from '@eda-restapp/permissions'
import {memo, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useSnackbarNotification} from './useSnackbarNotification'
import {eventLogger} from '@eda-restapp/logger'

export const DisplayWebSocketNotifications = memo(() => {
  const navigate = useNavigate()
  const {t} = useI18n()
  const hasAccess = usePermission('permission.orders.active')
  const {isLeader} = useNotificationChannelLeaderState()
  const {showSnackbarNotification} = useSnackbarNotification()

  useEffect(() => {
    if (!isLeader) {
      return
    }

    return notificationsChannel.subscribe((notification) => {
      if (notification.meta.source !== 'socket' || notification.meta.clicked) {
        return
      }

      switch (notification.event) {
        case 'direct_low_balance': {
          showSnackbarNotification({
            message: t('promotion.watch-low-balance.direct_low_balance-message', 'Низкий баланс в Директе!'),
            onClick: () => {
              notificationsChannel.broadcast({
                ...notification,
                meta: {...notification.meta, clicked: true}
              })
            },
            onClose: () => {
              eventLogger({
                name: 'notifications:direct_low_balance-not-clicked'
              })
            }
          })

          break
        }

        case 'order_changed_courier':
        case 'order_changed_items':
        case 'order_changed_status':
        case 'order_updated_courier':
        case 'order_new': {
          if (!hasAccess) {
            break
          }

          if (!notification.meta.notification_body) {
            break
          }

          showSnackbarNotification({
            message: notification.meta.notification_body,
            onClick: () => {
              notificationsChannel.broadcast({
                ...notification,
                meta: {...notification.meta, clicked: true}
              })
            }
          })
          break
        }

        case 'place_leader_changed': {
          if (!notification.meta.notification_body) {
            break
          }

          showSnackbarNotification({
            message: notification.meta.notification_body,
            type: ['leader', 'leader_can_taken_away'].includes(notification.data.new_status) ? 'success' : 'error',
            onClick: () => {
              notificationsChannel.broadcast({
                ...notification,
                meta: {...notification.meta, clicked: true}
              })
            }
          })

          break
        }

        default:
          break
      }
    })
  }, [isLeader, showSnackbarNotification, t, navigate, hasAccess])

  return null
})

function useNotificationChannelLeaderState() {
  const [isLeader, setIsLeader] = useState(false)
  useEffect(() => {
    if (!isLeader) {
      void notificationsChannel.leaderElector
        .awaitLeadership()
        .then(() => setIsLeader(true))
        // eslint-disable-next-line no-console
        .catch(console.error)
    }
  }, [isLeader])
  return {isLeader}
}
