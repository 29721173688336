import {useI18n} from '@eda-restapp/i18n'
import {useEffect} from 'react'
import {useLocation, useBlocker} from 'react-router-dom'

import {useEnv} from '@restapp/core-di'

type Props = {
  shouldBlock: boolean
  onContinue?: () => void
  unblockLocation?: string
}

const useOpenUnSavedAlertEffect = ({shouldBlock, unblockLocation, onContinue}: Props) => {
  const {t} = useI18n()
  const {dialogs} = useEnv()

  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const blocker = useBlocker((navigation) => {
    // проверяем пути
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const nextLocationPathname = navigation.nextLocation.pathname
    if (shouldBlock) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (unblockLocation && nextLocationPathname.includes(unblockLocation)) {
        return false
      } else if (nextLocationPathname !== location.pathname) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  })

  useEffect(() => {
    if (blocker.state == 'blocked') {
      void dialogs
        .open({
          cancelText: t('shared.use-open-un-saved-alert-effect.ne-sokhranyat', 'Не сохранять'),
          applyText: t('shared.use-open-un-saved-alert-effect.prodolzhit-redaktirovanie', 'Продолжить редактирование'),
          outsideClose: false,
          title: t('shared.use-open-un-saved-alert-effect.izmeneniya-ne-bili-sokhraneni', 'Изменения не были сохранены')
        })
        .then((isApplied) => {
          if (!isApplied) {
            blocker.proceed()
            if (onContinue) {
              onContinue()
            }
          } else {
            blocker.reset()
          }
        })
    }
  }, [blocker.state])
}

export default useOpenUnSavedAlertEffect
